
export const formTypeRatioDough = 'rdough'
export const formTypeQuantityDough = 'qdough'
export const formTypeLevain = 'levain'

export const BATCH_TYPE_FLOUR_WT = 'FW'
export const BATCH_TYPE_TOTAL_WT = 'TW'

export const LEVAIN_PCT_TYPE_NONE = 'NONE'
export const LEVAIN_PCT_TYPE_PFF = 'PFF'
export const LEVAIN_PCT_TYPE_TOTAL_WT = 'TW'

export const BAKERS_YEAST_TYPE_DRY = 'DRY'
export const BAKERS_YEAST_TYPE_CAKE = 'CAKE'

export const noPreferment = 'none'
export const liquidLevain = 'liquidLevain'
export const stiffLevain = 'stiffLevain'
export const poolish = 'poolish'
export const sponge = 'sponge'
export const biga = 'biga'
export const pateFermente = 'pateFermente'

export const ingredientWater = 'water'
export const ingredientFlour = 'flour'
export const ingredientDryYeast = 'dryYeast'
export const ingredientFreshYeast = 'freshYeast'
export const ingredientLevain = 'levain'
export const ingredientUnknown = 'unknown'

export const LOW_HYDRATION_PCT_THRESHOLD = 65
export const PREFERMENT_BUFFER_WEIGHT = 12

export function getLevainTypeLabel(levainType) {
  return levainType === LEVAIN_PCT_TYPE_TOTAL_WT ? 'Weight' : 'PFF'
}

export const prefermentTypeMap = [
  {
    prefermentType: noPreferment,
    prefermentLabel: 'None',
    defaultHydrationPct: 0,
    defaultRecipeFlourPct: 0,
    yeastRatioId: 0,
    starterPct: 0,
    isYeast: false
  },
  {
    prefermentType: liquidLevain,
    prefermentLabel: 'Liquid Levain',
    defaultHydrationPct: 100,
    defaultRecipeFlourPct: 10,
    yeastRatioId: 0,
    starterPct: 20,
    isYeast: false
  },
  {
    prefermentType: stiffLevain,
    prefermentLabel: 'Stiff Levain',
    defaultHydrationPct: 60,
    defaultRecipeFlourPct: 10,
    yeastRatioId: 0,
    starterPct: 20,
    isYeast: false
  },
  {
    prefermentType: biga,
    prefermentLabel: 'Biga',
    defaultHydrationPct: 60,
    defaultRecipeFlourPct: 20,
    yeastRatioId: 3,
    starterPct: 0,
    isYeast: true
  },
  {
    prefermentType: pateFermente,
    prefermentLabel: 'Pâte Fermentée',
    defaultHydrationPct: 72,
    defaultRecipeFlourPct: 20,
    yeastRatioId: 4,
    starterPct: 0,
    isYeast: true
  },
  {
    prefermentType: poolish,
    prefermentLabel: 'Poolish',
    defaultHydrationPct: 100,
    defaultRecipeFlourPct: 22,
    yeastRatioId: 4,
    starterPct: 0,
    isYeast: true
  },
  {
    prefermentType: sponge,
    prefermentLabel: 'Sponge',
    defaultHydrationPct: 100,
    defaultRecipeFlourPct: 15,
    yeastRatioId: 5,
    starterPct: 0,
    isYeast: true
  }
]

export function getPrefermentDefinition(prefermentType) {
  let preferment = prefermentTypeMap.find((p) => p.prefermentType === prefermentType)
  if (preferment === undefined) {
    return noPreferment
  }
  return preferment
}

export function getPrefermentLabel(prefermentType) {
  let preferment = prefermentTypeMap.find((p) => p.prefermentType === prefermentType)
  if (preferment === undefined) {
    return 'None'
  }
  return preferment.prefermentLabel
}

export function getPrefermentPffPct(prefermentType) {
  let preferment = prefermentTypeMap.find((p) => p.prefermentType === prefermentType)
  if (preferment === undefined) {
    return 0
  }
  return preferment.defaultRecipeFlourPct
}

export function isYeastPreferment(prefermentType) {
  let preferment = prefermentTypeMap.find((p) => p.prefermentType === prefermentType)
  if (preferment === undefined) {
    return false
  }
  return preferment.isYeast
}

export const Exception = {
  componentName: '',
  methodName: '',
  message: ''
}

export function createException(component, method, message) {
  return {...Exception, componentName: component, methodName: method, message: message}
}
