import {MenuItem} from "@mui/material";
import * as React from "react";
import {prefermentTypeMap} from "./Types";
import {FormTextField} from "./FormTextField";

function PrefermentTypeSelector(props) {
  const {prefermentType, onValueChange, excludeTypes} = props

  const excludeTypesSafe = excludeTypes === undefined ? [] : excludeTypes
  const preferments = prefermentTypeMap.filter(p => ! excludeTypesSafe.includes(p.prefermentType))

  function onChangeLocal(evt) {
    onValueChange(evt.target.value)
  }

  return (
      <FormTextField sx={{width: '20ch'}} label={'Preferment Type'} size={'small'} select value={prefermentType} onChange={onChangeLocal}>
        {
          preferments.map((p) => {
            if (p.isYeast) {
              return <MenuItem sx={{fontStyle: 'italic'}} key={p.prefermentType} value={p.prefermentType}>{p.prefermentLabel} (yeast)</MenuItem>
            } else {
              return <MenuItem key={p.prefermentType} value={p.prefermentType}>{p.prefermentLabel}</MenuItem>
            }
          })
        }
      </FormTextField>
  )
}

export {PrefermentTypeSelector}