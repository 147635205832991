import React from 'react'
import './index.css'
import App from './App'
import {createRoot} from "react-dom/client";

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
