import {useState} from "react";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import {Drawer, List, ListItem, ListItemText, ThemeProvider} from "@mui/material";
import {StyledNavLink} from "./StyledNavLink";
import * as React from "react";
import {useLocation} from "react-router-dom";
import {useTheme} from "@mui/system";
import {urlLevain, urlQuantityDough, urlRatioDough} from "../../App";
import NumbersIcon from "@mui/icons-material/Numbers";
import ScienceIcon from "@mui/icons-material/Science";
import PercentIcon from "@mui/icons-material/Percent";
import SaveIcon from "@mui/icons-material/Save";


function DrawerMenu() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const currentLocation = useLocation()
  const theme = useTheme();

  return (
    <div>
      <ThemeProvider theme={theme}>
        <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
          <MenuIcon fontSize={'large'}/>
        </IconButton>

        <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <List>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <StyledNavLink className={`${currentLocation.pathname === '/' ? 'active' : undefined}`} to={'/'}>Home</StyledNavLink>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <StyledNavLink className={`${currentLocation.pathname === '/help' ? 'active' : undefined}`} to={'/help'}>Help</StyledNavLink>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <StyledNavLink className={`${currentLocation.pathname.startsWith(urlRatioDough) ? 'active' : undefined}`} to={`${urlRatioDough}`}><PercentIcon/>Ratio</StyledNavLink>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <StyledNavLink className={`${currentLocation.pathname.startsWith(urlQuantityDough) ? 'active' : undefined}`} to={`${urlQuantityDough}`}><NumbersIcon/>Quantity</StyledNavLink>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <StyledNavLink className={`${currentLocation.pathname.startsWith(urlLevain) ? 'active' : undefined}`} to={`${urlLevain}`}><ScienceIcon/>Levain</StyledNavLink>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <StyledNavLink className={`${currentLocation.pathname === '/saved' ? 'active' : undefined}`} to={'/saved'}><SaveIcon/>Saved</StyledNavLink>
                </ListItemText>
              </ListItem>
            </List>
        </Drawer>
      </ThemeProvider>
    </div>
  )
}

export {DrawerMenu}