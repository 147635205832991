
export const PRINT_DOUGH_SESS_KEY = 'current-dough-recipe'
export const PRINT_LEVAIN_SESS_KEY = 'current-levain-recipe'

/**
 * Returns an array of stringified JSON objects from local storage according to the filter and sort params.
 * Each object is keyed by the local storage key, with the storage object as its value
 * @param filterKeyName
 * @param filterKeyValue
 * @param sortKeyName
 * @param sortAsc
 * @returns {*[]}
 */
function getLocalStorageCollectionJson(filterKeyName, filterKeyValue, sortKeyName, sortAsc) {
  const storageKeys = Object.keys(localStorage)
  const storageMap = {}
  storageKeys.forEach(k => {
    storageMap[k] = JSON.parse(localStorage.getItem(k))
  })

  const jsonResult = []
  storageKeys
  .filter((k) => {
    if (filterKeyName && filterKeyValue) {
      return storageMap[k][filterKeyName] === filterKeyValue
    }
    return true
  })
  .sort((a, b) => {
    if (sortAsc && sortKeyName) {
      return storageMap[a][sortKeyName] - storageMap[b][sortKeyName]
    } else if (sortKeyName) {
      return storageMap[b][sortKeyName] - storageMap[a][sortKeyName]
    } else {
      return 0
    }
  })
  .forEach((k, ix) => {
    jsonResult.push(JSON.stringify(storageMap[k]))
  })
  return jsonResult
}

/**
 * Accepts an object array and key field name expected to be found in each object in objArray.
 * Uses the value found for keyFieldName, if any, and sets the stringified object into local storage.
 * Returns the count of objects saved into local storage
 * @param objArray
 * @param keyFieldName
 * @returns {number}
 */
function putLocalStorageCollectionJson(objArray, keyFieldName) {
  let numItemsPut = 0
  objArray.forEach((obj, ix) => {
    if (obj.hasOwnProperty(keyFieldName)) {
      let storageKey = obj[keyFieldName]
      localStorage.setItem(storageKey, JSON.stringify(obj))
      ++numItemsPut
    }
  })
  return numItemsPut
}

export {getLocalStorageCollectionJson, putLocalStorageCollectionJson}