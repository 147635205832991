
function getLocalDate() {
  const utcDt = new Date()
  const localOffsetMillis = utcDt.getTimezoneOffset() * 60 * 1000
  const localMillis = utcDt.getTime() - localOffsetMillis
  return new Date(localMillis)
}

function getLocalDateStr() {
  return getLocalDate(new Date()).toISOString().slice(0, 10).replaceAll('-', '')
}

function getLocalDateTimeStr(includeMillis= false, isoFormat = false) {
  const utcDt = getLocalDate()
  return getDateTimeStr(utcDt, includeMillis, isoFormat)
}

function getDateTimeStr(date, includeMillis= false, isoFormat = false) {
  const sliceOffset = includeMillis ? 23 : 19
  if (isoFormat) {
    return date.toISOString().slice(0, sliceOffset)
  } else {
    return date.toISOString().replace('T', ' ').slice(0, sliceOffset)
  }
}

function addTimeToDate(date, hours, minutes, seconds) {
  const startingMillis = date.getTime()
  let addMillis = hours * 60 * 60 * 1000
  addMillis += minutes * 60 * 1000
  addMillis += seconds * 1000
  return new Date(startingMillis + addMillis)
}

export {getLocalDateStr, getLocalDate, getLocalDateTimeStr, getDateTimeStr, addTimeToDate}