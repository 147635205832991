import * as React from 'react';
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Typography
} from "@mui/material";
import {RecipeSummaryTable} from "./RecipeSummaryTable";
import {RecipeIngredientTable} from "./RecipeIngredientTable";
import {
  getPrefermentLabel,
  LEVAIN_PCT_TYPE_NONE,
  LEVAIN_PCT_TYPE_TOTAL_WT,
  PREFERMENT_BUFFER_WEIGHT
} from "../shared/Types";
import {forwardRef} from "react";
import {getDateTimeStr, getLocalDateTimeStr} from "../utils/DateUtils";
import {unitPercent} from "../shared/Units";

const DoughRecipe = forwardRef(function DoughRecipe(props, printRef) {
  const {items, variant, elevation} = props

  function renderStarter(dough, isPreferment) {
    if (dough.starterPct === 0 || dough.starterType === LEVAIN_PCT_TYPE_NONE) {
      return null
    }
    let levainName = isPreferment ? 'Starter' : 'Levain'
    let levainDescription = levainName
    if (dough.starterType === LEVAIN_PCT_TYPE_TOTAL_WT) {
      return (
        <RecipeIngredientTable ingredientList={[{name: levainDescription, percent: dough.starterPct, weight: dough.starterWeight, altUnit: dough.starterAltUnit, altUnitAmount: dough.starterAltUnitAmount}]}/>
      )
    } else {
      levainDescription = `${levainDescription} PFF Flour`
      if (dough.starterAltTypePct !== undefined) {
        levainDescription = `${levainDescription} (${dough.starterAltTypePct}% weight)`
      }
      let levainIngredients = []
      levainIngredients.push({percent: dough.starterPct, name: levainDescription, weight: dough.starterFlourWeight})
      levainIngredients.push({percent: dough.starterHydrationPct, name: `${levainName} Water`, weight: dough.starterLiquidWeight})
      return (
        <RecipeIngredientTable ingredientList={levainIngredients}/>
      )
    }
  }

  function renderYeast(yeast, isPreferment) {
    if (yeast === undefined || yeast.ratioId === 0) {
      return null
    }
    return (
      <div>
        <RecipeIngredientTable ingredientList={[{name: `Dry Yeast`, percent: yeast.percent, weight: yeast.weight, altUnit: yeast.altUnit, altUnitAmount: yeast.altUnitAmount
        }]}/>
      </div>
    )
  }

  function renderPreferment(includePreferment, preferment, prefermentPffPct) {
    if (!includePreferment) {
      return null
    } else {
      const prefermentName = `${getPrefermentLabel(preferment.prefermentType)} Preferment`
      return (
        <div>
          <Typography sx={{fontSize: 14, fontWeight: 550, marginTop: 2}} component={'div'}>
            {`${prefermentName}, ${prefermentPffPct}% PFF`}
          </Typography>
          {preferment.addBuffer ?
                  (<Typography sx={{fontSize: 11, fontStyle: 'italic', marginBottom: 0.5}}>Note: Preferment formula includes a weight buffer</Typography>)
                  : null
          }
          <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
            Flour
          </Typography>
          <RecipeIngredientTable ingredientList={preferment.flour}/>
          <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
            Leavening
          </Typography>
          {
            renderStarter(preferment, true)
          }
          {
            renderYeast(preferment.yeast, true)
          }
          <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
            {`Liquid (`}<Box component="span" fontWeight='900'>{`${preferment.hydrationPct}%`}</Box> {`hydration)`}
          </Typography>
          <RecipeIngredientTable ingredientList={preferment.liquid}/>
          <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
            Total
          </Typography>
          <RecipeIngredientTable ingredientList={[{name: prefermentName, weight: (preferment.addBuffer ? preferment.totalBufferedWeight : preferment.totalWeight)}]}/>
          {renderPrefermentTiming(preferment)}
        </div>
      )
    }
  }

  function renderMainLeavening(includePreferment, main, preferment) {
    return (
      <div>
        {includePreferment ?
          (
            <RecipeIngredientTable ingredientList={[{name: `Preferment (${main.prefermentPffPct}% PFF)`, percent: main.prefermentByWeightPct, weight: preferment.totalWeight}]}/>
          )
          : null
        }
        {
          renderStarter(main)
        }
        {
          renderYeast(main.yeast)
        }
      </div>
    )
  }

  function renderPrefermentTiming(preferment) {
    if (preferment.levainFermTimeStr.length === 0) {
      return null
    }
    return (
      <div>
        <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
          Timing
        </Typography>
        <Typography sx={{fontSize: 12, marginLeft: 2}} gutterBottom>
          {`Estimated ${preferment.levainFermPct}% rise time @ ${preferment.levainFermTempF}°F: `}
          <Box component="span" fontWeight='800'>{preferment.levainFermTimeStr}</Box>
        </Typography>
      </div>
    )
  }

  function renderBulkFermentTiming(items) {
    let fermPct = items.mainDough.bulkFermPct
    let fermTemp = items.mainDough.bulkFermTempF
    let fermTimeStr = items.mainDough.bulkFermTimeStr
    if (fermTimeStr.length === 0) {
      return null
    }
    return (
      <div>
        <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
          Timing
        </Typography>
        <Typography sx={{fontSize: 12, marginLeft: 2}} gutterBottom>
          {`Estimated ${fermPct}% rise time @ ${fermTemp}°F: `}
          <Box component="span" fontWeight='800'>{fermTimeStr}</Box>
        </Typography>
      </div>
    )
  }

    return (
        <Card elevation={elevation} variant={variant}>
            <CardContent id={'print-dough-card'} ref={printRef}>
              <Typography sx={{fontWeight: 600}} variant="h6" component={'div'}>
                {items.formName}
              </Typography>
              {items.attribution ? (
                      <Typography sx={{ fontSize: 10}}>
                        Attribution: {items.attribution}
                      </Typography>) : null
              }
              <Typography sx={{ fontSize: 10}}>
                Generated: {getLocalDateTimeStr()}
              </Typography>
              {items.createdAt ? (
                  <Typography sx={{ fontSize: 10}}>{`Created: ${getDateTimeStr(new Date(items.createdAt))}`}</Typography>
              ) : null}
              {items.modifiedAt ? (
                      <Typography sx={{ fontSize: 10}}>{`Last modified: ${getDateTimeStr(new Date(items.modifiedAt))}`}</Typography>
              ) : null}
              <Typography marginTop={0.75} sx={{ fontSize: 14, fontWeight: 550}}>
                Recipe Totals
              </Typography>
              <RecipeSummaryTable items={items}/>

              {renderPreferment(items.includePreferment, items.prefermentDough, items.mainDough.prefermentPffPct)}

              <Typography sx={{fontSize: 14, fontWeight: 550, marginTop: 1}} component={'div'}>
                Main Dough
              </Typography>
              <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
                Flour
              </Typography>
              <RecipeIngredientTable ingredientList={items.mainDough.flour}/>
              <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
                Leavening
              </Typography>
              {renderMainLeavening(items.includePreferment, items.mainDough, items.prefermentDough)}
              <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
                Liquid
              </Typography>
              <RecipeIngredientTable ingredientList={items.mainDough.liquid}/>
              {items.mainDough.mixin.length > 0 ? (
                <div>
                  <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
                    Mixins
                  </Typography>
                  <RecipeIngredientTable ingredientList={items.mainDough.mixin}/>
                </div>) : null
              }
              {items.mainDough.soaker.length > 0 ? (
                <div>
                  <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
                    Soaker
                  </Typography>
                  <RecipeIngredientTable ingredientList={items.mainDough.soaker}/>
                </div>) : null
              }
              {items.mainDough.toppings.length > 0 ? (
                <div>
                  <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
                    Toppings
                  </Typography>
                  <RecipeIngredientTable ingredientList={items.mainDough.toppings}/>
                </div>) : null
              }
              <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
                Total
              </Typography>
              <RecipeIngredientTable ingredientList={[{name: 'Main Dough', weight: items.recipeTotalWeight}]}/>
              {renderBulkFermentTiming(items)}
              {items.notes.trim() !== '' ?
                <div>
                <Typography sx={{fontSize: 14, fontWeight: 550, marginTop: 1}} component={'div'}>
                  Notes and Instructions
                </Typography>
                <Typography sx={{fontSize: 12, whiteSpace: "pre-wrap"}} color="text.secondary" gutterBottom>
                  {items.notes}
                </Typography>
                </div> : null
              }
            </CardContent>
        </Card>
    )
})

DoughRecipe.propTypes = {
  items: PropTypes.shape({
    formName: PropTypes.string,
    attribution: PropTypes.string,
    createdAt: PropTypes.number,
    modifiedAt: PropTypes.number,
    notes: PropTypes.string,
    numBatches: PropTypes.number,
    batchSize: PropTypes.number,
    includePreferment: PropTypes.bool,
    recipeFlourTotalWeight: PropTypes.number,
    recipeLiquidTotalWeight: PropTypes.number,
    recipeYeastTotalWeight: PropTypes.number,
    recipeMixinTotalWeight: PropTypes.number,
    recipeSoakerTotalWeight: PropTypes.number,
    recipeTotalWeight: PropTypes.number,
    mainDough: PropTypes.shape({
      hydrationPct: PropTypes.number,
      prefermentPffPct: PropTypes.number,
      starterPct: PropTypes.number,
      starterAltTypePct:  PropTypes.number,
      starterHydrationPct: PropTypes.number,
      yeast: PropTypes.shape({
        ratioId: PropTypes.number,
        percent: PropTypes.number,
        weight: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number,
        volumeDisplayLabel: PropTypes.string
      }),
      starterWeight: PropTypes.number,
      starterAltUnit: PropTypes.string,
      starterAltUnitAmount: PropTypes.number,
      starterFlourWeight: PropTypes.number,
      starterLiquidWeight: PropTypes.number,
      flourWeight: PropTypes.number,
      liquidWeight: PropTypes.number,
      autolyse: PropTypes.bool,
      bulkFermPct: PropTypes.number,
      bulkFermTempF: PropTypes.string,
      bulkFermTimeStr: PropTypes.string,
      flour: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.number,
        percent: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number
      })),
      liquid: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.number,
        percent: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number
      })),
      mixin: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.number,
        percent: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number
      })),
      soaker: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.number,
        percent: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number
      })),
      toppings: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.number,
        percent: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number
      })),
      totalWeight: PropTypes.number,
      prefermentByWeightPct: PropTypes.number
    }),
    prefermentDough: PropTypes.shape( {
      hydrationPct: PropTypes.number,
      starterPct: PropTypes.number,
      starterAltTypePct:  PropTypes.number,
      starterHydrationPct: PropTypes.number,
      yeast: PropTypes.shape({
        ratioId: PropTypes.number,
        percent: PropTypes.number,
        weight: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number,
        volumeDisplayLabel: PropTypes.string
      }),
      starterWeight: PropTypes.number,
      starterAltUnit: PropTypes.string,
      starterAltUnitAmount: PropTypes.number,
      starterFlourWeight: PropTypes.number,
      starterLiquidWeight: PropTypes.number,
      flourWeight: PropTypes.number,
      liquidWeight: PropTypes.number,
      autolyse: PropTypes.bool,
      bulkFermPct: PropTypes.number,
      bulkFermTempF: PropTypes.string,
      bulkFermTimeStr: PropTypes.string,
      bulkFermTimeAdjusted: PropTypes.bool,
      levainFermPct: PropTypes.number,
      levainFermTempF: PropTypes.string,
      levainFermTimeStr: PropTypes.string,
      flour: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.number,
        percent: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number
      })),
      liquid: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.number,
        percent: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number
      })),
      totalWeight: PropTypes.number
    })
  }),
  visible: PropTypes.bool
}

export { DoughRecipe }