import {useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import Button from "@mui/material/Button";

function AttributionDialog(props) {
  const {attributionText, setNewAttributionText, dialogOpen, setDialogOpen} = props

  const handleClose = () => {
    setDialogOpen(false);
  };

  function onFieldChange(evt) {
    setNewAttributionText(evt.target.value)
  }

  return (
    <div>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Attribution</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Credit the original author or source
          </DialogContentText>
          <TextField
                  autoFocus
                  margin="dense"
                  label={`Attribution`}
                  value={attributionText}
                  onChange={onFieldChange}
                  fullWidth
                  variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Done</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export {AttributionDialog}