import {FormTextField} from "./FormTextField";
import * as React from "react";
import {normalizeDecimalString} from "../utils/NumberUtils";

function DecimalStringFormField(props) {
  const {valueStr, size, name, label, onChange, onBlur, id, disabled} = props

  // state variable as object: concept to keep in mind
  // const [values, setValues] = useState({
  //   num: 1,
  //   str: '1'
  // });

  function onBlurLocal(evt) {
    if (onBlur !== undefined) {
      onBlur(evt)
    }
  }

  function onDecimalFieldChange(evt) {
    let newValueStr = normalizeDecimalString(evt.target.value)
    if (newValueStr === undefined) {
      return
    }

    const floatValue = parseFloat(newValueStr)
    onChange(name, floatValue, newValueStr, id)
  }


  return (
    <FormTextField size={size} disabled={disabled}
                   id="outlined-required"
                   name={name}
                   label={label}
                   value={valueStr}
                   onChange={onDecimalFieldChange}
                   onBlur={onBlurLocal}
                   variant="filled"/>
  )
}

export {DecimalStringFormField}