// DoughForm defaults

import {
  BATCH_TYPE_TOTAL_WT,
  formTypeRatioDough,
  LEVAIN_PCT_TYPE_TOTAL_WT,
  liquidLevain
} from "../shared/Types";
import {defaultBulkFermPct, defaultLevainFermPct} from "../shared/FermentationTiming";

const mainFormDataDefaults = {
  formName: `New Formula`,
  formType: formTypeRatioDough,
  attribution: '',
  numBatches: 1,
  batchSize: 1000,
  batchType: BATCH_TYPE_TOTAL_WT,
  hydrationPct: 72,
  autolyse: false,
  starterType: LEVAIN_PCT_TYPE_TOTAL_WT,
  starterPct: 20,
  starterHydrationPct: 100,
  yeastRatioId: 0,
  yeastPct: 0,
  bulkFermPct: defaultBulkFermPct,
  bulkFermTempF: '75',
  includePreferment: false,
  prefermentPffPct: 0,
  prefermentData: undefined,
  flour: [
    {
      name: 'Bread Flour',
      percent: 100,
      percentStr: '100',
      id: 'b.1'
    }
  ],
  liquid: [
    {
      name: 'Water',
      percent: 100,
      percentStr: '100',
      id: 'b.1'
    }
  ],
  mixin: [
    {
      name: 'Salt',
      percent: 1.85,
      id: 'b.1'
    }
  ],
  soaker: [
  ],
  toppings: [
  ],
  notes: ''
}


const prefermentDefaults = {
  prefermentType: liquidLevain,
  hydrationPct: 100,
  autolyse: false,
  yeastRatioId: 0,
  yeastPct: 0,
  starterType: LEVAIN_PCT_TYPE_TOTAL_WT,
  starterPct: 20,
  starterHydrationPct: 100,
  levainFermPct: defaultLevainFermPct,
  levainFermTempF: '75',
  addBuffer: false,
  flour: [
    {
      name: 'All Purpose',
      percent: 100,
      percentStr: '100',
      id: 'b.1'
    }
  ],
  liquid: [
    {
      name: 'Water',
      percent: 100,
      percentStr: '100',
      id: 'b.1'
    }
  ]
}

const maxUnitsToBake = 100

// structure to represent yeast quantity in terms of number of 1/16 tsp units
//   i.e. value is just an index/identifier for the UI dropdown and multiplier is the # of 1/16 tsp units

export function getUnitsToBakeSelector() {
  return [...Array(maxUnitsToBake).keys()].map((ix) => {return ix + 1})
}

export {maxUnitsToBake, mainFormDataDefaults, prefermentDefaults}