
export const helpFinalMixHydration = 'Final Mix Hydration is the overall goal recipe hydration.\n\n' +
        'This will include the water in the Preferment Formula, if applicable.'

export const helpAutolyse = 'Autolyse is a pre-fermentation soak of the flour in a portion of the dough water intended to help strengthen gluten bonds.\n' +
        'Autolyse tends to be more important when using whole grain or freshly milled flours, but may improve your dough with any flour.\n' +
        'Autolyse should be done without any leavening agent and usually without any mixins such as salt.\n\n' +
        'Choosing this option simply portions the dough water amount into two amounts, autolyse and final mix, in the recipe rendering.\n' +
        'Autolyse times can be 30-60+ minutes depending on the percentage of whole grain flour.'

export const helpFermVolTemp = 'This time projection is simply a rough guideline for planning purposes.\n' +
        'As always, be sure to monitor your dough or levain for signs of adequate fermentation.\n\n' +
        'These timings assume predominantly Bread or All Purpose flour with a healthy, daily-maintained starter or viable dry yeast.\n' +
        'When using higher percentages of whole grain, high protein flour, rise times will be less.\n\n' +
        'Levain can be ready to use at 85% but may be stronger for the dough at closer to 100%.\n' +
        'The main bulk ferment can be ready anywhere from 20% to 30+% but is subject to more variables, making those time projections less accurate.'

export const helpLevainTypeMain = 'The levain seed amount can be specified as a percent of total weight (independent ingredient), ' +
        'or as a percentage of prefermented flour (PFF).\n\n' +
        'When using a levain with equal parts flour and water (100% hydration), choose the "Weight" option.\n' +
        'When using a levain with an unusually low or high hydration level, choose the "PFF" option, for a more accurate overall dough hydration level.\n' +
        'NOTE: when your formula calls for a custom levain with specific flours, use the "Add Preferment Formula" to ' +
        'provide levain build specifics.'


export const helpLevainTypePreferment = 'The levain seed amount can be specified by weight (independent ingredient) ' +
        'or as a percentage of prefermented flour (PFF).\n' +
        'When using a levain with equal parts flour and water (100% hydration), the "by weight" selection is adequate and simpler.\n' +
        'When using a levain with an unusually low or high hydration level, the water amounts can skew the desired overall dough ' +
        'hydration level. In those cases, choosing the PFF option and the corresponding levain hydration level to produce a more accurate ' +
        'overall dough hydration for the specified goal.\n\n'

export const helpPrefermentType = 'Choose a Preferment Type to produce different combinations of ratios, then adjust to your needs.\n' +
        'A Preferment, with its unique flour ingredients, can produce a more complex flavor profile by taking your ' +
        'dough through multiple stages of fermentation.\n\n' +
        'Alternatively, if you have a go-to levain mix that you prefer using as a standalone ingredient, simply ' +
        'disable the "Add Preferment Formula" switch and use the Main Dough panel.\n\n'

export const helpPrefermentFlourPortion = 'The Preferment Flour Portion defines the percentage of the overall recipe flour amount ' +
        'allocated to the Preferment dough build.\n\n' +
        'A typical sourdough levain Preferment might be 8 to 12% of the overall flour, whereas some yeast Preferments may use higher percentages.\n\n' +
        'Note that when using a levain seed in your Preferment specified as PFF, the flour amount used in the levain contributes to the Preferment flour amount.'

export const helpPrefermentHydration = 'The Preferment Hydration identifies the hydration amount of just the Preferment dough mix.\n\n' +
        'Note that when using a levain seed in your Preferment specified as PFF, the levain hydration amount contributes to the Preferment hydration amount.'

export const helpQuantityButtons = 'The Apply, Reset and Save buttons behave the same as for the Ratio menu buttons.\n' +
        'The Apply and Save buttons render and save the recipe in its original, quantity-based form.\n\n' +
        'The "Save As Ratio" button converts a copy of this quantity recipe to a scalable Ratio-based recipe by saving it directly as a separate specification.\n' +
        'To view, apply, scale, and/or revise the Ratio version of this recipe, click Save As Ratio, then go to the Saved ' +
        'menu option to choose and navigate to the Ratio version of the recipe.'
