import * as React from 'react';
import {useState} from "react";
import {FormTextField} from "./FormTextField";
import {Box, Fab, Grid, Tooltip} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {deepCopy, findIndexForFieldValue, findObjectForFieldValue, getFieldValuesAsArray} from "../utils/ArrayUtils";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import * as Units from "./Units";
import {unitPercent} from "./Units";
import {toFixedDecimal} from "../utils/NumberUtils";
import {DecimalStringFormField} from "./DecimalStringFormField";

/**
 * This component manages a set of related ingredients with units in percentages relative to the group itself.
 * Percentage amounts can be specified as whole numbers or decimal numbers with .01 scale
 * In contrast, the IngredientCollectionForm component handles ingredients with ratio percentages relative to the recipe flour.
 * The component ensures a 100% sum total of all its group members by automatically self-balancing when focus leaves the
 * percentage unit form field.
 *
 * In order to make the self-balancing work, the component uses a DecimalStringFormField component which manages both a String
 * and floating point version of the decimal value. This approach permits character-at-a-time change events from
 * control/user input, but also accepts new values from the parent when self-balancing the values, which is an
 * antipattern in ReactJs (manaaged vs unmanaged).
 * The String and floating point value aren't always 100% in sync visually, e.g. during typing.
 * This approach seems to give the best user experience for field entry typing while also permitting re-balance updates
 * from the parent via onBlur. ...and avoids the key-down/timer event handling approach.
 * @param props with ingredientData as an array of ingredient objects
 * @returns {JSX.Element}
 * @constructor
 */
function IngredientGroupForm(props) {
  const {ingredientData, setIngredientData, idField, nameField, unitField, groupName, minGroupSize, disabled} = props
  const [editedIngredientId, setEditedIngredientId] = useState(undefined)

  // sibling String field must be named "{unitField}Str"
  const unitFieldStr = `${unitField}Str`

  function onIngredientNameChange(evt) {
    const ingredientId = evt.target.dataset.ingredientid
    const ix = findIndexForFieldValue(ingredientData, idField, ingredientId)
    if (ix < 0) {
      console.log(`!! onIngredientNameChange: cannot find ingredient for id ${ingredientId}`)
      return
    }
    const updatedIngredientData = ingredientData.slice()
    updatedIngredientData[ix] = {...ingredientData[ix], [nameField]: evt.target.value}
    setIngredientData(updatedIngredientData)
  }

  function onIngredientUnitFieldChange(fieldName, floatValue, strValue, id) {
    const ix = findIndexForFieldValue(ingredientData, idField, id)
    if (ix < 0) {
      console.log(`!! onIngredientPctFieldChange: cannot find ingredient for id ${id}`)
      return
    }

    if (floatValue > 100) {
      floatValue = 100
      strValue = '100'
    }

    const updatedIngredientData = ingredientData.slice()
    updatedIngredientData[ix] = {...ingredientData[ix], [unitField]: floatValue, [unitFieldStr]: strValue}
    setIngredientData(updatedIngredientData)
    setEditedIngredientId(id)
  }

  function onBlurIngredientUnitChange(evt) {
    if (editedIngredientId === undefined) {
      return
    }
    const workingIngredientData = deepCopy(ingredientData)
    const ix = findIndexForFieldValue(workingIngredientData, idField, editedIngredientId)
    if (ix >= 0) {
      // ensure floating point and string values are properly formed after onBlur
      repairValueStates(workingIngredientData[ix])
    }

    balanceIngredientPercentages(workingIngredientData, editedIngredientId)
    setIngredientData(workingIngredientData)

    setEditedIngredientId(undefined)
  }

  function repairValueStates(ingredientObj) {
    if (isNaN(ingredientObj[unitField]) || !ingredientObj[unitField]) {
      ingredientObj[unitField] = 0
    }
    let adjustedUnitFieldStr = ingredientObj[unitFieldStr]
    if (adjustedUnitFieldStr.endsWith('.')) {
      adjustedUnitFieldStr = adjustedUnitFieldStr.substring(0, adjustedUnitFieldStr.length - 1)
    }
    if (adjustedUnitFieldStr === '') {
      adjustedUnitFieldStr = '0'
    }
    ingredientObj[unitFieldStr] = adjustedUnitFieldStr
  }

  function balanceIngredientPercentages(workingIngredientData, ingredientId) {
    if (workingIngredientData.length === 1) {
      workingIngredientData[0][unitField] = 100
      workingIngredientData[0][unitFieldStr] = '100'
      return
    }

    const ix = findIndexForFieldValue(workingIngredientData, idField, ingredientId)
    if (ix < 0) {
      console.log(`!! balanceIngredientPercentages: cannot find ingredient for id ${ingredientId}`)
      return
    }

    const currentSum = getFieldValuesAsArray(workingIngredientData, unitField).reduce((pv, cv) => toFixedDecimal(pv + cv))
    const currentBalanceDiff = toFixedDecimal(100 - currentSum)
    if (currentBalanceDiff === 0) {
      return
    }
    // create a wrap-around list of keys excluding the edited key in order left-to-right...
    const tempKeys = getFieldValuesAsArray(workingIngredientData, nameField)
    const editedIngredientName = workingIngredientData[ix][nameField]
    const editedIndex = tempKeys.indexOf(editedIngredientName)
    const pre = tempKeys.slice(0,editedIndex)
    const post = tempKeys.slice(editedIndex+1)
    const workingKeys = post.concat(pre)

    // console.log(`### reduce based on field ${editedIngredientName}...current sum: ${currentSum}, balance diff: ${currentBalanceDiff}...`)
    workingKeys.reduce((pv, cv) => {
      if (pv === 0 ) {
        return pv
      }
      let ingredientObj = findObjectForFieldValue(workingIngredientData, nameField, cv)
      // console.log(`reducing ${cv} value ${ingredientObj[unitField]} by ${pv}...`)
      let currVal = ingredientObj[unitField] ?? 0
      let newPv = 0
      if (currVal + pv > 100) {
        newPv = toFixedDecimal((currVal + pv) - 100)
        ingredientObj[unitField] = 100
      } else if (currVal + pv < 0) {
        newPv = toFixedDecimal(currVal + pv)
        ingredientObj[unitField] = 0
      } else {
        ingredientObj[unitField] = toFixedDecimal(currVal + pv)
      }
      // maintain the String sibling field
      ingredientObj[unitFieldStr] = `${ingredientObj[unitField]}`
      // console.log(`Reduce: set ${cv} to ${ingredientObj[unitField]}`)
      return newPv
    }, currentBalanceDiff)
  }

  function addCustomIngredient() {
    const newIx = ingredientData.length + 1
    const initValue = newIx === 1 ? 100 : 0
    const uniqueId = `c.${Math.floor(Math.random() * 1000) + 1}`
    const newIngredientArr = ingredientData.slice()
    newIngredientArr.push({[nameField]: `${groupName} ${newIx}`, [unitField]: initValue, [unitFieldStr]: `${initValue}`, [idField]: uniqueId, group: true})
    setIngredientData(newIngredientArr)
  }

  function deleteIngredient(ingredientId) {
    let workingIngredientData = deepCopy(ingredientData)
    workingIngredientData = workingIngredientData.filter((fd) => fd[idField] !== ingredientId)
    const lastIngredientIx = workingIngredientData.length - 1

    if (lastIngredientIx >=0) {
      balanceIngredientPercentages(workingIngredientData, workingIngredientData[lastIngredientIx][idField])
    }
    setIngredientData(workingIngredientData)
  }

  return (
          <div>
            <Grid item container direction={'column'} rowSpacing={1}>
              {ingredientData.length === 0 && minGroupSize === 0 ? (
                      <Grid item xs={0.65} sm={1.25} md={1.25}>
                        <Box sx={{paddingTop: '0.6rem', paddingLeft: '0.5rem'}}>
                          <Tooltip title={`Add a ${groupName}`} placement={'right'} arrow>
                            <Fab size="small" color="secondary" aria-label="add">
                              <AddIcon onClick={addCustomIngredient}/>
                            </Fab>
                          </Tooltip>
                        </Box>
                      </Grid>
                ) : null
              }
              {ingredientData.map((fd, idx) => {
                return(
                <Grid key={fd[idField]} item container columnSpacing={1.5} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'} columns={{xs: 4, sm: 8, md: 12}}>
                  <Grid item xs={1.75} sm={3.25} md={5} >
                    <FormTextField size={'small'} disabled={disabled}
                              id="outlined-required"
                              inputProps={{'data-ingredientid': fd[idField]}}
                              name={nameField}
                              label={groupName}
                              value={fd[nameField]}
                              onChange={onIngredientNameChange}
                              variant="filled"/>
                  </Grid>
                  <Grid item xs={0.9} sm={1.5} md={3}>
                    <DecimalStringFormField valueStr={fd[unitFieldStr]} size={'small'} disabled={disabled}
                                      name={unitField} label={`${Units.getUnitLabel(unitPercent)} of ${groupName}`}
                                      onChange={onIngredientUnitFieldChange}
                                      onBlur={onBlurIngredientUnitChange}
                                      id={fd[idField]} />
                  </Grid>
                  <Grid item xs={0.5} sm={1} md={1}>
                    <IconButton sx={{paddingTop: '1.1rem'}} onClick={() => deleteIngredient(fd[idField])} placement="right" edge="end" disabled={disabled || (minGroupSize > 0 && ingredientData.length === 1)} >
                      <DeleteIcon/>
                    </IconButton>
                  </Grid>
                  {idx === (ingredientData.length - 1) ?
                    <Grid item xs={0.65} sm={1.25} md={1.25}>
                      <Box sx={{paddingTop: '0.6rem', paddingLeft: '0.5rem'}}>
                        <Tooltip title={`Add a ${groupName}`} placement={'right'} arrow>
                          <span>
                          <Fab disabled={disabled} size="small" color="secondary" aria-label="add">
                            <AddIcon onClick={addCustomIngredient}/>
                          </Fab>
                          </span>
                        </Tooltip>
                      </Box>
                    </Grid> : null}
                </Grid>)
              })
              }
            </Grid>
          </div>
  )
}

export { IngredientGroupForm }