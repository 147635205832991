import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import PercentIcon from '@mui/icons-material/Percent';
import PersonIcon from '@mui/icons-material/Person';
import NumbersIcon from '@mui/icons-material/Numbers';
import ScienceIcon from '@mui/icons-material/Science';
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Help(props) {
  return (
    <Grid container direction={'column'} padding={2} paddingLeft={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}} paddingRight={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}} justifyContent={'center'} alignItems={'center'}
          rowSpacing={1.5}>
      <Grid item xs={4} sm={8} md={12}>
        <Typography variant={'h5'}>Help</Typography>
      </Grid>

      <Grid item xs={4} sm={8} md={12} marginBottom={1}>
        <Typography display={'block'} variant={'body1'} fontStyle={'italic'}>App features and How-Tos</Typography>
      </Grid>

      <Grid item xs={4} sm={8} md={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
            <Typography variant={'h6'}>Overview</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography display={'block'} variant={'body1'} fontStyle={'italic'}>
              Most formula menu options use collapsible/expandable panels to isolate different formula topics,
              e.g. Preferment, Main Dough, and Notes & Instructions.<br/>
              After completing one panel, you may collapse that panel without losing anything.
              Collapsing panels helps avoid contextual confusion, especially on smaller devices.<br/><br/>
            </Typography>
            <Typography color={'black'} variant={'h6'}>
              <PercentIcon sx={{marginBottom: -0.5}} color={'dark'}/>
            </Typography>
            <Typography display={'block'} variant={'body1'}>
              To design a new dough formula, click on the <b>Ratio</b> menu option. Pick your target dough weight, then create a percentage-based "formula" that describes the flour, hydration, and levain (preferment) ratios.
              As you design your formula, rise time projection is presented, based on air temperature and chosen ratio of starter or preferment.<br/>
              Use the Apply button to generate a printable recipe with all ingredients in grams.<br/><br/>
            </Typography>
            <Typography color={'black'} variant={'h6'}>
              <NumbersIcon sx={{marginBottom: -0.5}} color={'dark'}/>
            </Typography>
            <Typography display={'block'} variant={'body1'}>
              If you want to start with an existing recipe, click on the <b>Quantity</b> menu option and enter the recipe's ingredients and respective unit measurements.
              The <b>Quantity</b> option lets you render the recipe as-is, or you can convert it to a percentage-based <b>Ratio</b> formula,
              enabling it to be scalable.<br/><br/>
            </Typography>
            <Typography color={'black'} variant={'h6'}>
              <ScienceIcon sx={{marginBottom: -0.5}} color={'dark'}/>
            </Typography>
            <Typography display={'block'} variant={'body1'}>
              Use the <b>Levain</b> menu option for a quick, scalable levain formula-to-recipe calculator.<br/><br/>
            </Typography>

            <Typography color={'black'} variant={'h6'}>
              <SaveIcon sx={{marginBottom: -0.5}} color={'dark'}/>
            </Typography>
            <Typography display={'block'} variant={'body1'}>
              All three formula menus include a <b>Save</b> button that lets you save your formula specification with a unique name.
              You can access previously Saved formulas from the <b>Saved</b> menu option.<br/><br/>
            </Typography>
            <Typography variant={'body1'} fontStyle={'italic'}>
              Note that each time you visit a formula create menu option, you will see prefilled ingredients and percentages or weights.
              These are simply pre-built examples that serve as a starting point for you to change.<br/>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
            <Typography variant={'h6'}>Terminology</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant={'body1'}>
              <b>Bakers Percentage</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              Commonly used in baking, an ingredient's percentage as a ratio to the sum of the weight of all flour for the recipe, including the Main flour, and Preferment flour, if specified.
            </Typography>
            <Typography variant={'body1'}>
              <b>Bulk Fermentation</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              The initial phase of the main dough development where yeast feeding and carbon dioxide release occurs.
              This phase begins when you add your leavening agent to the flour and water mix.
              This phase ends after the dough has become smooth, elastic (almost jelly-like), and the volume has increased
              by 25-30%.  After dividing and shaping, the final proofing phase begins, where more expansion can occur.
            </Typography>
            <Typography variant={'body1'}>
              <b>Final Proof</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              Second and final phase of the main dough development where additional yeast activity occurs within the final shaped dough.
              Often in sourdough baking, final proofing is done in the refrigerator for 8 or more hours to enhance flavor,
              but can also be done at room temperature in as few as 1-2 hours.
              Final proofing is complete when the dough is elastic to the touch (poke test) and still has some yeast
              activity in reserve for the bake rise.
            </Typography>
            <Typography variant={'body1'}>
              <b>Float Test</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              A test to detect the readiness of a levain for use in a main dough mix.  Take a small amount of your
              levain (1/2 tsp or so) and drop into a beaker of water.  If it floats, it is mature enough to use - the amount of carbon dioxide from
              yeast production is adequate.  If it sinks, it either needs more time, or is past its peak.
              A levain past its peak will have lots of bubbles on top and some reduction in volume.
              Levain in this state can be refreshed by adding about 20-30% more flour, then give it another 45-90 minutes.
            </Typography>
            <Typography variant={'body1'}>
              <b>Leavening or Leavening Agent</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              General term for an ingredient that contains live yeast culture without regard to its yeast productivity/maturity.
              This can be dry or cake yeast, or a sourdough starter culture (contains a yeast strain and bacteria organisms).
            </Typography>
            <Typography variant={'body1'}>
              <b>Levain</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              This French-derived word used in lots of contexts.  In this app, it refers to a specially
              prepared mix of sourdough starter seed, flour and water with the intention of using most or all of it in
              a bread dough mixture as its leavening agent. A levain is an offshoot of, and separate from a "starter" that is being maintained
              and refreshed as a baker's preserved sourdough culture.
            </Typography>
            <Typography variant={'body1'}>
              <b>Mother</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              The "parent" or preexisting starter, from which a portion is used as "starter seed" to prepare a levain, or to prepare the next
              generation of the long-living starter.
            </Typography>
            <Typography variant={'body1'}>
              <b>Poke Test</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              A test to determine whether a dough is complete with its final proof stage and ready for baking.
              The dough is properly proofed if you can gently poke a finger into the dough and see the dent slowly spring back.
              When your finger dent doesn't recover, it could be under- or over-proofed, depending on the baker's instinct.
              Give it more time if you believe it's under proofed, but an overproofed dough cannot be fixed.
            </Typography>
            <Typography variant={'body1'}>
              <b>Preferment</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              A mixture of pre-fermented flour that is ultimately used as the leavening agent in a dough mixture.  Preferment
              is a broader term to be inclusive of either a sourdough levain, or a non-sourdough, yeast-only mix such as a "Poolish".
            </Typography>
            <Typography variant={'body1'}>
              <b>PFF</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              Pre-Fermented Flour, used in the context of flour-based percentages. A percentage in terms of PFF means the
              percentage of the flour contained in a preferment in relation to the sum total of all flour in the recipe, preferment flour included.
              For example if you have a dough recipe with 600g flour, and a levain preferment made from 100g flour, you have a total of
              700g flour in the recipe. The %PFF of the preferment is then 100 / 700 = 14.28%.  In contrast, a preferment can also be specified in
              terms of percent by total weight.  In that case, if the preferment of 100g flour also had 100g water and 20g starter, the
              preferment would weigh 220g.  So the total weight percent would be 220 / 700 (bakers percentage!) = 31.4%
            </Typography>
            <Typography variant={'body1'}>
              <b>Starter or Starter Seed</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              Generic term for the baker's sourdough culture that is replenished on a regular basis to keep it viable
              over time. The baker extracts a portion of mature starter for use as "seed" to build a levain preferment.
              In some cases, if the starter isn't overly mature and there is adequate starter volume, a portion of the starter
              can be used directly as a levain to mix into a dough.  This should only be done if there is sufficient starter remaining to
              seed its next generation.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={4} sm={8} md={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
            <Typography color={'black'} variant={'h6'}>
              <PercentIcon sx={{marginBottom: -0.5}} color={'dark'}/> Ratio
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontStyle={'italic'} fontWeight={'800'} variant={'body1'}>
              When you want to create a percentage-based formula from scratch...<br/>
            </Typography>
            <Typography variant={'body1'}>
              The <b>Ratio</b> menu option is the primary method for creating a formula using Bakers Percentages.<br/>
              This option can either be used in a <b>simple</b> or <b>detailed</b> mode, depending on the need for levain/preferment details.<br/>
              The simple/detailed mode is controlled by the <b>Add Preferment Formula</b> switch:
            </Typography>
            <List>
              <ListItem><Typography variant={'body1'}>1. Without Preferment Formula (default)</Typography></ListItem>
              <ListItem><Typography variant={'body1'}>2. With Preferment Formula</Typography></ListItem>
            </List>
            <Typography variant={'body1'}>
              The first mode, <b>Without Preferment Formula</b>, is simplest.
              Expand the Main Dough panel and in the Leavening section, simply set the Levain or Dry Yeast as a percentage of the dough flour.
              This mode treats the levain as a simple ingredient and does not describe the flour, water, or starter makeup of the levain.
              Note that changing the percentage of levain (or yeast) elicits a "rise" time estimate that is sensitive to the air temperature chosen in the drop-down.
              The amount of "rise" referred to here is the overall dough bulk fermentation volume increase, which is usually 25-30%.
              <br/><br/>

              The second mode, <b>With Preferment Formula</b>, introduces a Preferment panel above the Main Dough panel.
              The Preferment panel asks for more details about the levain makeup, including flour, water and starter seed or yeast amount.
              This mode is useful when planning for an unusual levain hydration level that would influence the overall dough hydration, or a specific
              mixture of flours that you want to record as part of the overall formula.<br/>
              The Preferment panel has its own Starter or Dry Yeast percentage slider controls.
              Changes to these percentages elicits a "rise" time estimate that is sensitive to the air temperature chosen in the drop-down.
              The amount of "rise" referred to here is the Preferment rise which is usually optimal for mixing into the main dough at 90-100% rise.<br/>
              When using this mode, the Main Dough panel has a Preferment % PFF slider control added to it.
              This slider control determines the percentage of Preferment flour in relation to the overall recipe flour, which includes both Preferment and Main flours.<br/>
              This percentage control is akin to the Levain or Dry Yeast percentage controls in the simpler mode, except it uses PFF percent instead of weight percent.<br/>
              This percent control also elicits the main dough "rise" time projection based on air temperature as described above.
              <br/><br/>
              Based on a target dough or flour weight, choose your hydration%, itemize your liquids and flours with their
              ratios to one another, and itemize your mixins as a percent of the recipe flour. <br/>
              Your leavening agent specifications will either involve the Preferment panel or not, depending on which Preferment mode you are using as outlined above.
              Note that Dry Yeast percentages are specified as a ratio of yeast volume per 100 grams of flour, but the generated recipe
              will show yeast amounts in both grams and volume measurements, e.g. 1/4 tsp.
              <br/> <br/>

              Enter any special instructions you want to include in the recipe using the Notes & Instructions panel.<br/>
              Click the <b>Apply</b> button and a print-ready recipe is generated as you scroll down.
              <br/> Give your formula a <i>unique name</i> and click the <b>Save</b> button to keep your formula!
              <br/>
              Note that currently, the Save button only saves your formula to your browser's local storage, and not to any central server. More on this below.<br/>
              Saved formulas are accessible any time from the <b>Saved</b> menu option.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={4} sm={8} md={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
            <Typography color={'black'} variant={'h6'}>
              <NumbersIcon sx={{marginBottom: -0.5}} color={'dark'}/> Quantity
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontStyle={'italic'} fontWeight={'800'} variant={'body1'}>
              When you want to start with an existing recipe and optionally convert it to Ratio-based... <br/>
            </Typography>
            <Typography display={'block'} variant={'body1'}>
              The <b>Quantity</b> menu option lets you enter ingredients as pre-determined weight or volume quantities.
              This option is useful for converting an existing recipe to a scalable Ratio recipe by using the <b>Save as Ratio</b> button.<br/>
              The Quantity formula recipe can also be rendered as-is without converting to a Ratio formula.
              When rendering a recipe from the originally entered formula, cups, teaspoons and other volume units are converted
              to weight in grams but the original volume units are also shown.<br/>
              Like the <b>Ratio</b> menu option, the Quantity menu option can be used in a simple or detailed mode by enabling
              the Add Preferment Formula toggle.  Use of the Add Preferment Formula depends on the original recipe and your
              desire to capture the Preferment details, or not.<br/>
              When entering ingredients
              The familiar <b>Apply</b> and <b>Save</b> buttons perform the same as for the Ratio menu option.<br/>
              The <b>Save as Ratio</b> button lets you convert it to a scalable Ratio formula.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={4} sm={8} md={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
            <Typography color={'black'} variant={'h6'}>
              <ScienceIcon sx={{marginBottom: -0.5}} color={'dark'}/> Levain
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontStyle={'italic'} fontWeight={'800'} variant={'body1'}>
              When you want to build a special levain using ratios...<br/>
            </Typography>
            <Typography display={'block'} variant={'body1'}>
              The <b>Levain</b> menu option is a Bakers Percentage-based Levain/Starter formula create option.<br/>
              Use this for a quick calculation to build tomorrow morning's levain, and also see the projected "rise" time, based on
              the percentage of starter seed chosen. <br/>
              For example, if your dough formula recipe calls for 220g of a 60% stiff levain, set your Levain Hydration to 60%, and your Starter Seed %
              to obtain the desired "rise" time, depending on when you plan to mix the dough.<br/>
            </Typography>
            <Typography display={'block'} variant={'body1'} fontStyle={'italic'}>
              Note: The Mother Seed and Mother Hydration settings aren't consequential in most smaller batches.
              However, in larger batches and/or larger seed amounts where the mother starter is maintained with a higher hydration, for example,
              this can influence the overall hydration percentages.  So in these cases, providing more accurate Mother specifications
              will produce a more accurate overall hydration level.
            </Typography>
            <Typography display={'block'} variant={'body1'}>
              Like the other menu options, the <b>Apply</b> button generates the "recipe" and the <b>Save</b> button persists the formula.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={4} sm={8} md={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
            <Typography color={'black'} variant={'h6'}>
              <SaveIcon sx={{marginBottom: -0.5}} color={'dark'}/> Saved
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography display={'block'} variant={'body1'}>
              The <b>Saved</b> menu option is where you access previously saved Ratio, Quantity or Levain formulas.<br/>
              Your saved items remain despite closing your tabs and shutting down your computer.<br/>
              However, since this app stores saved items in your browser's local storage and, currently, not on a server, there are
              some limitations to be aware of:
              <br/>
              1. In your browser, doing a hard cache clear or privacy clear may wipe out your local storage.
              <br/>
              2. Saved formulas are visible only to the device on which you clicked Save, i.e. items saved while using your
              iPad will <b>not</b> automatically appear on your Desktop computer or your smart phone.
              <br/>
              3. To protect your saved formulas or share them among devices, there are Export and Import icon buttons
              on the <b>Saved</b> screen.
              <br/>
              4. To share a backup from your iPad to your phone, for example...<br/>
              &nbsp; a. Click the Export (down arrow) icon on your iPad.  <b>Note that only the selected formula type is exported.</b>
              <br/>
              &nbsp; b. When the exported file is rendered in a new tab on your iPad, click the browser Share option and
              choose
              an option such as Airdrop, SMS, or email to your destination device.  On Apple devices, iCloud Drive also works well
              for sharing among devices.
              <br/>
              &nbsp; c. Once your export file is captured on your destination device, open this app in a browser on your
              destination device and
              click the Import button. Choose the file just shared to this device to complete the import.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={4} sm={8} md={12}>
        <Typography color={'black'} variant={'h6'}>
          <PersonIcon sx={{marginBottom: -0.5}} color={'dark'}/> About Me
        </Typography>
        <Typography display={'block'} variant={'body1'}>
          I'm a sourdough baking enthusiast since 2019... and still learning!
        </Typography>
        <Typography sx={{ fontSize: 14}}>dan.r.alms@gmail.com</Typography>
      </Grid>
    </Grid>
  )
}

export { Help }