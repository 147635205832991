import * as Types from "../shared/Types";
import {
  BATCH_TYPE_FLOUR_WT,
  LEVAIN_PCT_TYPE_PFF,
  LEVAIN_PCT_TYPE_TOTAL_WT,
  noPreferment, PREFERMENT_BUFFER_WEIGHT
} from "../shared/Types";
import {getAutolyseLiquidPct, unitGrams, unitTeaspoon} from "../shared/Units";
import {toFixedDecimal, toFixedWholePercent} from "../utils/NumberUtils";
import {getYeastPercentFromId, getYeastTotalTspFromId, yeastDefs} from "../shared/Yeast";
import {getLevainTime, getYeastTime} from "../shared/FermentationTiming";
import {
  calculateLevainFlourByTotalWeight,
  calculatePrimaryLevainComponentWeights
} from "../levain/LevainRecipeCalculator";

const ratioRecipeItems = {
  formName: '',
  formType: '',
  attribution: '',
  createdAt: '',
  modifiedAt: '',
  notes: '',
  numBatches: 1,
  batchSize: 0,
  batchType: Types.BATCH_TYPE_TOTAL_WT,
  includePreferment: false,
  recipeFlourTotalWeight: 0,
  recipeLiquidTotalWeight: 0,
  recipeYeastTotalWeight: 0,
  recipeStarterTotalWeight: 0,
  recipeMixinTotalWeight: 0,
  recipeSoakerTotalWeight: 0,
  recipeUnitWeight: 0,
  recipeTotalWeight: 0,
  mainDough: {
    hydrationPct: 0,
    hydrationPctFraction: 0,
    autolyse: false,
    bulkFermPct: 0,
    bulkFermTempF: '0',
    bulkFermTimeStr: '',
    prefermentPffPct: 0,
    prefermentPffPctFraction: 0,
    starterType: LEVAIN_PCT_TYPE_TOTAL_WT,
    starterPct: 0,
    starterAltTypePct: 0,
    starterPctFraction: 0,
    starterHydrationPct: 0,
    starterHydrationPctFraction: 0,
    yeastInputRatioId: 0,
    yeastInputPct: 0,
    yeastInputPctFraction: 0,
    yeast: {
      flourUnitGrams: yeastDefs.flourUnitGrams,
      ratioId: 0,
      percent: 0,
      weight: 0,
      altUnit: unitTeaspoon,
      altUnitAmount: 0,
      volumeDisplayLabel: ''
    },
    starterWeight: 0,
    starterAltUnit: unitGrams,
    starterAltUnitAmount: 0,
    starterFlourWeight: 0,
    starterLiquidWeight: 0,
    flourWeight: 0,
    flourInput: [],
    flour: [],
    liquidWeight: [],
    liquidInput: [],
    liquid: [],
    mixinInput: [],
    mixin: [],
    soakerInput: [],
    soaker: [],
    toppingsInput: [],
    toppings: [],
    totalWeight: 0,
    prefermentByWeightPct: 0
  },
  prefermentDough: {
    prefermentType: noPreferment,
    hydrationPct: 0,
    hydrationPctFraction: 0,
    autolyse: false,
    levainFermPct: 0,
    levainFermTempF: '0',
    levainFermTimeStr: '',
    starterType: LEVAIN_PCT_TYPE_TOTAL_WT,
    starterPct: 0,
    starterAltTypePct: 0,
    starterPctFraction: 0,
    starterHydrationPct: 0,
    starterHydrationPctFraction: 0,
    yeastInputRatioId: 0,
    yeastInputPct: 0,
    yeastInputPctFraction: 0,
    yeast: {
      flourUnitGrams: yeastDefs.flourUnitGrams,
      ratioId: 0,
      percent: 0,
      weight: 0,
      altUnit: unitTeaspoon,
      altUnitAmount: 0,
      volumeDisplayLabel: ''
    },
    starterWeight: 0,
    starterAltUnit: unitGrams,
    starterAltUnitAmount: 0,
    starterFlourWeight: 0,
    starterLiquidWeight: 0,
    addBuffer: false,
    flourWeight: 0,
    flourInput: [],
    flour: [],
    liquidWeight: [],
    liquidInput: [],
    liquid: [],
    totalWeight: 0,
    totalBufferedWeight: 0
  }
}


function calculateDoughRecipe(inputData) {
  let items = JSON.parse(JSON.stringify(ratioRecipeItems))
  items.formName = inputData.formName
  items.formType = inputData.formType
  items.attribution = inputData.attribution
  items.createdAt = inputData.createdAt
  items.modifiedAt = inputData.modifiedAt
  items.notes = inputData.notes
  items.numBatches = inputData.numBatches
  items.batchSize = inputData.batchSize
  items.batchType = inputData.batchType
  items.includePreferment = inputData.includePreferment
  items.mainDough.autolyse = inputData.autolyse
  items.mainDough.bulkFermPct = inputData.bulkFermPct
  items.mainDough.bulkFermTempF = inputData.bulkFermTempF
  items.mainDough.prefermentPffPct = inputData.prefermentPffPct
  items.mainDough.prefermentPffPctFraction = inputData.prefermentPffPct / 100
  items.mainDough.hydrationPct = inputData.hydrationPct
  items.mainDough.hydrationPctFraction = inputData.hydrationPct / 100
  items.mainDough.starterType = inputData.starterType
  items.mainDough.starterPct = inputData.starterPct
  items.mainDough.starterPctFraction = inputData.starterPct / 100
  items.mainDough.starterHydrationPct = inputData.starterHydrationPct
  items.mainDough.starterHydrationPctFraction = inputData.starterHydrationPct / 100
  items.mainDough.yeastInputRatioId = inputData.yeastRatioId
  items.mainDough.yeastInputPct = inputData.yeastPct
  items.mainDough.yeastInputPctFraction = inputData.yeastPct / 100
  items.mainDough.flourInput = inputData.flour
  items.mainDough.liquidInput = inputData.liquid
  items.mainDough.mixinInput = inputData.mixin
  items.mainDough.soakerInput = inputData.soaker
  items.mainDough.toppingsInput = inputData.toppings
  if (inputData.includePreferment) {
    items.prefermentDough.prefermentType = inputData.prefermentData.prefermentType
    items.prefermentDough.autolyse = inputData.prefermentData.autolyse
    items.prefermentDough.levainFermPct = inputData.prefermentData.levainFermPct
    items.prefermentDough.levainFermTempF = inputData.prefermentData.levainFermTempF
    items.prefermentDough.hydrationPct = inputData.prefermentData.hydrationPct
    items.prefermentDough.hydrationPctFraction = inputData.prefermentData.hydrationPct / 100
    items.prefermentDough.yeastInputRatioId = inputData.prefermentData.yeastRatioId
    items.prefermentDough.yeastInputPct = inputData.prefermentData.yeastPct
    items.prefermentDough.yeastInputPctFraction = inputData.prefermentData.yeastPct / 100
    items.prefermentDough.starterType = inputData.prefermentData.starterType
    items.prefermentDough.starterPct = inputData.prefermentData.starterPct
    items.prefermentDough.starterPctFraction = inputData.prefermentData.starterPct / 100
    items.prefermentDough.starterHydrationPct = inputData.prefermentData.starterHydrationPct
    items.prefermentDough.starterHydrationPctFraction = inputData.prefermentData.starterHydrationPct / 100
    items.prefermentDough.addBuffer = inputData.prefermentData.addBuffer
    items.prefermentDough.flourInput = inputData.prefermentData.flour
    items.prefermentDough.liquidInput = inputData.prefermentData.liquid
  }
  return calculateRecipeWeights(items)
}

function calculateRecipeWeights(items) {
  let flourAndLiquidWeights = calculateRecipeLiquidAndFlour(items)
  let recipeFlourWeight = flourAndLiquidWeights.flourWeight
  let recipeLiquidWeight = flourAndLiquidWeights.liquidWeight

  let primaryWeights = calculateAllComponentWeights(recipeFlourWeight, items)

  let prefermentDoughFlourWeight = primaryWeights.prefermentDough.doughFlourWeight
  let prefermentLevainWeight = primaryWeights.prefermentDough.levainWeight
  let prefermentLevainFlourWeight = primaryWeights.prefermentDough.levainFlourWeight
  let prefermentLevainLiquidWeight = primaryWeights.prefermentDough.levainLiquidWeight
  let prefermentDoughLiquidWeight = primaryWeights.prefermentDough.doughLiquidWeight

  let mainDoughFlourWeight = primaryWeights.mainDough.doughFlourWeight
  let mainLevainWeight = primaryWeights.mainDough.levainWeight
  let mainLevainFlourWeight = primaryWeights.mainDough.levainFlourWeight
  let mainLevainLiquidWeight = primaryWeights.mainDough.levainLiquidWeight
  // mainDough liquid weight = remainder after any preferment and levain water (preferment hydration is given priority)
  let mainDoughLiquidWeight = recipeLiquidWeight - prefermentDoughLiquidWeight - prefermentLevainLiquidWeight - mainLevainLiquidWeight

  consoleLog(`mainDoughLiquidWeight: ${mainDoughLiquidWeight}, prefermentDoughLiquidWeight: ${prefermentDoughLiquidWeight}`)

  // recalculate mainDough flour based on ratios for each flour
  let mainDoughFlourWeightAdjusted = calculateFlourDistribution(items.mainDough, mainDoughFlourWeight)

  // recalculate mainDough liquids based on ratios for each liquid
  let mainDoughLiquidWeightAdjusted = calculateLiquidDistribution(items.mainDough, mainDoughLiquidWeight)

  items.mainDough.liquidWeight = mainDoughLiquidWeightAdjusted
  if (items.mainDough.autolyse) {
    // highest weight to the top
    items.mainDough.liquid.sort((a, b) => b.weight - a.weight)
    const highestWeightLiquid = items.mainDough.liquid.shift()
    const autolysePctFraction = getAutolyseLiquidPct(highestWeightLiquid.weight / mainDoughFlourWeightAdjusted)
    const autolyseWeight = Math.round(highestWeightLiquid.weight * autolysePctFraction)
    const autolysePct = autolyseWeight / mainDoughLiquidWeightAdjusted
    const finalMixWeight = highestWeightLiquid.weight - autolyseWeight
    const finalMixPct = finalMixWeight / mainDoughLiquidWeightAdjusted
    items.mainDough.liquid.unshift({id: highestWeightLiquid.id, name: highestWeightLiquid.name, group: 'Liquid', percent: toFixedWholePercent(finalMixPct, 2), weight: finalMixWeight})
    items.mainDough.liquid.unshift({id: `a.${highestWeightLiquid.id}`, name: `Autolyse ${highestWeightLiquid.name}`, group: 'Liquid', percent: toFixedWholePercent(autolysePct, 2), weight: autolyseWeight})
  }
  
  // recalculate prefermentDough flour based on ratios for each flour
  let prefermentDoughFlourWeightAdjusted = calculateFlourDistribution(items.prefermentDough, prefermentDoughFlourWeight)

  // recalculate prefermentDough liquids based on ratios for each liquid
  let prefermentDoughLiquidWeightAdjusted = calculateLiquidDistribution(items.prefermentDough, prefermentDoughLiquidWeight)
  items.prefermentDough.liquidWeight = prefermentDoughLiquidWeightAdjusted
  // prefermentDough autolyse not supported; repeat mainDough autolyse mods here if needed in future

  consoleLog(`mainDoughLiquidWeightAdjusted: ${mainDoughLiquidWeightAdjusted}, prefermentDoughLiquidWeightAdjusted: ${prefermentDoughLiquidWeightAdjusted}`)

  // account for adjusted dough flour weights in the recipeflour weight
  recipeFlourWeight = Math.round(mainDoughFlourWeightAdjusted + mainLevainFlourWeight + prefermentDoughFlourWeightAdjusted + prefermentLevainFlourWeight)
  let recipeMixinWeight = 0
  items.mainDough.mixinInput.forEach((md, ix) => {
    let mixinWeight = Math.round(recipeFlourWeight * (md.percent / 100))
    items.mainDough.mixin.push({id: md.id, name: md.name, percent: md.percent, weight: mixinWeight})
    recipeMixinWeight += mixinWeight
  })

  let recipeSoakerWeight = 0
  items.mainDough.soakerInput.forEach((sd, ix) => {
    let soakerWeight = Math.round(recipeFlourWeight * (sd.percent / 100))
    items.mainDough.soaker.push({id: sd.id, name: sd.name, percent: sd.percent, weight: soakerWeight})
    recipeSoakerWeight += soakerWeight
  })
  // topping items are just a category of mixins, but weight doesn't count in dough weight
  items.mainDough.toppingsInput.forEach((td, ix) => {
    items.mainDough.toppings.push({id: td.id, name: td.name, group: 'Toppings', percent: td.percent, weight: 0})
  })

  // prefermentDough weights...
  items.prefermentDough.starterWeight = prefermentLevainWeight
  items.prefermentDough.starterFlourWeight = Math.round(prefermentLevainFlourWeight)
  items.prefermentDough.starterLiquidWeight = prefermentLevainLiquidWeight
  items.prefermentDough.flourWeight = prefermentDoughFlourWeightAdjusted
  if (items.prefermentDough.starterType === LEVAIN_PCT_TYPE_TOTAL_WT) {
    items.prefermentDough.starterAltTypePct = levainTwToPff(items.prefermentDough.flourWeight, items.prefermentDough.starterPct)
  } else {
    items.prefermentDough.starterAltTypePct = levainPffToTw(items.prefermentDough.flourWeight, items.prefermentDough.starterWeight)
  }
  items.prefermentDough.yeast = calculateYeastForRatio(prefermentDoughFlourWeightAdjusted + prefermentLevainFlourWeight, items.prefermentDough.yeastInputRatioId, items.prefermentDough.yeastInputPct)
  items.prefermentDough.totalWeight = Math.round(prefermentDoughFlourWeightAdjusted + prefermentDoughLiquidWeightAdjusted + prefermentLevainWeight + items.prefermentDough.yeast.weight)


  // mainDough weights...
  items.mainDough.starterWeight = mainLevainWeight
  items.mainDough.starterFlourWeight = Math.round(mainLevainFlourWeight)
  items.mainDough.starterLiquidWeight = mainLevainLiquidWeight
  items.mainDough.flourWeight = mainDoughFlourWeightAdjusted
  if (items.mainDough.starterType === LEVAIN_PCT_TYPE_TOTAL_WT) {
    items.mainDough.starterAltTypePct = levainTwToPff(items.mainDough.flourWeight, items.mainDough.starterPct)
  } else {
    items.mainDough.starterAltTypePct = levainPffToTw(items.mainDough.flourWeight, items.mainDough.starterWeight)
  }
  items.mainDough.yeast = calculateYeastForRatio(mainDoughFlourWeightAdjusted + mainLevainFlourWeight, items.mainDough.yeastInputRatioId, items.mainDough.yeastInputPct)
  items.mainDough.totalWeight = Math.round(mainDoughFlourWeightAdjusted + mainDoughLiquidWeightAdjusted + mainLevainWeight + recipeMixinWeight + recipeSoakerWeight + items.mainDough.yeast.weight)
  items.mainDough.prefermentByWeightPct = toFixedWholePercent(items.prefermentDough.totalWeight / items.mainDough.flourWeight, 2)

  // timing projections...
  if (items.prefermentDough.flourWeight > 0) {
    // bulk timing based on preferment
    let levainPct = items.mainDough.prefermentByWeightPct
    if (items.prefermentDough.levainFermPct < 100) {
      // adjustment for less than fully mature levain
      levainPct = Math.round(levainPct * (items.prefermentDough.levainFermPct / 100))
    }
    items.mainDough.bulkFermTimeStr = getLevainTime(items.mainDough.bulkFermTempF, items.mainDough.bulkFermPct, levainPct, items.mainDough.hydrationPct)
    // preferment timing
    if (items.prefermentDough.starterPct > 0) {
      items.prefermentDough.levainFermTimeStr = getLevainTime(items.prefermentDough.levainFermTempF, items.prefermentDough.levainFermPct, items.prefermentDough.starterPct, items.prefermentDough.hydrationPct)
    } else if (items.prefermentDough.yeastInputRatioId > 0) {
      items.prefermentDough.levainFermTimeStr = getYeastTime(items.prefermentDough.levainFermTempF, items.prefermentDough.levainFermPct, items.prefermentDough.yeastInputRatioId, items.prefermentDough.hydrationPct)
    }
  } else if (items.mainDough.starterPct > 0) {
    // bulk timing based on levain as ingredient
    const levainPct = items.mainDough.starterType === LEVAIN_PCT_TYPE_PFF ? items.mainDough.starterAltTypePct : items.mainDough.starterPct
    items.mainDough.bulkFermTimeStr = getLevainTime(items.mainDough.bulkFermTempF, items.mainDough.bulkFermPct, levainPct, items.mainDough.hydrationPct)
  } else if (items.mainDough.yeastInputRatioId > 0) {
    // bulk timing based on yeast as ingredient
    items.mainDough.bulkFermTimeStr = getYeastTime(items.mainDough.bulkFermTempF, items.mainDough.bulkFermPct, items.mainDough.yeastInputRatioId, items.mainDough.hydrationPct)
  }

  // finalize recipe totals...
  let yeastWeight = toFixedDecimal(items.mainDough.yeast.weight + items.prefermentDough.yeast.weight, 1)
  items.recipeFlourTotalWeight = recipeFlourWeight
  items.recipeLiquidTotalWeight = mainDoughLiquidWeightAdjusted + mainLevainLiquidWeight +  prefermentDoughLiquidWeightAdjusted + prefermentLevainLiquidWeight
  items.recipeMixinTotalWeight = recipeMixinWeight
  items.recipeSoakerTotalWeight = recipeSoakerWeight
  items.recipeYeastTotalWeight = yeastWeight

  // Note that recipe levain weight is only non-zero when using levain as total weight
  //   when levain specified as PFF, the levain flour and water are included in recipe flour and water
  let recipeLevainWeight = 0
  if (items.prefermentDough.starterType === LEVAIN_PCT_TYPE_TOTAL_WT) {
    recipeLevainWeight += prefermentLevainWeight
  }
  if (items.mainDough.starterType === LEVAIN_PCT_TYPE_TOTAL_WT) {
    recipeLevainWeight += mainLevainWeight
  }
  items.recipeStarterTotalWeight = recipeLevainWeight

  items.recipeTotalWeight = items.mainDough.totalWeight + items.prefermentDough.totalWeight
  items.recipeUnitWeight = Math.round(items.recipeTotalWeight / items.numBatches)

  if (items.includePreferment && items.prefermentDough.addBuffer && items.prefermentDough.starterType === LEVAIN_PCT_TYPE_TOTAL_WT) {
    let targetPrefermentWeight = items.prefermentDough.totalWeight + PREFERMENT_BUFFER_WEIGHT
    const newPrefermentFlourWeight = calculateLevainFlourByTotalWeight(targetPrefermentWeight, items.prefermentDough.starterPctFraction, items.prefermentDough.hydrationPctFraction)
    const prefermentComponents = calculatePrimaryLevainComponentWeights(newPrefermentFlourWeight, items.prefermentDough.starterPctFraction, items.prefermentDough.hydrationPctFraction)
    const newPrefermentLiquidWeight = prefermentComponents.levainLiquidWeight
    items.prefermentDough.starterWeight = Math.round(prefermentComponents.levainStarterWeight)
    items.prefermentDough.yeast = calculateYeastForRatio(newPrefermentFlourWeight, items.prefermentDough.yeastInputRatioId, items.prefermentDough.yeastInputPct)
    // recalculate prefermentDough flour based on ratios for each flour
    prefermentDoughFlourWeightAdjusted = calculateFlourDistribution(items.prefermentDough, newPrefermentFlourWeight)
    // recalculate prefermentDough liquids based on ratios for each liquid
    prefermentDoughLiquidWeightAdjusted = calculateLiquidDistribution(items.prefermentDough, newPrefermentLiquidWeight)
    items.prefermentDough.totalBufferedWeight = Math.round(prefermentDoughFlourWeightAdjusted + prefermentDoughLiquidWeightAdjusted + items.prefermentDough.starterWeight + items.prefermentDough.yeast.weight)
    consoleLog(`bufferedPreferment, totalBufferedWeight=${items.prefermentDough.totalBufferedWeight}, originalWeight=${items.prefermentDough.totalWeight}`)
  }

  return items;
}

// recalculate dough flour based on ratios for each flour
function calculateFlourDistribution(dough, doughFlourWeight) {
  let doughFlourWeightAdjusted = 0
  dough.flour = []
  dough.flourInput.forEach((fd, ix) => {
    let flourWeight = Math.round(doughFlourWeight * (fd.percent / 100))
    dough.flour.push({id: fd.id, name: fd.name, group: 'Flour', percent: fd.percent, weight: flourWeight
    })
    doughFlourWeightAdjusted += flourWeight
  })
  return doughFlourWeightAdjusted
}

// recalculate dough liquids based on ratios for each liquid
function calculateLiquidDistribution(dough, doughLiquidWeight) {
  let doughLiquidWeightAdjusted = 0
  dough.liquid = []
  dough.liquidInput.forEach((ld, ix) => {
    let liquidWeight = Math.round(doughLiquidWeight * (ld.percent / 100))
    dough.liquid.push({id: ld.id, name: ld.name, group: 'Liquid', percent: ld.percent, weight: liquidWeight})
    doughLiquidWeightAdjusted += liquidWeight
  })
  return doughLiquidWeightAdjusted
}

// derive overall recipe total flour, which everything else is based on
function calculateRecipeLiquidAndFlour(items) {
  let recipeFlourWeight
  if (items.batchType === BATCH_TYPE_FLOUR_WT) {
    // user-specified total flour weight
    recipeFlourWeight = Math.round(items.numBatches * items.batchSize)
  } else {
    // When the batch is specified as a total weight, solve for the recipe flour by dividing total weight
    // by the known ingredient percentages:
    //   1. Sum of mixin percentages
    //   2. Sum of dry yeast percentages
    //   3. Sum of total levain percentages (if levain is specified as a total weight ingredient rather than in PFF terms)
    //   4. Overall hydration percent
    // TWc = rf + rf*(sum(Mi)) + rf*yp/100 + rf*hp [+ rf*lp]  :: where [] = optional if levain is %total weight
    //  rf = TWc / (1 + sum(Mi) + yp/100 + hp [+ lp])         :: where [] = optional if levain is %total weight
    // where:
    //    TWc = total weight (constant)
    //     rf = recipe flour
    //     df = dough flour
    //     lf = levain (preferment) flour
    //     Mi = mixin pct
    //     yp = yeast pct (percent per 100g flour here)
    //     lp = levain pct (in the No Preferment scenario, we use the "dough.starter*" fields to represent levain)
    //     hp = overall hydration pct
    //
    // Since the overall form of the recipe allows an optional preferment dough, there is possibly a second
    // "dough" subrecipe.  So this needs to account for one or two sub-recipes each with their own
    // flour, yeast, and levain specifications.  If a preferment is designated, the overall recipe flour
    // quantity is split based on the provided percentage.
    // The preferment has its own hydration percentage specified separately from the overall recipe hydration percentage.
    // When a preferment is designated, the main dough liquid will be the remainder from the preferment liquid to satisfy
    // the overall recipe hydration.  Note that when levain is specified as PFF, the levain hydration factors into the
    // hydration percentage of both the preferment and the overall hydration.
    //
    // Levain weight is calculated differently depending on the user's choice of %PFF or % total weight.
    // For %PFF, lw = lf + lf*lhp  (lhp = levain hydration pct)
    // For %TW, lw = rf*lp and the levain flour and levain water weight parameters are forced to 0
    //
    // When levain is calculated as %PFF, the levain flour portion is a percent of all recipe flour including levain+dough flour
    // The percentage as PFF is used to account for the levain water and levain flour components separately
    // to provide a more accurate overall recipe hydration percentage.

    let targetTotalWeight = items.numBatches * items.batchSize
    // sum(Mi):
    let mixinRatioSum = 0
    items.mainDough.mixinInput.forEach((md) => {
      mixinRatioSum += (md.percent / 100)
    })
    // soaker items are just another category of mixins
    items.mainDough.soakerInput.forEach((sd) => {
      mixinRatioSum += (sd.percent / 100)
    })
    consoleLog(`targetTotalWeight: ${targetTotalWeight}, mixinRatioSum: ${mixinRatioSum}, hydrationPct: ${items.mainDough.hydrationPctFraction}`)

    // determine separate mainDough and prefermentDough factors for levain (only if levain spec'd as total weight)
    //   and yeast weights...
    let mainFlourPctOfRecipe = 100
    let prefermentFlourPctOfRecipe = 0
    if (items.includePreferment) {
      // preferment percentage of overall recipe flour
      prefermentFlourPctOfRecipe = items.mainDough.prefermentPffPct
      mainFlourPctOfRecipe = 100 - prefermentFlourPctOfRecipe
    }
    mainFlourPctOfRecipe = mainFlourPctOfRecipe / 100
    prefermentFlourPctOfRecipe = prefermentFlourPctOfRecipe / 100
    consoleLog(`mainFlourPctOfRecipe: ${mainFlourPctOfRecipe}, prefermentFlourPctOfRecipe: ${prefermentFlourPctOfRecipe}`)

    // yeast percentages are proportionate to the respective main and preferment specifications
    const mainYeastPct = items.mainDough.yeastInputPctFraction || (getYeastPercentFromId(items.mainDough.yeastInputRatioId) / yeastDefs.flourUnitGrams)
    const prefermentYeastPct = items.prefermentDough.yeastInputPctFraction || (getYeastPercentFromId(items.prefermentDough.yeastInputRatioId) / yeastDefs.flourUnitGrams)
    const totalYeastPct = mainYeastPct + prefermentYeastPct
    consoleLog(`mainYeastPct: ${mainYeastPct}, prefermentYeastPct: ${prefermentYeastPct}, totalYeastPct: ${totalYeastPct}`)
    // levain total weight percentages are proportionate to the respective main and preferment specifications
    let mainLevainAsWeightPctFraction = 0
    let prefermentLevainAsWeightPctFraction = 0
    if (items.mainDough.starterType === LEVAIN_PCT_TYPE_TOTAL_WT) {
      mainLevainAsWeightPctFraction = (items.mainDough.starterPctFraction * mainFlourPctOfRecipe)
    }
    if (items.prefermentDough.starterType === LEVAIN_PCT_TYPE_TOTAL_WT) {
      prefermentLevainAsWeightPctFraction = (items.prefermentDough.starterPctFraction * prefermentFlourPctOfRecipe)
    }
    const totalLevainAsWeightPctFraction = mainLevainAsWeightPctFraction + prefermentLevainAsWeightPctFraction
    consoleLog(`mainLevainAsWeightPct: ${mainLevainAsWeightPctFraction}, prefermentLevainAsWeightPct: ${prefermentLevainAsWeightPctFraction}, totalLevainAsWeightPct: ${totalLevainAsWeightPctFraction}`)
    // and now...
    recipeFlourWeight = Math.round(targetTotalWeight / (1 + mixinRatioSum + totalYeastPct + totalLevainAsWeightPctFraction + items.mainDough.hydrationPctFraction))
  }

  let recipeLiquidWeight = Math.round(recipeFlourWeight * items.mainDough.hydrationPctFraction)
  consoleLog(`recipeFlourWeight: ${recipeFlourWeight}, recipeLiquidWeight: ${recipeLiquidWeight}`)
  return {flourWeight: recipeFlourWeight, liquidWeight: recipeLiquidWeight}
}

// derive both the main and preferment (if designated) component weights
function calculateAllComponentWeights(recipeFlourWeight, items) {
  let mainDoughPrimaryWeights = undefined
  let prefermentDoughPrimaryWeights = undefined
  if (!items.includePreferment) {
    prefermentDoughPrimaryWeights = calculateComponentWeights(0, items.prefermentDough)
    mainDoughPrimaryWeights = calculateComponentWeights(recipeFlourWeight, items.mainDough)
  } else {
    let prefermentFlourWeight = Math.round(recipeFlourWeight * items.mainDough.prefermentPffPctFraction)
    prefermentDoughPrimaryWeights = calculateComponentWeights(prefermentFlourWeight, items.prefermentDough)
    mainDoughPrimaryWeights = calculateComponentWeights(recipeFlourWeight - prefermentFlourWeight, items.mainDough)
  }
  return {
    mainDough: mainDoughPrimaryWeights,
    prefermentDough: prefermentDoughPrimaryWeights
  }
}

function calculateComponentWeights(totalFlourWeight, doughParams) {
  let levainFlourWeight = 0
  let doughFlourWeight = 0
  let levainLiquidWeight = 0
  let levainWeight = 0
  let doughLiquidWeight =0
  
  if (doughParams.starterType === LEVAIN_PCT_TYPE_PFF) {
    levainFlourWeight = totalFlourWeight * doughParams.starterPctFraction
    doughFlourWeight = totalFlourWeight - levainFlourWeight
    levainLiquidWeight = Math.round(levainFlourWeight * doughParams.starterHydrationPctFraction)
    levainWeight = Math.round(levainFlourWeight + levainLiquidWeight)
    doughLiquidWeight = Math.round((totalFlourWeight * doughParams.hydrationPctFraction) - levainLiquidWeight)
  } else if (doughParams.starterType === LEVAIN_PCT_TYPE_TOTAL_WT) {
    doughFlourWeight = totalFlourWeight
    levainWeight = Math.round(totalFlourWeight * doughParams.starterPctFraction)
    doughLiquidWeight = Math.round(totalFlourWeight * doughParams.hydrationPctFraction)
  } else {
    doughFlourWeight = totalFlourWeight
    doughLiquidWeight = Math.round(totalFlourWeight * doughParams.hydrationPctFraction)
  }

  return {
    levainFlourWeight: levainFlourWeight,
    doughFlourWeight: doughFlourWeight,
    levainLiquidWeight: levainLiquidWeight,
    levainWeight: levainWeight,
    doughLiquidWeight: doughLiquidWeight
  }
}

function calculateYeastForRatio(flourWeight, yeastRatioId, yeastPct) {
  consoleLog(`calcYeast, flourWeight: ${flourWeight}, yeastRatioId: ${yeastRatioId}, yeastPct: ${yeastPct}`)
  const yeastPercent = yeastPct || getYeastPercentFromId(yeastRatioId)
  const altUnitAmt = toFixedDecimal(getYeastTotalTspFromId(yeastRatioId, flourWeight), 1)
  return {
    ratioId: yeastRatioId,
    weight: toFixedDecimal(yeastPercent * (flourWeight / yeastDefs.flourUnitGrams), 1),
    percent: toFixedDecimal(yeastPercent),
    altUnit: unitTeaspoon,
    altUnitAmount: altUnitAmt,
    volumeDisplayLabel: `${altUnitAmt} Tsp`
  }
}

function consoleLog(message) {
  // debug
  // console.log(message)
}

function levainTwToPff(doughFLourWeight, starterPct) {
  const starterPctF = starterPct / 100
  const levainFlourWeight = (doughFLourWeight * starterPctF) / 2
  return Math.round(100 * (levainFlourWeight / (doughFLourWeight + levainFlourWeight )))
}

function levainPffToTw(flourWeight, levainWeight) {
  return Math.round(100 * (levainWeight / flourWeight))
}

function levainPffToTwEstimate(pffPct, levainHydrationPct, levainSeedPct) {
  const pffPctF = pffPct / 100
  const levainHydrationPctF = levainHydrationPct / 100
  const levainSeedPctF = levainSeedPct / 100
  const twPct = Math.round(((pffPctF + (pffPctF * levainHydrationPctF) + (pffPctF * levainSeedPctF)) / (1 - pffPctF)) * 100)
  // console.log(`levainPffToTwEstimate tw=${twPct}, pffPct = ${pffPct}, levainHydrationPct=${levainHydrationPct}, levainSeedPct=${levainSeedPct}`)
  return twPct
}

export {calculateDoughRecipe, calculateYeastForRatio, levainTwToPff, levainPffToTw, levainPffToTwEstimate}