import * as React from 'react';
import {Box, Fab, Grid, Tooltip} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {deepCopy, findIndexForFieldValue} from "../utils/ArrayUtils";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import * as Units from "../shared/Units";
import {FormTextField} from "./FormTextField";
import {unitPercent} from "./Units";
import {DecimalFormField} from "./DecimalFormField";

/**
 * This component manages a set of related ingredients with units in percentages relative to the recipe flour.
 * Percentage amounts can be specified as whole numbers or decimal numbers with .01 scale
 * In contrast, the IngredientGroupForm component handles ingredients with ratio percentages relative to the members of the group.
 * @param props with ingredientData as an array of ingredient objects
 * @returns {JSX.Element}
 * @constructor
 */
function IngredientCollectionForm(props) {
  const {ingredientData, setIngredientData, idField, nameField, unitField, collectionName, disabled} = props

  function onIngredientNameChange(evt) {
    const ingredientId = evt.target.dataset.ingredientid
    const ix = findIndexForFieldValue(ingredientData, idField, ingredientId)
    if (ix < 0) {
      console.log(`!! onStringFieldChange: cannot find Ingredient for id ${ingredientId}`)
      return
    }
    const updatedIngredientData = ingredientData.slice()
    updatedIngredientData[ix] = {...ingredientData[ix], [nameField]: evt.target.value}
    setIngredientData(updatedIngredientData)
  }

  function onIngredientUnitFieldChange(fieldName, floatValue, id) {
    const ix = findIndexForFieldValue(ingredientData, idField, id)
    if (ix < 0) {
      console.log(`!! onDecimalFieldChange: cannot find Ingredient for id ${id}`)
      return
    }

    const updatedIngredientData = ingredientData.slice()
    updatedIngredientData[ix] = {...ingredientData[ix], [unitField]: floatValue}
    setIngredientData(updatedIngredientData)
  }

  function addCustomIngredient() {
    const newIx = ingredientData.length + 1
    const uniqueId = `c.${Math.floor(Math.random() * 1000) + 1}`
    const newIngredientArr = ingredientData.slice()
    newIngredientArr.push({[nameField]: `${collectionName} ${newIx}`, [unitField]: 0, [idField]: uniqueId})
    setIngredientData(newIngredientArr)
  }

  function deleteIngredient(ingredientId) {
    let workingIngredientData = deepCopy(ingredientData)
    workingIngredientData = workingIngredientData.filter((md) => md[idField] !== ingredientId)
    setIngredientData(workingIngredientData)
  }

  return (
          <div>
            <Grid item container direction={'column'} rowSpacing={1}>
              {ingredientData.length === 0 ? (
                      <Grid item xs={0.65} sm={1.25} md={1.25}>
                        <Box sx={{paddingTop: '0.6rem', paddingLeft: '0.5rem'}}>
                          <Tooltip title={`Add a ${collectionName} ingredient`} placement={'right'} arrow>
                            <Fab disabled={disabled} size="small" color="secondary" aria-label="add">
                              <AddIcon onClick={addCustomIngredient}/>
                            </Fab>
                          </Tooltip>
                        </Box>
                      </Grid>
                ) : null
              }
              {ingredientData.map((md, idx) => {
                return(
                  <Grid key={md[idField]} item container columnSpacing={1.5} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'} columns={{xs: 4, sm: 8, md: 12}}>
                    <Grid item xs={1.75} sm={3.25} md={5}>
                        <FormTextField size={'small'}
                                id="outlined-required"
                                inputProps={{'data-ingredientid': md[idField]}}
                                name={nameField}
                                label={`${collectionName}`}
                                value={md[nameField]}
                                onChange={onIngredientNameChange}
                                disabled={disabled}
                                variant="filled"/>
                    </Grid>
                    <Grid item xs={0.9} sm={1.5} md={3}>
                      <DecimalFormField value={md[unitField]} size={'small'}
                                        name={unitField} label={`${Units.getUnitLabel(unitPercent)} recipe`}
                                        onChange={onIngredientUnitFieldChange}
                                        disabled={disabled}
                                        id={md[idField]} />
                    </Grid>
                    <Grid item xs={0.5} sm={1} md={1}>
                      <IconButton sx={{paddingTop: '1.1rem'}} disabled={disabled} onClick={() => deleteIngredient(md[idField])} placement="right" edge="end" >
                        <DeleteIcon/>
                      </IconButton>
                    </Grid>
                    {idx === (ingredientData.length - 1) ?
                      <Grid item xs={0.65} sm={1.25} md={1.25}>
                        <Box sx={{paddingTop: '0.6rem', paddingLeft: '0.5rem'}}>
                          <Tooltip title={`Add a ${collectionName} ingredient`} placement={'right'} arrow>
                            <Fab disabled={disabled} size="small" color="secondary" aria-label="add">
                              <AddIcon onClick={addCustomIngredient}/>
                            </Fab>
                          </Tooltip>
                        </Box>
                      </Grid> : null}
                  </Grid>
                )
              })
              }
            </Grid>
          </div>
  )
}

export { IngredientCollectionForm }