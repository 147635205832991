import {Chip, Grid, MenuItem, Typography} from "@mui/material";
import {FormTextField} from "./FormTextField";
import * as React from "react";

function FermentationTimingControl(props) {
  const {headingLabel, fermPctValue, fermPctName, fermPctLabel, fermPctValueArr, fermTempValue, fermTempName, fermTempLabel,
    fermTempValueArr, onChange, timeDisplayValue, timeDisplayWarning, onHelpClick} = props

  function renderHeading() {
    return `${headingLabel} ${fermPctValue}% rise time @ ${fermTempValue}°F:`
  }

  function renderWarning() {
    const warningMessage = timeDisplayWarning()
    if (warningMessage !== undefined) {
      return (
        <Typography sx={{fontSize: 10, marginTop: -0.5, fontStyle: 'italic'}} gutterBottom>
          {warningMessage}
        </Typography>
      )
    }
    return null
  }

  return (
    <div>
      <Grid item>
        <Typography sx={{marginTop: 1}} variant={'subtitle2'} gutterBottom>{renderHeading()}
          <Chip sx={{marginLeft: 1}} label={timeDisplayValue()} size={'small'} color={'secondary'} onClick={onHelpClick} />
        </Typography>
        {renderWarning()}
      </Grid>
      <Grid item container direction={'row'} marginTop={2} >
        <FormTextField select size={'small'} sx={{width: '40%'}}
                       id={`${fermPctName}`}
                       label={`${fermPctLabel}`}
                       name={`${fermPctName}`}
                       value={fermPctValue}
                       onChange={onChange}>
          {
            fermPctValueArr.map((v) => {
              return <MenuItem key={v} value={v}>{`${v}% rise`}</MenuItem>
            })
          }
        </FormTextField>
        <FormTextField select size={'small'} sx={{width: '40%', marginLeft: 1.5}}
                       id={`${fermTempName}`}
                       label={`${fermTempLabel}`}
                       name={`${fermTempName}`}
                       value={fermTempValue}
                       onChange={onChange}>
          {
            fermTempValueArr.map((v) => {
              return <MenuItem key={v.key} value={v.key}>{v.label}</MenuItem>
            })
          }
        </FormTextField>
      </Grid>
    </div>
  )
}

export {FermentationTimingControl}
