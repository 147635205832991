import {
  ingredientDryYeast,
  ingredientFlour,
  ingredientFreshYeast,
  ingredientLevain,
  ingredientUnknown,
  ingredientWater
} from "./Types";
import {toFixedDecimal} from "../utils/NumberUtils";

export const unitKilos = 'kilogram'
export const unitGrams = 'gram'
export const unitLiters = 'liter'
export const unitMillis = 'milliliter'
export const unitPounds = 'pound'
export const unitOunces = 'ounce'
export const unitPercent = 'percent'
export const unitCups = 'cup'
export const unitTablespoon = 'tablespoon'
export const unitTeaspoon = 'teaspoon'

export const FLOUR_EXCLUSION_UNITS = [unitPercent, unitLiters, unitMillis]
export const LIQUID_EXCLUSION_UNITS = [unitPercent]
export const GENERAL_DRY_EXCLUSION_UNITS = [unitPercent, unitLiters, unitMillis, unitCups, unitTeaspoon, unitTablespoon]

export const unitMap = [
  {
    unitType: unitCups,
    unitLabel: 'c'
  },
  {
    unitType: unitGrams,
    unitLabel: 'g'
  },
  {
    unitType: unitKilos,
    unitLabel: 'kg'
  },
  {
    unitType: unitLiters,
    unitLabel: 'l'
  },
  {
    unitType: unitMillis,
    unitLabel: 'ml'
  },
  {
    unitType: unitOunces,
    unitLabel: 'oz'
  },
  {
    unitType: unitPercent,
    unitLabel: '%'
  },
  {
    unitType: unitPounds,
    unitLabel: 'lb'
  },
  {
    unitType: unitTablespoon,
    unitLabel: 'tbsp'
  },
  {
    unitType: unitTeaspoon,
    unitLabel: 'tsp'
  }
]


export function getUnitLabel(unitName) {
  let unit = unitMap.find((u) => u.unitType === unitName)
  if (unit === undefined) {
    return '?'
  }
  return unit.unitLabel
}

export function convert(ingredientName, amount, fromUnitType, toUnitType) {
  if (fromUnitType === toUnitType) {
    return amount
  }
  switch(ingredientName) {
    case ingredientFlour:
      return flourGramsToUnit(toGrams(amount, fromUnitType, ingredientFlour), toUnitType)
    case ingredientWater:
      return waterGramsToUnit(toGrams(amount, fromUnitType, ingredientWater), toUnitType)
    case ingredientDryYeast:
      return dryYeastGramsToUnit(toGrams(amount, fromUnitType, ingredientDryYeast), toUnitType)
    case ingredientFreshYeast:
      return dryYeastGramsToUnit(toGrams(amount, fromUnitType, ingredientDryYeast) * 2, toUnitType)
    case ingredientLevain:
      return levainGramsToUnit(toGrams(amount, fromUnitType, ingredientLevain), toUnitType)
    case ingredientUnknown:
      return weightGramsToUnit(toGrams(amount, fromUnitType, ingredientUnknown), toUnitType)
    default: {
      console.log(`convert: unknown ingredient, ${ingredientName}`)
      return -1
    }
  }
}

export function toGrams(amount, unitType, ingredientName) {
  if (unitType === unitGrams) {
    return amount
  }
  switch(ingredientName) {
    case ingredientFlour:
      return Math.round(flourToGrams(amount, unitType))
    case ingredientWater:
      return Math.round(waterToGrams(amount, unitType))
    case ingredientDryYeast:
      return toFixedDecimal(dryYeastToGrams(amount, unitType), 1)
    case ingredientFreshYeast:
      return Math.round(dryYeastToGrams(amount, unitType) * 2)
    case ingredientLevain:
      return Math.round(levainToGrams(amount, unitType))
    case ingredientUnknown:
      return Math.round(weightToGrams(amount, unitType))
    default: {
      console.log(`toGrams: unknown ingredient, ${ingredientName}`)
      return -1
    }
  }
}

export function flourToGrams(amount, unitType) {
  // valid units: grams, cups, oz, kilos, lbs, tsp, tbsp
  switch(unitType) {
    case unitGrams:
      return amount
    case unitCups:
      return amount * 120
    case unitOunces:
      return amount * 28.35
    case unitKilos:
      return amount / 1000
    case unitPounds:
      return amount * 453.6
    case unitTeaspoon:
      return amount * 2.5
    case unitTablespoon:
      return amount * 7.5
    default:
      return NaN
  }
}

export function flourGramsToUnit(amount, unitType) {
  // valid units: grams, cups, oz, kilos, lbs, tsp, tbsp
  switch(unitType) {
    case unitGrams:
      return Math.round(amount)
    case unitCups:
      return toFixedDecimal(amount / 120, 2)
    case unitOunces:
      return toFixedDecimal(amount / 28.35, 1)
    case unitKilos:
      return toFixedDecimal(amount * 1000, 3)
    case unitPounds:
      return toFixedDecimal(amount / 453.6, 2)
    case unitTeaspoon:
      return toFixedDecimal(amount / 2.5, 2)
    case unitTablespoon:
      return toFixedDecimal(amount / 7.5, 2)
    default:
      return NaN
  }
}

export function waterToGrams(amount, unitType) {
  // valid units: grams, kilos, litres, millis, oz, lbs, cups, tsp, tbsp
  switch(unitType) {
    case unitGrams:
      return amount
    case unitCups:
      return amount * 227
    case unitOunces:
      return amount * 28.35
    case unitKilos:
      return amount * 1000
    case unitLiters:
      return amount * 1000
    case unitMillis:
      return amount
    case unitPounds:
      return amount * 453.6
    case unitTeaspoon:
      return amount * 4.73
    case unitTablespoon:
      return amount * 14.19
    default:
      return NaN
  }
}

export function waterGramsToUnit(amount, unitType) {
  // valid units: grams, kilos, litres, millis, oz, lbs, cups, tsp, tbsp
  switch(unitType) {
    case unitGrams:
      return Math.round(amount)
    case unitCups:
      return toFixedDecimal(amount / 227, 2)
    case unitOunces:
      return toFixedDecimal(amount / 28.35, 1)
    case unitKilos:
      return toFixedDecimal(amount / 1000, 3)
    case unitLiters:
      return toFixedDecimal(amount / 1000, 3)
    case unitMillis:
      return Math.round(amount)
    case unitPounds:
      return toFixedDecimal(amount / 453.6, 2)
    case unitTeaspoon:
      return toFixedDecimal(amount / 4.73, 2)
    case unitTablespoon:
      return toFixedDecimal(amount / 14.19, 2)
    default:
      return NaN
  }
}

export function dryYeastToGrams(amount, unitType) {
  // valid units: grams, oz, kilos, lbs, tsp, tbsp
  switch(unitType) {
    case unitGrams:
      return amount
    case unitOunces:
      return amount * 28.33
    case unitKilos:
      return amount * 1000
    case unitPounds:
      return amount * 453.28
    case unitTeaspoon:
      return amount * 3
    case unitTablespoon:
      return amount * 9
    default:
      return NaN
  }
}

export function dryYeastGramsToUnit(amount, unitType) {
  // valid units: grams, oz, kilos, lbs, tsp, tbsp
  switch(unitType) {
    case unitGrams:
      return toFixedDecimal(amount, 1)
    case unitOunces:
      return toFixedDecimal(amount / 28.33, 1)
    case unitKilos:
      return toFixedDecimal(amount / 1000, 3)
    case unitPounds:
      return toFixedDecimal(amount / 453.28, 2)
    case unitTeaspoon:
      return toFixedDecimal(amount / 3, 2)
    case unitTablespoon:
      return toFixedDecimal(amount / 9, 2)
    default:
      return NaN
  }
}

export function levainToGrams(amount, unitType) {
  // valid units: grams, oz, kilos, lbs, tsp, tbsp
  switch(unitType) {
    case unitGrams:
      return amount
    case unitOunces:
      return flourToGrams(amount / 2, unitOunces) + waterToGrams(amount / 2, unitOunces)
    case unitKilos:
      return flourToGrams(amount / 2, unitKilos) + waterToGrams(amount / 2, unitKilos)
    case unitPounds:
      return flourToGrams(amount / 2, unitPounds) + waterToGrams(amount / 2, unitPounds)
    case unitTeaspoon:
      return flourToGrams(amount / 2, unitTeaspoon) + waterToGrams(amount / 2, unitTeaspoon)
    case unitTablespoon:
      return flourToGrams(amount / 2, unitTablespoon) + waterToGrams(amount / 2, unitTablespoon)
    default:
      return NaN
  }
}

export function levainGramsToUnit(amount, unitType) {
  // valid units: grams, oz, kilos, lbs, tsp, tbsp
  switch(unitType) {
    case unitGrams:
      return Math.round(amount)
    case unitOunces:
      return toFixedDecimal(flourGramsToUnit(amount / 2, unitOunces) + waterGramsToUnit(amount / 2, unitOunces), 1)
    case unitKilos:
      return toFixedDecimal(flourGramsToUnit(amount / 2, unitKilos) + waterGramsToUnit(amount / 2, unitKilos), 3)
    case unitPounds:
      return toFixedDecimal(flourGramsToUnit(amount / 2, unitPounds) + waterGramsToUnit(amount / 2, unitPounds), 2)
    case unitTeaspoon:
      return toFixedDecimal(flourGramsToUnit(amount / 2, unitTeaspoon) + waterGramsToUnit(amount / 2, unitTeaspoon), 2)
    case unitTablespoon:
      return toFixedDecimal(flourGramsToUnit(amount / 2, unitTablespoon) + waterGramsToUnit(amount / 2, unitTablespoon), 2)
    default:
      return NaN
  }
}

export function weightToGrams(amount, unitType) {
  // valid units: grams, oz, kilos, lbs
  switch(unitType) {
    case unitGrams:
      return amount
    case unitOunces:
      return amount * 28.35
    case unitKilos:
      return amount * 1000
    case unitPounds:
      return amount * 453.6
    default:
      return NaN
  }
}

export function weightGramsToUnit(amount, unitType) {
  // valid units: grams, oz, kilos, lbs
  switch(unitType) {
    case unitGrams:
      return Math.round(amount)
    case unitOunces:
      return toFixedDecimal(amount / 28.35, 1)
    case unitKilos:
      return toFixedDecimal(amount / 1000, 3)
    case unitPounds:
      return toFixedDecimal(amount / 453.6, 2)
    default:
      return NaN
  }
}

export function toCelsius(degreesF) {
  return toFixedDecimal(((degreesF - 32) * 5) / 9, 1)
}

export function getAutolyseLiquidPct(hydrationPct) {
  let autolysePct = 95
  if (hydrationPct > 74) {
    autolysePct = (85 - (hydrationPct - 75))
  } else if (hydrationPct > 64) {
    autolysePct = (95 - (hydrationPct - 65))
  }
  return toFixedDecimal(autolysePct / 100, 2)
}