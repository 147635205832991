import {BATCH_TYPE_TOTAL_WT, formTypeLevain} from "../shared/Types";
import {defaultLevainFermPct} from "../shared/FermentationTiming";

const levainFormDefaults = {
  formName: `New Levain`,
  formType: formTypeLevain,
  batchSize: 100,
  batchType: BATCH_TYPE_TOTAL_WT,
  levainStarterPct: 10,
  levainHydrationPct: 100,
  levainMotherStarterPct: 10,
  levainMotherHydrationPct: 100,
  levainFermPct: defaultLevainFermPct,
  levainFermTempF: '75',
}

const levainFlourDefaults = [
  {
    name: 'All Purpose',
    percent: 70,
    percentStr: '70',
    id: 'b.1'
  },
  {
    name: 'Rye',
    percent: 30,
    percentStr: '30',
    id: 'b.2'
  }
]

export {levainFormDefaults, levainFlourDefaults}