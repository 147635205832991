import {useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import Button from "@mui/material/Button";

function PromptFormDialog(props) {
  const {message, fieldName, formValue, setFormValue, onSave, dialogOpen, setDialogOpen} = props
  const [isError, setIsError] = useState(false)
  const [isSaveDisabled, setIsSaveDisabled] = useState(false)

  const handleCancel = () => {
    setDialogOpen(false)
    setIsError(false)
    setIsSaveDisabled(false)
  };

  const handleSave = () => {
    setDialogOpen(false)
    onSave()
  };

  function onFieldChange(evt) {
    const newValue = evt.target.value
    const isValid = newValue?.trim()
    setIsError(!isValid)
    setIsSaveDisabled(!isValid)
    setFormValue(newValue)
  }

  return (
    <div>
      <Dialog open={dialogOpen} onClose={handleCancel}>
        <DialogTitle>{fieldName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
          <TextField
                  autoFocus
                  margin="dense"
                  id={`prompt-form-id`}
                  label={fieldName}
                  value={formValue}
                  onChange={onFieldChange}
                  fullWidth
                  variant="standard"
                  error={isError}
                  helperText={`${isError ? 'Name required' : ''}`}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button disabled={isSaveDisabled} onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export {PromptFormDialog}