import {unitTeaspoon} from "./Units";

// Note: as long as flourUnitGrams = 100, unitMultiplier * yeastRatioUnitGrams IS, by definition, the yeast percent!
export const yeastDefs = {
  volumeUnit: unitTeaspoon,
  flourUnitGrams: 100,
  yeastRatioUnitGrams: 0.1875, // 1/16 tsp in grams
  yeastRatios: [
    {
      ratioId: 0,
      unitMultiplier: 0,
      label: 'None',
      timingIndex: -1
    },
    {
      ratioId: 1,
      unitMultiplier: 0.5,
      label: '1/32 tsp',
      timingIndex: 0
    },
    {
      ratioId: 2,
      unitMultiplier: 1,
      label: '1/16 tsp',
      timingIndex: 1
    },
    {
      ratioId: 3,
      unitMultiplier: 2 ,
      label: '1/8 tsp',
      timingIndex: 2
    },
    {
      ratioId: 4,
      unitMultiplier: 4,
      label: '1/4 tsp',
      timingIndex: 3
    },
    {
      ratioId: 5,
      unitMultiplier: 8,
      label: '1/2 tsp',
      timingIndex: 4
    },
    {
      ratioId: 6,
      unitMultiplier: 12,
      label: '3/4 tsp',
      timingIndex: 5
    },
    {
      ratioId: 7,
      unitMultiplier: 16,
      label: '1 tsp',
      timingIndex: 6
    },
    {
      ratioId: 8,
      unitMultiplier: 17,
      label: '1 1/16 tsp',
      timingIndex: 7
    },
    {
      ratioId: 9,
      unitMultiplier: 18,
      label: '1 1/8 tsp',
      timingIndex: 8
    },
    {
      ratioId: 10,
      unitMultiplier: 20,
      label: '1 1/4 tsp',
      timingIndex: 9
    },
    {
      ratioId: 11,
      unitMultiplier: 24,
      label: '1 1/2 tsp',
      timingIndex: 10
    },
    {
      ratioId: 12,
      unitMultiplier: 32,
      label: '2 tsp',
      timingIndex: 11
    },
    {
      ratioId: 13,
      unitMultiplier: 48,
      label: '3 tsp',
      timingIndex: 12
    }
  ]
}

export function getMaxYeastRatioId() {
  return yeastDefs.yeastRatios[yeastDefs.yeastRatios.length - 1].ratioId
}

export function getMaxYeastUnitMultiplier() {
  return yeastDefs.yeastRatios[yeastDefs.yeastRatios.length - 1].unitMultiplier
}

export function getYeastPercentFromId(ratioId) {
  const yeastRatio = yeastDefs.yeastRatios.find((yd) => yd['ratioId'] === ratioId) || yeastDefs.yeastRatios[0]
  return yeastRatio['unitMultiplier'] * yeastDefs.yeastRatioUnitGrams
}

export function getYeastTotalTspFromId(ratioId, totalFlourWeight) {
  const yeastRatio = yeastDefs.yeastRatios.find((yd) => yd['ratioId'] === ratioId) || yeastDefs.yeastRatios[0]
  const sixteenths = yeastRatio['unitMultiplier']
  const tspFraction = sixteenths / 16
  return tspFraction * (totalFlourWeight / yeastDefs.flourUnitGrams)
}

export function getYeastSelectorDescription() {
  return `Dry Yeast/${yeastDefs.flourUnitGrams}g flour`
}

export function getYeastRatioSelectLabel(ratioId) {
  const yeastRatio = yeastDefs.yeastRatios.find((yd) => yd['ratioId'] === ratioId) || yeastDefs.yeastRatios[0]

  return `${yeastRatio['label']} per ${yeastDefs.flourUnitGrams}g`
}

export function deriveRatioIdFromMultiplier(multiplier) {
  if (multiplier === undefined || multiplier <= 0) {
    return 0
  }
  const roundedMultiplier = Math.round(multiplier)
  const maxYeastUnitMultipler = getMaxYeastUnitMultiplier()
  const maxYeastRatioId = getMaxYeastRatioId()
  if (roundedMultiplier > maxYeastUnitMultipler) {
    return maxYeastRatioId
  }

  const yeastRatio = yeastDefs.yeastRatios.find((yd) => yd['unitMultiplier'] === roundedMultiplier)
  if (yeastRatio !== undefined) {
    return yeastRatio['ratioId']
  }
  // no exact match, find nearest neighbor
  for (let ix = 0; ix < yeastDefs.yeastRatios.length; ix++) {
    if (yeastDefs.yeastRatios[ix].unitMultiplier > roundedMultiplier) {
      const lowerBound =  yeastDefs.yeastRatios[ix-1].unitMultiplier
      const upperBound =  yeastDefs.yeastRatios[ix].unitMultiplier
      if ((roundedMultiplier - lowerBound) < (upperBound - roundedMultiplier) && lowerBound > 0) {
        const result = yeastDefs.yeastRatios[ix-1].ratioId
        console.log(`deriveRatioIdFromMultiplier: taking lower neighbor to multipler ${roundedMultiplier}, ratioid: ${result}`)
        return result
      }
      const result = yeastDefs.yeastRatios[ix].ratioId
      console.log(`deriveRatioIdFromMultiplier: taking upper neighbor to multiplier ${roundedMultiplier}, ratioid: ${result}`)
      return result
    }
  }
  return yeastRatio['ratioId']
}

export function getYeastTimingIndex(ratioId) {
  const yeastRatio = yeastDefs.yeastRatios.find((yd) => yd['ratioId'] === ratioId) || yeastDefs.yeastRatios[0]
  return yeastRatio.timingIndex
}
