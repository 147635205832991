import {styled} from "@mui/system";
import {TableCell} from "@mui/material";

const RecipeSummaryTableCell = styled(TableCell)({
  '&.MuiTableCell-root': {
    fontSize: 12,
    fontStyle: 'italic',
    paddingLeft: 5,
    borderBottom: '0',
    paddingTop: 0,
    paddingBottom: 0,
  }
});

const RecipeIngredientTableCell = styled(TableCell)({
  '&.MuiTableCell-root': {
    fontSize: 12,
    color: 'text.secondary',
    borderBottom: '0',
    paddingTop: 0,
    paddingBottom: 0,
  }
});

export {RecipeSummaryTableCell, RecipeIngredientTableCell}
