import * as React from 'react';
import {
  Box, Divider, FormControlLabel, FormGroup,
  Grid, Switch,
  Typography
} from "@mui/material";
import {
  getLevainTypeLabel, getPrefermentDefinition,
  isYeastPreferment,
  LEVAIN_PCT_TYPE_TOTAL_WT,
  noPreferment
} from "../shared/Types";
import {PrefermentTypeSelector} from "../shared/PrefermentTypeSelector";
import {
  getYeastPercentFromId,
  getYeastRatioSelectLabel,
  getMaxYeastRatioId
} from "../shared/Yeast";
import {toFixedDecimal} from "../utils/NumberUtils";
import {useState} from "react";
import IconButton from "@mui/material/IconButton";
import {
  helpFermVolTemp,
  helpPrefermentType
} from "../help/HelpContent";
import {HelpOutline} from "@mui/icons-material";
import {ContextualHelpDialog} from "../help/ContextualHelpDialog";
import {FormulaSlider} from "../shared/FormulaSlider";
import {
  fermTemps,
  getLevainTime,
  getYeastTime,
  levainVolumes
} from "../shared/FermentationTiming";
import {FermentationTimingControl} from "../shared/FermentationTimingControl";
import {IngredientGroupForm} from "../shared/IngredientGroupForm";

function PrefermentForm(props) {
  const {formData, setFormData} = props
  const [openContextualHelpDialog, setOpenContextualHelpDialog] = useState(false)
  const [contextualHelpContent, setContextualHelpContent] = useState({title: undefined, content: undefined})

  function openHelp(title, content) {
    setContextualHelpContent({title: title, content: content})
    setOpenContextualHelpDialog(true)
  }

  function onCloseHelp() {
    setOpenContextualHelpDialog(false)
    setContextualHelpContent({title: undefined, content: undefined})
  }

  function updateFlourData(updatedFlourData) {
    setFormData({
      ...formData,
      flour: updatedFlourData
    })
  }

  function updateLiquidData(updatedLiquidData) {
    setFormData({
      ...formData,
      liquid: updatedLiquidData
    })
  }

  function onNumericFieldChange(evt) {
    let intVal = parseInt(evt.target.value)
    if (isNaN(intVal)) {
      intVal = 0
    }
    setFormData({
      ...formData,
      [evt.target.name]: intVal
    })
  }

  function onYeastRatioChange(evt) {
    let ratioId = parseInt(evt.target.value)
    if (isNaN(ratioId)) {
      ratioId = 0
    }
    setFormData({
      ...formData,
      yeastRatioId: ratioId,
      yeastPct: getYeastPercentFromId(ratioId)
    })
  }

  function onTimingParamsChange(evt) {
    setFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    })
  }

  function onAddBufferChange(evt) {
    setFormData({
      ...formData,
      addBuffer: evt.target.checked
    })
  }

  function onPrefermentTypeChange(value) {
    const prefermentDef = getPrefermentDefinition(value)
    console.log(`onPrefermentTypeChange to ${value}, yeastRatioId=${prefermentDef.yeastRatioId}, starterPct=${prefermentDef.starterPct}`)
    setFormData({...formData,
                 prefermentType: value,
                 recipeFlourPct: prefermentDef.defaultRecipeFlourPct,
                 hydrationPct: prefermentDef.defaultHydrationPct,
                 yeastRatioId: prefermentDef.yeastRatioId,
                 yeastPct: 0,
                 starterPct: prefermentDef.starterPct,
                 starterType: LEVAIN_PCT_TYPE_TOTAL_WT,
                 starterHydrationPct: 100
    })
  }

  function renderTypeAndHydration() {
    return (
      <Grid item container direction={'column'}>

        <Grid item container direction={'row'} marginBottom={1.5}>
        <Grid item >
          <PrefermentTypeSelector id={'quantity-pf'} prefermentType={formData.prefermentType} onValueChange={onPrefermentTypeChange} excludeTypes={[noPreferment]}/>
          <IconButton color={'primary'} onClick={() => openHelp(undefined, helpPrefermentType)}><HelpOutline fontSize={'small'} /></IconButton>
        </Grid>
        <Grid item marginLeft={3}>
          <FormGroup row>
          <FormControlLabel control={<Switch name={'addBuffer'} checked={formData.addBuffer}
                                             onChange={onAddBufferChange}/>}
                            label={<Typography variant={'subtitle2'}>Add Weight Buffer</Typography>}/>
          </FormGroup>
        </Grid>
        </Grid>
        <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1} >
          <Grid item container direction={'row'}>
            <Typography variant={'subtitle2'} gutterBottom>Preferment Hydration:
              <Box component="span" fontWeight='fontWeightBold'> {formData.hydrationPct}% </Box>
            </Typography>
          </Grid>
          <FormulaSlider getAriaLabel={() => 'Dough Hydration'}
                  disabled={false}
                  sx={{marginLeft: 1, width: '90%'}}
                  name={'hydrationPct'}
                  value={formData.hydrationPct}
                  onChange={onNumericFieldChange}
                  valueLabelDisplay="auto"
                  color={"water"}
                  min={20}
                  max={195}
                  step={1}/>
        </Grid>
      </Grid>
    )
  }

  function getStarterSliderHeading() {
    if (formData.starterPct > 0) {
      return `${toFixedDecimal(formData.starterPct, 2)}% ${getLevainTypeLabel(formData.starterType)}`
    }
    return 'None'
  }

  function getYeastSliderHeading() {
    if (formData.yeastRatioId > 0) {
      return `${toFixedDecimal(getYeastPercentFromId(formData.yeastRatioId), 2)}%`
    }
    return 'None'
  }

  function renderPrefermentLeavening() {
    return (
      <div>
        <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={2} marginBottom={1}>
          <Typography variant={'subtitle2'} gutterBottom>Starter:
            <Box component="span" fontWeight='fontWeightBold'> {getStarterSliderHeading()}</Box>
          </Typography>
          <FormulaSlider getAriaLabel={() => 'Levain '}
                         sx={{marginLeft: 1, width: '90%'}}
                         disabled={isYeastPreferment(formData.prefermentType)}
                         name={'starterPct'}
                         value={formData.starterPct}
                         onChange={onNumericFieldChange}
                         color={formData.starterPct > 0 ? "primary" : "attention"}
                         valueLabelDisplay={'auto'}
                         min={0}
                         max={150}
                         step={1}/>
        </Grid>
        <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1} marginBottom={2.5}>
          <Typography variant={'subtitle2'} gutterBottom>Dry Yeast:
            <Box component="span" fontWeight='fontWeightBold'> {getYeastSliderHeading()}</Box>
          </Typography>
          <FormulaSlider getAriaLabel={() => 'Dry Yeast'}
                         sx={{marginLeft: 1, width: '90%'}}
                         disabled={!isYeastPreferment(formData.prefermentType)}
                         name={'yeastRatioId'}
                         value={formData.yeastRatioId}
                         onChange={onYeastRatioChange}
                         color={formData.yeastRatioId > 0 ? "primary" : "attention"}
                         valueLabelFormat={getYeastRatioSelectLabel}
                         valueLabelDisplay={'auto'}
                         min={0}
                         max={getMaxYeastRatioId()}
                         step={1}/>
        </Grid>
        <FermentationTimingControl headingLabel={'Preferment'} fermPctValueArr={levainVolumes} fermTempValueArr={fermTemps}
                                   timeDisplayValue={prefermentLevainTimeFormatted} timeDisplayWarning={() => undefined}
                                   fermPctValue={formData.levainFermPct} fermPctName={'levainFermPct'} fermPctLabel={'Pre Ferm Goal'}
                                   fermTempValue={formData.levainFermTempF} fermTempName={'levainFermTempF'} fermTempLabel={'Pre Ferm Temp'}
                                   onChange={onTimingParamsChange} onHelpClick={() => openHelp(undefined, helpFermVolTemp)}/>
      </div>
    )
  }


  function prefermentLevainTimeFormatted() {
    if (formData.starterPct > 0) {
      return `~ ${getLevainTime(formData.levainFermTempF, formData.levainFermPct, formData.starterPct, formData.hydrationPct)}`
    } else if (formData.yeastRatioId > 0) {
      return `~ ${getYeastTime(formData.levainFermTempF, formData.levainFermPct, formData.yeastRatioId, formData.hydrationPct)}`
    } else {
      return 'n/a'
    }
  }

  return (
          <Grid item container direction={'column'}>
            {renderTypeAndHydration()}
            <div>
              <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}>
                <Typography sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Flour</Typography>
              </Divider>
              <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1} marginBottom={1}>
                <IngredientGroupForm ingredientData={formData.flour} setIngredientData={updateFlourData} idField={'id'} nameField={'name'}
                           unitField={'percent'} groupName={'Flour'} minGroupSize={1} disabled={false}/>
              </Grid>
            </div>
            <div>
              <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}>
                <Typography sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Liquid</Typography>
              </Divider>
              <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1} marginBottom={1}>
                <IngredientGroupForm ingredientData={formData.liquid} setIngredientData={updateLiquidData} idField={'id'}
                            nameField={'name'} unitField={'percent'} minGroupSize={1} groupName={'Liquid'} disabled={false}/>
              </Grid>
            </div>

            <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}>
              <Typography sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Leavening</Typography>
            </Divider>
            {renderPrefermentLeavening()}

            <ContextualHelpDialog isOpen={openContextualHelpDialog} close={onCloseHelp}
                                  title={contextualHelpContent.title} content={contextualHelpContent.content}/>
          </Grid>
  )
}

export { PrefermentForm }