import {styled} from "@mui/system";
import {TextField} from "@mui/material";

const FormTextField = styled(TextField)({
  '& .MuiFilledInput-root': {
    border: '1px solid #ffffff',
    overflow: 'hidden',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      color: '#000000',
      backgroundColor: '#F3EBE0FF'
    },
  },
});

export {FormTextField}
