import {useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import Button from "@mui/material/Button";

function ConfirmDialog(props) {
  const {title, confirmMessage, confirm, confirmButtonName, cancel, cancelButtonName, dialogOpen} = props

  return (
    <div>
      <Dialog open={dialogOpen} onClose={cancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {confirmMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel}>{cancelButtonName}</Button>
          <Button onClick={confirm}>{confirmButtonName}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export {ConfirmDialog}