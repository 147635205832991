import {useState} from "react";
import {
  Accordion, AccordionDetails,
  AccordionSummary, Alert, FormControl, FormControlLabel, FormGroup,
  Grid,
  Paper, Snackbar, Switch,
  TextField, Tooltip,
  Typography
} from "@mui/material";
import {FormTextField} from "../shared/FormTextField";
import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import {useNavigate, useParams} from "react-router";
import {SavedObjectDialog} from "../shared/SavedObjectDialog";
import {QuantityDoughFormDetail} from "./QuantityDoughFormDetail";
import {mainFormQuantityDefaults, prefermentQuantityDefaults} from "./QuantityDoughFormDefaults";
import {formTypeRatioDough, getPrefermentLabel, LEVAIN_PCT_TYPE_TOTAL_WT} from "../shared/Types";
import {DoughRecipeWrapper} from "../dough/DoughRecipeWrapper";
import {convertToRatioForm} from "./QuantityToRatioConverter";
import {urlQuantityDough} from "../../App";
import {getLocalDate} from "../utils/DateUtils";
import {getNewStateKey} from "../utils/FormStateUtils";
import {PromptFormDialog} from "../shared/PromptFormDialog";
import {ConfirmDialog} from "../shared/ConfirmDialog";
import {calculateQuantityRecipe} from "./QuantityDoughRecipeCalculator";
import IconButton from "@mui/material/IconButton";
import {helpQuantityButtons} from "../help/HelpContent";
import {ArrowDropDown, ArrowDropUp, HelpOutline} from "@mui/icons-material";
import {ContextualHelpDialog} from "../help/ContextualHelpDialog";
import AttributionIcon from "@mui/icons-material/Attribution";
import {AttributionDialog} from "../shared/AttributionDialog";
import ReplayIcon from '@mui/icons-material/Replay';
import {maxUnitsToBake} from "../dough/DoughFormDefaults";
import {unitGrams} from "../shared/Units";

function QuantityDoughForm(props) {
  const {formKey} = useParams()
  const [savedFormData, setSavedFormData] = useState(initSavedFormData())
  const [formData, setFormData] = useState(initFormData())
  const [prefermentFormData, setPrefermentFormData] = useState(initPrefermentFormData())
  const [formNameError, setFormNameError] = useState(false)
  const [oldFormName, setOldFormName] = useState(undefined)
  const [recipeVisible, setRecipeVisible] = useState(false)
  const [recipeItems, setRecipeItems] = useState(undefined)
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true)
  const [saveAsRatioBtnDisabled, setSaveAsRatioBtnDisabled] = useState(true)
  const [openFormExistsDialog, setOpenFormExistsDialog] = useState(false)
  const [openAttributionDialog, setOpenAttributionDialog] = useState(false)
  const [openRatioFormNamePrompt, setOpenRatioFormNamePrompt] = useState(false)
  const [openRatioFormNameConfirm, setOpenRatioFormNameConfirm] = useState(false)
  const [saveAsRatioComplete, setSaveAsRatioComplete] = useState()
  const [ratioFormName, setRatioFormName] = useState(null)
  const [hasChanges, setHasChanges] = useState(false)
  const [ratioCandidateFormName, setRatioCandidateFormName] = useState(undefined)
  const [formStateKey, setFormStateKey] = useState(getNewStateKey())
  const navigate = useNavigate()
  const [openContextualHelpDialog, setOpenContextualHelpDialog] = useState(false)
  const [contextualHelpContent, setContextualHelpContent] = useState({title: undefined, content: undefined})

  function openHelp(title, content) {
    setContextualHelpContent({title: title, content: content})
    setOpenContextualHelpDialog(true)
  }

  function onCloseHelp() {
    setOpenContextualHelpDialog(false)
    setContextualHelpContent({title: undefined, content: undefined})
  }

  function initSavedFormData() {
    if (formKey === undefined) {
      return undefined
    }
    const decodedKey = window.atob(formKey)
    const storedForm = localStorage.getItem(decodedKey)
    return storedForm ? JSON.parse(storedForm) : mainFormQuantityDefaults
  }

  function initFormData() {
    if (savedFormData === undefined) {
      return mainFormQuantityDefaults
    }
    // exclude preferment from the savedFormData to split into separately managed states: formData and prefermentFormData
    // this is mostly being done to omit the preferment structure in the persisted data to save space
    // these are, of course, merged when calculating the recipe
    const {prefermentData, ...remainder} = savedFormData
    return applyNewMainFormDefaults(remainder)
  }

  function applyNewMainFormDefaults(storedForm) {
    Object.keys(mainFormQuantityDefaults).forEach(k => {
      if (storedForm[k] === undefined) {
        storedForm[k] = mainFormQuantityDefaults[k]
      }
      // in case of type rename
      storedForm.formType = mainFormQuantityDefaults.formType
    })
    return storedForm
  }

  function initPrefermentFormData() {
    if (savedFormData === undefined || savedFormData['prefermentData'] === undefined) {
      return prefermentQuantityDefaults
    }
    return applyNewPrefermentFormDefaults(savedFormData['prefermentData'])
  }

  function applyNewPrefermentFormDefaults(storedForm) {
    Object.keys(prefermentQuantityDefaults).forEach(k => {
      if (storedForm[k] === undefined) {
        storedForm[k] = prefermentQuantityDefaults[k]
      }
    })
    return storedForm
  }

  function updateFormData(formData) {
    setFormData(formData)
    setHasChanges(true)
  }

  function updatePrefermentData(prefermentData) {
    setPrefermentFormData(prefermentData)
    setHasChanges(true)
  }

  function onIncludePrefermentChange(evt) {
    const includePreferment = evt.target.checked

    updateFormData({
      ...formData,
      includePreferment: includePreferment,
      starterType: LEVAIN_PCT_TYPE_TOTAL_WT,
      starterAmount: (includePreferment ? 0 : mainFormQuantityDefaults.starterAmount),
      starterUnit: unitGrams,
      yeastAmount: (includePreferment ? 0 : mainFormQuantityDefaults.yeastAmount),
      yeastUnit: unitGrams
    })
  }

  function onStringFieldChange(evt) {
    updateFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    })
  }

  function onFormNameFieldChange(evt) {
    if (evt.target.validity.valid) {
      setFormNameError(false)
    } else {
      setFormNameError(true)
    }
    updateFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    })
  }

  function onNumericFieldChange(evt) {
    let intVal = parseInt(evt.target.value)
    if (isNaN(intVal)) {
      intVal = 0
    }
    if (evt.target.name === 'numBatches' && intVal >= maxUnitsToBake) {
      intVal = maxUnitsToBake
    }
    if (evt.target.name === 'numBatches' && intVal < 1) {
      intVal = 1
    }
    updateFormData({
      ...formData,
      [evt.target.name]: intVal
    })
  }

  function recipeCloseRequest() {
    setRecipeVisible(false)
  }

  function clickAttribution() {
    setOpenAttributionDialog(true)
  }

  function applyAttribution(attributionText) {
    updateFormData({...formData, attribution: attributionText})
  }

  function incrementBatches() {
    const currVal = formData.numBatches
    if (currVal >= maxUnitsToBake) {
      return
    }
    updateFormData({...formData, numBatches: currVal + 1})
  }

  function decrementBatches() {
    const currVal = formData.numBatches
    if (currVal === 1) {
      return
    }
    updateFormData({...formData, numBatches: currVal - 1})
  }

  function applyForm() {
    const mergedFormData = {...formData, prefermentData: (formData.includePreferment ? prefermentFormData : undefined)}
    setRecipeItems(calculateQuantityRecipe(mergedFormData))
    setRecipeVisible(true)
    setSaveBtnDisabled(false)
    setSaveAsRatioBtnDisabled(false)
  }

  function loadSavedForm() {
    if (savedFormData === undefined) {
      console.log(`loadSavedForm (revert): no savedFormData present, ignoring`)
      return
    }
    setFormData(initFormData())
    setPrefermentFormData(initPrefermentFormData())
    setFormNameError(false)
    setRecipeItems(undefined)
    setRecipeVisible(false)
    setSaveBtnDisabled(true)
    setFormStateKey(getNewStateKey())
    setHasChanges(false)
  }

  function resetForm() {
    setSavedFormData(undefined)
    const newFormData = {...mainFormQuantityDefaults}
    setFormData(newFormData)
    setPrefermentFormData(prefermentQuantityDefaults)
    setFormNameError(false)
    setRecipeItems(undefined)
    setRecipeVisible(false)
    setSaveBtnDisabled(true)
    setSaveAsRatioBtnDisabled(true)
    setFormStateKey(getNewStateKey())
    setHasChanges(false)
    navigate(urlQuantityDough)
  }

  function handleSaveClick() {
    saveForm(formData.formName)
  }

  function setNewFormName(newFormName) {
    updateFormData({...formData, formName: newFormName})
  }

  function applyNewFormName(newFormName) {
    saveForm(newFormName, true)
  }

  // save quantity form as currently rendered
  function saveForm(formName, forceSave = false) {
    let formKey = formName
    if (Object.keys(localStorage).includes(formKey) && !forceSave) {
      setOldFormName(formData.formName)
      setOpenFormExistsDialog(true)
      return
    }
    setOldFormName(undefined)
    let timeStampField = 'createdAt'
    if (formData.hasOwnProperty(timeStampField)) {
      timeStampField = 'modifiedAt'
    }
    const updatedFormData = {
      ...formData,
      formName: formName,
      [timeStampField]: getLocalDate().getTime()
    }
    setFormData(updatedFormData)
    const mergedFormData = {...updatedFormData, prefermentData: (formData.includePreferment ? prefermentFormData : undefined)}
    localStorage.setItem(formKey, JSON.stringify(mergedFormData))
    setSavedFormData(mergedFormData)
    setRecipeItems(calculateQuantityRecipe(mergedFormData))
    setSaveBtnDisabled(true)
    setHasChanges(false)
  }

  // Save as Ratio button
  function handleSaveAsRatioClick() {
    setRatioCandidateFormName(`${formData.formName} (Ratio)`)
    setOpenRatioFormNamePrompt(true)
  }

  // handle prompt save button...
  function onRatioFormNameSelected() {
    if (ratioCandidateFormName?.trim()) {
      saveRatioForm(ratioCandidateFormName)
    }
  }

  // confirm overwrite of existing ratio form name
  function confirmNewRatioFormName() {
    setOpenRatioFormNameConfirm(false)
    saveRatioForm(ratioCandidateFormName, true)
  }

  // cancel save ratio form
  function cancelNewRatioFormName() {
    setOpenRatioFormNameConfirm(false)
  }

  // saving ratio form - no navigation, no visual cue of this save in the current rendering of the recipe
  // ...could implement an Alert success that disappears after a timeout
  function saveRatioForm(selectedRatioFormName, forceSave = false) {
    let formKey = selectedRatioFormName
    if (Object.keys(localStorage).includes(formKey) && !forceSave) {
      setOpenRatioFormNameConfirm(true)
      return
    }
    const mergedFormData = {...formData,
      formType: formTypeRatioDough,
      formName: selectedRatioFormName,
      prefermentData: (formData.includePreferment ? prefermentFormData : undefined),
      createdAt: getLocalDate().getTime()}
    const ratiosFormData = convertToRatioForm(mergedFormData)
    localStorage.setItem(formKey, JSON.stringify(ratiosFormData))
    setRatioFormName(selectedRatioFormName)
    setSaveAsRatioComplete(true)
  }

  function dismissSaveAsRatioComplete(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    setSaveAsRatioComplete(false)
    setRatioFormName(undefined)
  }

  return (
      <div key={formStateKey}>
        <Grid container direction={'column'}>
          <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={3000} open={ratioFormName && saveAsRatioComplete} onClose={dismissSaveAsRatioComplete}>
            <Alert sx={{marginTop: '1rem'}} severity={'success'} onClose={dismissSaveAsRatioComplete}>{`Ratio Form ${ratioFormName} saved successfully`}</Alert>
          </Snackbar>
        </Grid>
        <Grid container direction={'row'} paddingLeft={{xs: '5%', sm: '8%', md: '8%', lg: '20%'}} paddingRight={{xs: '5%', sm: '8%', md: '8%', lg: '20%'}} rowSpacing={1.25} columns={{xs: 4, sm: 8, md: 12}}>
          <Grid item container direction={'column'} marginTop={1.5} alignItems={'center'}>
            <Typography variant={'h5'}>Quantity Dough Formula</Typography>
          </Grid>
          <Grid item container direction={'column'} justifyContent={'center'} paddingLeft={{xs: 0, sm: 0, md: 8}} paddingRight={{xs: 0, sm: 0, md: 8}}>
            <Grid item container direction={'row'} columns={{xs: 4, sm: 8, md: 12}}>
              <Grid item xs={3.5} sm={7.5} md={11.5}>
                <FormTextField sx={{width: '100%'}} required
                      id="outlined-required"
                      size={'small'}
                      name={'formName'}
                      label="Name"
                      value={formData.formName}
                      onChange={onFormNameFieldChange}
                      error={formNameError}
                      helperText={
                        formNameError ? 'Formula name is required' : ''
                      }
                      variant="filled"/>
              </Grid>
              <Grid item xs={0.5} sm={0.5} md={0.5}>
                <Tooltip title={'Attribution'} placement={'bottom'} arrow>
                  <IconButton onClick={clickAttribution}><AttributionIcon sx={{marginTop: 0.5}} color={'primary'}/></IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction={'row'} columns={{xs: 4, sm: 8, md: 12}} justifyContent={'center'} alignContent={'center'}>
            <Grid item xs={1.5} sm={2.25} md={2.75}>
              <FormTextField size={'small'}
                             sx={{width: '8ch'}}
                             id="batches-select"
                             label={'Bake Units'}
                             name={'numBatches'} variant={'filled'}
                             value={formData.numBatches}
                             onChange={onNumericFieldChange}>
              </FormTextField>
              <FormControl>
                <IconButton onClick={incrementBatches} sx={{padding: 0, marginTop: -0.3}} color={"primary"}><ArrowDropUp fontSize={'large'}/></IconButton>
                <IconButton onClick={decrementBatches} sx={{padding: 0, marginTop: -1.5}} color={"primary"}><ArrowDropDown fontSize={'large'}/></IconButton>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1}
                marginBottom={1}>
            <FormGroup row>
              <FormControlLabel control={<Switch name={'includePreferment'} checked={formData.includePreferment}
                                                 onChange={onIncludePrefermentChange}/>}
                                label={<Typography variant={'subtitle2'}>Add Preferment Formula</Typography>}/>
            </FormGroup>
          </Grid>
          {formData.includePreferment ?
              (
                <Grid item container direction={'column'}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header">
                      <Typography>Preferment</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <QuantityDoughFormDetail visible={true} isPrefermentPanel={true} formData={prefermentFormData}
                                               setFormData={updatePrefermentData}/>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ) : null
          }
          <Grid item container direction={'column'}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                <Typography>Main Dough</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <QuantityDoughFormDetail visible={true} isPrefermentPanel={false} formData={formData} setFormData={updateFormData}/>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item container direction={'column'}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                <Typography>Notes & Instructions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField fullWidth multiline rows={10}
                           id={'outlined-textarea'}
                           variant={'standard'}
                           name={'notes'}
                           value={formData.notes}
                           onChange={onStringFieldChange}/>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item container spacing={2} direction={'row'} justifyContent={'center'} columns={{xs: 4, sm: 8, md: 12}}>
            {savedFormData !== undefined ?
                (
                <Grid item>
                  <Tooltip title={'Revert to Saved copy'} placement={'bottom'} arrow>
                  <span>
                    <IconButton sx={{marginRight: -1}} onClick={loadSavedForm} disabled={!hasChanges || formNameError}><ReplayIcon fontSize={'medium'} color={(!hasChanges || formNameError ? 'grey': 'primary')}/></IconButton>
                  </span>
                  </Tooltip>
                </Grid>
              ) : null
            }
            <Grid item>
              <Button variant={'contained'} onClick={applyForm} disabled={formNameError}>Apply</Button>
            </Grid>
            <Grid item>
              <Button color={"secondary"} variant={'outlined'} onClick={resetForm}>Clear</Button>
            </Grid>
            <Grid item>
              <Button color={"secondary"} variant={'outlined'} disabled={saveBtnDisabled}
                      onClick={handleSaveClick}>Save</Button>
            </Grid>
            <Grid item>
              <Button color={"secondary"} variant={'outlined'} disabled={saveAsRatioBtnDisabled}
                      onClick={handleSaveAsRatioClick}>Save as Ratio</Button>
            </Grid>
            <Grid item>
              <IconButton color={'primary'} onClick={() => openHelp(undefined, helpQuantityButtons)}><HelpOutline fontSize={'small'} /></IconButton>
            </Grid>
          </Grid>
          <Grid item container direction={'column'} justifyContent={'center'} columns={{xs: 4, sm: 8, md: 12}}>
            <Paper elevation={3} sx={{marginBottom: '1.5rem'}}>
              <DoughRecipeWrapper items={recipeItems} visible={recipeVisible} closeRequest={recipeCloseRequest}/>
            </Paper>
          </Grid>
        </Grid>
        <SavedObjectDialog key={'qformName'} objectName={formData.formName}
                           setNewObjectName={setNewFormName}
                           apply={applyNewFormName}
                           existingFormName={oldFormName}
                           dialogOpen={openFormExistsDialog}
                           setDialogOpen={setOpenFormExistsDialog}
                           objectType={'Formula'} />
        <PromptFormDialog message={'Enter a distinct name for this Formula converted to a Ratio Formula. The Ratio Formula can be accessed from the Saved menu item.'}
                          fieldName={'Ratio Formula Name'}
                          formValue={ratioCandidateFormName}
                          setFormValue={setRatioCandidateFormName}
                          onSave={onRatioFormNameSelected}
                          dialogOpen={openRatioFormNamePrompt}
                          setDialogOpen={setOpenRatioFormNamePrompt} />
        <ConfirmDialog title={`Formula '${ratioCandidateFormName}' exists!`}
                       confirmMessage={`Click Overwrite to proceed anyway`}
                       confirm={confirmNewRatioFormName}
                       confirmButtonName={'Overwrite'}
                       cancel={cancelNewRatioFormName}
                       cancelButtonName={'Cancel'}
                       dialogOpen={openRatioFormNameConfirm}/>
        <AttributionDialog attributionText={formData.attribution} setNewAttributionText={applyAttribution} dialogOpen={openAttributionDialog} setDialogOpen={setOpenAttributionDialog}/>
        <ContextualHelpDialog isOpen={openContextualHelpDialog} close={onCloseHelp} title={contextualHelpContent.title} content={contextualHelpContent.content}/>
      </div>
  )


}

export {QuantityDoughForm}
