import {
  Alert,
  Box, Chip,
  FormControl, FormControlLabel, Grid,
  List,
  ListItem,
  ListItemButton, ListItemText,
  Radio,
  RadioGroup, Snackbar, TextField, Tooltip
} from "@mui/material";
import * as React from "react";
import {getDateTimeStr, getLocalDate, getLocalDateStr} from "../utils/DateUtils";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {useState} from "react";
import {Link} from "react-router-dom";
import {getLocalStorageCollectionJson, putLocalStorageCollectionJson} from "../utils/StorageUtils";
import {formTypeUrlMap} from "../../App";
import {formTypeLevain, formTypeQuantityDough, formTypeRatioDough} from "../shared/Types";
import {ConfirmDialog} from "../shared/ConfirmDialog";
import {PromptFormDialog} from "../shared/PromptFormDialog";

const exportFileNamePrefix = 'formulaDough'
const formulaTypeDescriptionMap = [
  {
    formType: formTypeRatioDough,
    fileType: 'ratio'
  },
  {
    formType: formTypeQuantityDough,
    fileType: 'quantity'
  },
  {
    formType: formTypeLevain,
    fileType: 'levain'
  },
]

function SavedItems(props) {
  const [storageKeys, setStorageKeys] = useState(Object.keys(localStorage))
  const [formType, setFormType] = useState(formTypeRatioDough)
  const [uploadFile, setUploadFile] = useState(null)
  const [actionableFormName, setActionableFormName] = useState(null)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const [openCopyDialog, setOpenCopyDialog] = useState(false)
  const [duplicateCopy, setDuplicateCopy] = useState(false)
  const [candidateFormName, setCandidateFormName] = useState(undefined)
  const [invalidUploadFile, setInvalidUploadFile] = useState()
  const [fileImportComplete, setFileImportComplete] = useState()

  const storageMap = {}
  storageKeys.forEach(k => {
    storageMap[k] = JSON.parse(localStorage.getItem(k))
  })

  function hasStoredItems() {
    let storageItem = storageKeys
    .find((k) => {
       return storageMap[k]['formType'] === formType
    })
    return storageItem !== undefined
  }

  function selectedFormulaTypeDescription() {
    return formulaTypeDescriptionMap.find((ft) => ft.formType === formType).fileType
  }

  function exportFormulas() {
    // credit: https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react
    const element = document.createElement("a");
    const jsonArray = getLocalStorageCollectionJson('formType', formType, 'createdAt', false)
    const file = new Blob([`[${jsonArray.join(',')}]`], {type: 'application/json'});
    element.href = URL.createObjectURL(file);
    const fileSuffix = selectedFormulaTypeDescription()
    element.download = `${exportFileNamePrefix}-${fileSuffix}-${getLocalDateStr()}.json`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element)
  }

  function onUploadFileChange(evt) {
    if (evt.target.files && evt.target.files.length > 0) {
      const uploadFile = evt.target.files[0]
      // console.log(`upload file selected: ${uploadFile.name}`)
      setUploadFile(uploadFile)
      // reset input value to permit multiple uploads of the same file
      evt.target.value = null
    } else {
      console.log(`upload file selection not detected!`)
    }
  }

  function importFormulas() {
    if (!uploadFile) {
      console.log(`No upload file selected!`)
      return
    }
    const fileReader = new FileReader()
    fileReader.readAsText(uploadFile, 'UTF-8')
    fileReader.onload = e => {
      const fileContents = e.target.result.trim()
      const expectedFieldsRegex = /"formName"/
      if (!fileContents.startsWith('[') || !fileContents.match(expectedFieldsRegex) || !fileContents.endsWith(']')) {
        setInvalidUploadFile(true)
        return
      }
      // console.log(`Upload of file ${uploadFile.name} complete`)
      importFormulasToLocalStorage(JSON.parse(fileContents))
    }
  }

  function removeUploadFile() {
    setUploadFile(null)
  }

  function importFormulasToLocalStorage(formsArray) {
    putLocalStorageCollectionJson(formsArray, 'formName')
    setStorageKeys(Object.keys(localStorage))
    setFileImportComplete(true)
  }

  function onFormTypeChange(evt) {
    setFormType(evt.target.value)
  }

  function deleteClick(itemKey) {
    setActionableFormName(itemKey)
    setOpenDeleteConfirm(true)
  }

  function cancelDelete() {
    setActionableFormName(undefined)
    setOpenDeleteConfirm(false)
  }

  function doDelete() {
    localStorage.removeItem(actionableFormName)
    setStorageKeys(storageKeys.filter((k) => k !== actionableFormName))
    setActionableFormName(undefined)
    setOpenDeleteConfirm(false)
  }

  function copyClick(itemKey) {
    setActionableFormName(itemKey)
    setCandidateFormName(`Copy of ${itemKey}`)
    setOpenCopyDialog(true)
  }

  function doCopy() {
    if (storageKeys.includes(candidateFormName)) {
      setDuplicateCopy(true)
      setActionableFormName(undefined)
      setOpenCopyDialog(false)
      return
    }
    const sourceFormula = storageMap[actionableFormName]
    const destFormula = {...sourceFormula, formName: candidateFormName, createdAt: getLocalDate().getTime()}
    delete destFormula.modifiedAt
    localStorage.setItem(candidateFormName, JSON.stringify(destFormula))
    storageKeys.unshift(candidateFormName)
    setStorageKeys(storageKeys)
    setActionableFormName(undefined)
    setOpenCopyDialog(false)
  }

  function downloadItemClick(itemKey) {
    const element = document.createElement("a");
    const file = new Blob([`[${JSON.stringify(storageMap[itemKey])}]`], {type: 'application/json'});
    element.href = URL.createObjectURL(file);
    element.download = `${exportFileNamePrefix}-${itemKey}-${getLocalDateStr()}.json`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element)
  }

  function dismissDuplicateCopy(event, reason) {
    setDuplicateCopy(false)
  }

  function getUrlFromFormType() {
    const navObj = formTypeUrlMap.find((ft) => ft.formType === formType)
    if (navObj === undefined) {
      console.log(`No URL found for formType ${formType}!`)
      return undefined
    }
    return navObj.url
  }

  function renderSingleItem(key, ix) {
    let fieldName = storageMap[key].hasOwnProperty('modifiedAt') ? 'modifiedAt' : 'createdAt'

    const itemDateStr = getDateTimeStr(new Date(storageMap[key][fieldName]))
    return (
      <ListItem sx={{paddingLeft: 0, paddingRight: 5}} key={ix} id={key} secondaryAction={
        <span>
        <IconButton onClick={() => deleteClick(key)} edge="end">
          <DeleteIcon />
        </IconButton>
        <IconButton sx={{marginLeft: 1.5}} onClick={() => copyClick(key)} edge="end">
          <ContentCopyIcon />
        </IconButton>
        <IconButton sx={{marginLeft: 1.5, marginTop: 0.5}} onClick={() => downloadItemClick(key)} edge="end">
          <FileDownloadIcon/>
        </IconButton>
        </span>
      }>
        <Link to={`${getUrlFromFormType()}/${window.btoa(key)}`} style={{textDecoration: 'none', color: 'white'}} >
          <ListItemButton divider={false}>
            <ListItemText secondary={itemDateStr} primary={key}/>
          </ListItemButton>
        </Link>
      </ListItem>
    )
  }
  function dismissInvalidFile(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    setInvalidUploadFile(false)
  }

  function dismissImportComplete(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    setFileImportComplete(false)
    setUploadFile(undefined)
  }

  return (
          <div>
            <Grid container direction={'column'}>
              <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={3000} open={duplicateCopy} onClose={dismissDuplicateCopy}>
                <Alert sx={{marginTop: '1rem'}} severity={'error'} onClose={dismissDuplicateCopy}>{`Formula ${candidateFormName} already exists, try another name.`}</Alert>
              </Snackbar>
              <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={5000} open={uploadFile && invalidUploadFile} onClose={dismissInvalidFile}>
                  <Alert sx={{marginTop: '1rem'}} severity={'error'} onClose={dismissInvalidFile}>{`File ${uploadFile?.name} is invalid. Must be a file previously exported from this screen on any device`}</Alert>
                </Snackbar>
                <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={3000} open={uploadFile && fileImportComplete} onClose={dismissImportComplete}>
                  <Alert sx={{marginTop: '1rem'}} severity={'success'} onClose={dismissImportComplete}>{`File ${uploadFile?.name} imported successfully`}</Alert>
                </Snackbar>
            </Grid>

            <Box component="form"
                 sx={{
                   '& .MuiTextField-root': {m: 1, width: '9ch'},
                   display: 'flex', flexDirection: 'column', alignItems: 'center'
                 }}
                 noValidate
                 autoComplete="off">
              <div>
                <Grid item container marginTop={'0.5rem'} direction={'row'} justifyContent={'center'} marginRight={12} columns={3} spacing={'0.75rem'}>
                  <Tooltip title={`Export saved ${selectedFormulaTypeDescription()} formulas`} placement={'top-start'} arrow>
                  <Grid item>
                    <IconButton disabled={!hasStoredItems()} onClick={exportFormulas}><FileDownloadIcon fontSize={'large'}/></IconButton>
                  </Grid>
                  </Tooltip>
                  <Grid item xs={1}>
                    <Tooltip title={'Choose file for Import'} placement={'top-start'} arrow>
                      <FormControl sx={{width: '4ch'}}>
                      <TextField  variant={'standard'}
                              InputProps={{ sx:{height: '2rem', width: 0},
                                startAdornment: (
                                        <IconButton component="label">
                                          <DriveFolderUploadIcon fontSize={'large'}/>
                                          <input style={{display:"none"}}
                                                 type="file"
                                                 hidden
                                                 onChange={onUploadFileChange}
                                                 name="fileInput"
                                          />
                                        </IconButton>
                                )}}/>
                      </FormControl>
                    </Tooltip>
                  </Grid>
                </Grid>
                {uploadFile ? (
                  <Grid item container marginTop={'0.5rem'} direction={'row'} spacing={'0.75rem'} justifyContent={'flex-start'} columns={4}>
                    <Grid item>
                    <Tooltip title={'Import to Saved items'} placement={'top'} arrow>
                      <Chip label={uploadFile?.name} variant={'filled'} onClick={importFormulas} onDelete={removeUploadFile}/>
                    </Tooltip>
                    </Grid>
                  </Grid>
                 ) : null}
              </div>
              <FormControl sx={{marginTop: '0.5rem'}}>
                <RadioGroup aria-labelledby="form-type-group-label" row
                            defaultValue={formTypeRatioDough}
                            value={formType}
                            name="formType" onChange={onFormTypeChange}>
                  <FormControlLabel value={formTypeRatioDough} control={<Radio/>} label="Ratio"/>
                  <FormControlLabel value={formTypeQuantityDough} control={<Radio/>} label="Quantity"/>
                  <FormControlLabel value={formTypeLevain} control={<Radio/>} label="Levain"/>
                </RadioGroup>
              </FormControl>
              <List dense sx={{
                marginBottom: '1rem',
                width: '30ch',
                maxWidth: 600,
                height: 400,
                overflowX: 'hidden',
                overflowY: 'auto'
              }}>
                {storageKeys
                .filter((k) => {
                  return storageMap[k].formType === formType
                })
                .sort((a, b) => {
                  let fieldA = storageMap[a].hasOwnProperty('modifiedAt') ? 'modifiedAt' : 'createdAt'
                  let fieldB = storageMap[b].hasOwnProperty('modifiedAt') ? 'modifiedAt' : 'createdAt'
                  return storageMap[b][fieldB] - storageMap[a][fieldA]
                })
                .map((k, ix) => {
                  return renderSingleItem(k, ix)
                })}
              </List>
            </Box>
            <ConfirmDialog title={`Deleting '${actionableFormName}'`}
                           confirmMessage={`Are you sure?`}
                           confirm={doDelete}
                           confirmButtonName={'Delete'}
                           cancel={cancelDelete}
                           cancelButtonName={'Cancel'}
                           dialogOpen={openDeleteConfirm}/>
            <PromptFormDialog message={`Enter unique name for Copy of ${actionableFormName}`}
                              fieldName={'Formula Name'}
                              formValue={candidateFormName}
                              setFormValue={setCandidateFormName}
                              onSave={doCopy}
                              dialogOpen={openCopyDialog}
                              setDialogOpen={setOpenCopyDialog} />

          </div>
  )
}

export {SavedItems}