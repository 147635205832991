import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {useLocation} from 'react-router-dom';
import {Container, useTheme} from '@mui/system';
import {Grid, Tooltip, useMediaQuery} from '@mui/material';
import {StyledNavLink} from "./StyledNavLink";
import {DrawerMenu} from "./DrawerMenu";
import {urlLevain, urlQuantityDough, urlRatioDough} from "../../App";
import PercentIcon from "@mui/icons-material/Percent";
import NumbersIcon from "@mui/icons-material/Numbers";
import ScienceIcon from "@mui/icons-material/Science";
import SaveIcon from "@mui/icons-material/Save";



function MainMenu() {
  const currentLocation = useLocation()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
      <AppBar position='static'>
        <Container maxWidth={'lg'}>
        <Toolbar>
          {isMobile ? (<DrawerMenu />)
          : (
          <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={{ sm: 1, md: 1}} >
            <Grid item xs={1} sm={2} md={1.5}>
              <StyledNavLink className={`${currentLocation.pathname === '/' ? 'active' : undefined}`} to={'/'}>Home</StyledNavLink>
            </Grid>
            <Grid item xs={1} sm={2} md={1.5}>
              <StyledNavLink className={`${currentLocation.pathname === '/help' ? 'active' : undefined}`} to={'/help'}>Help</StyledNavLink>
            </Grid>
            <Grid item xs={1} sm={2} md={1.5}>
              <Tooltip title={'Ratio Dough Formula'} placement={'bottom'} arrow>
                <StyledNavLink className={`${currentLocation.pathname.startsWith(urlRatioDough) ? 'active' : undefined}`} to={urlRatioDough}><PercentIcon/> Ratio</StyledNavLink>
              </Tooltip>
            </Grid>
            <Grid item xs={1} sm={2} md={1.5}>
              <Tooltip title={'Quantity Dough Formula'} placement={'bottom'} arrow>
                <StyledNavLink className={`${currentLocation.pathname === urlQuantityDough ? 'active' : undefined}`} to={urlQuantityDough}><NumbersIcon/> Quantity</StyledNavLink>
              </Tooltip>
            </Grid>
            <Grid item xs={1} sm={2} md={1.5}>
              <Tooltip title={'Levain Formula'} placement={'bottom'} arrow>
                <StyledNavLink className={`${currentLocation.pathname.startsWith(urlLevain) ? 'active' : undefined}`} to={urlLevain}><ScienceIcon/>Levain</StyledNavLink>
              </Tooltip>
              </Grid>
            <Grid item xs={1} sm={2} md={1.5}>
              <Tooltip title={'Saved Formulas'} placement={'bottom'} arrow>
                <StyledNavLink className={`${currentLocation.pathname === '/saved' ? 'active' : undefined}`} to={'/saved'}><SaveIcon/>Saved</StyledNavLink>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        </Toolbar>
        </Container>
      </AppBar>
  )
}

export default MainMenu;
