import * as React from 'react';
import {Grid, ImageList, ImageListItem, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import pumpernickel from './../../images/pumpernickel.jpeg'
import sesamepoppy from './../../images/sesame-poppy.jpeg'
import fermenting from './../../images/fermenting.jpeg'
import wholewheat from './../../images/wholewheat.jpeg'
import twoloaves from '../../images/twoloaves.jpeg'

const itemData = [
  {
    img: pumpernickel,
    title: '',
    rows: 2,
    cols: 2
  },
  {
    img: sesamepoppy,
    title: '',
    rows: 2
  },
  {
    img: wholewheat,
    title: ''
  },
  {
    img: fermenting,
    title: ''
  },
  {
    img: twoloaves,
    title: '',
    rows: 3,
    cols: 4
  }
]

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
            size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

function getImageList() {
  return (
          <ImageList sx={{width: '100%', height: '100%', transform: 'translateZ(0)'}} variant="quilted" cols={4} rowHeight={150}>
            {itemData.map((item) => (
                      <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                        <img
                                {...srcset(item.img, 121, item.rows, item.cols)}
                                alt={item.title}
                                loading="lazy"
                        />
                      </ImageListItem>
            ))}
          </ImageList>
  )
}

function Home(props) {
  return (
    <Grid container direction={'column'} padding={2} paddingLeft={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}} paddingRight={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}} justifyContent={'center'} alignItems={'center'}
          rowSpacing={1}>
      <Grid item xs={4} sm={8} md={12}>
        <Typography variant={'h5'}>Bread Dough Formulas</Typography>
      </Grid>
      <Grid item xs={4} sm={8} md={12} marginBottom={1}>
        <Typography display={'block'} variant={'body1'} fontStyle={'italic'}>For detail-oriented sourdough geeks like me!</Typography>
      </Grid>

      <Grid item xs={4} sm={8} md={12}>
          <List>
            <ListItem dense={true}>
              <ListItemIcon >
                <AutoAwesomeIcon sx={{paddingLeft: 2}}/>
              </ListItemIcon>
              <ListItemText>Create scalable formulas for bread dough recipes</ListItemText>
            </ListItem>
            <ListItem dense={true}>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{paddingLeft: 2}}/>
              </ListItemIcon>
              <ListItemText>See rise time projections from your ratio specifications</ListItemText>
            </ListItem>
            <ListItem dense={true}>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{paddingLeft: 2}}/>
              </ListItemIcon>
              <ListItemText>Generate kitchen-ready, print-friendly recipes and notes</ListItemText>
            </ListItem>
            <ListItem dense={true}>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{paddingLeft: 2}}/>
              </ListItemIcon>
              <ListItemText>Save and manage your formulas</ListItemText>
            </ListItem>
          </List>
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <Typography display={'block'} variant={'body1'} fontStyle={'italic'}>More about the app's features and How-To's can be found in the Help menu.</Typography>
      </Grid>

      <Grid item xs={4} sm={8} md={12}>
        <Typography variant={'h6'}>Happy Baking!</Typography>
      </Grid>

      <Grid item container direction={'column'} justifyContent={'center'} alignItems={'center'}>
        {getImageList()}
      </Grid>
    </Grid>
  )
}

export { Home }