import * as React from 'react';
import {
  Box, Divider,
  FormControlLabel, FormGroup,
  Grid,
  Switch,
  Typography
} from "@mui/material";
import {
  getLevainTypeLabel, getPrefermentLabel, LEVAIN_PCT_TYPE_PFF
} from "../shared/Types";
import {
  getYeastPercentFromId,
  getYeastRatioSelectLabel,
  getMaxYeastRatioId
} from "../shared/Yeast";
import {toFixedDecimal} from "../utils/NumberUtils";
import {useState} from "react";
import IconButton from "@mui/material/IconButton";
import {
  helpAutolyse,
  helpFermVolTemp,
  helpFinalMixHydration
} from "../help/HelpContent";
import {HelpOutline} from "@mui/icons-material";
import {ContextualHelpDialog} from "../help/ContextualHelpDialog";
import {FormulaSlider} from "../shared/FormulaSlider";
import {
  doughVolumes,
  fermTemps,
  getLevainTime,
  getYeastTime
} from "../shared/FermentationTiming";
import {levainPffToTwEstimate} from "./DoughRecipeCalculator";
import {FermentationTimingControl} from "../shared/FermentationTimingControl";
import {IngredientGroupForm} from "../shared/IngredientGroupForm";
import {IngredientCollectionForm} from "../shared/IngredientCollectionForm";

function MainDoughForm(props) {
  const {formData, setFormData, prefermentType, prefermentLevainFermPct, prefermentStarterPct, prefermentHydrationPct} = props
  const [openContextualHelpDialog, setOpenContextualHelpDialog] = useState(false)
  const [contextualHelpContent, setContextualHelpContent] = useState({title: undefined, content: undefined})

  function openHelp(title, content) {
    setContextualHelpContent({title: title, content: content})
    setOpenContextualHelpDialog(true)
  }

  function onCloseHelp() {
    setOpenContextualHelpDialog(false)
    setContextualHelpContent({title: undefined, content: undefined})
  }

  function updateFlourData(updatedFlourData) {
    setFormData({
      ...formData,
      flour: updatedFlourData
    })
  }

  function updateLiquidData(updatedLiquidData) {
    setFormData({
      ...formData,
      liquid: updatedLiquidData
    })
  }

  function updateMixinData(mixinData) {
    setFormData({
      ...formData,
      mixin: mixinData
    })
  }

  function updateSoakerData(soakerData) {
    setFormData({
      ...formData,
      soaker: soakerData
    })
  }

  function updateToppingsData(toppingsData) {
    setFormData({
      ...formData,
      toppings: toppingsData
    })
  }

  function onNumericFieldChange(evt) {
    let intVal = parseInt(evt.target.value)
    if (isNaN(intVal)) {
      intVal = 0
    }
    setFormData({
      ...formData,
      [evt.target.name]: intVal
    })
  }

  function onDecimalFieldChange(evt) {
    let floatVal = parseFloat(evt.target.value)
    if (isNaN(floatVal)) {
      floatVal = 0
    }
    setFormData({
      ...formData,
      [evt.target.name]: floatVal
    })
  }

  function onYeastRatioChange(evt) {
    let ratioId = parseInt(evt.target.value)
    if (isNaN(ratioId)) {
      ratioId = 0
    }
    setFormData({
      ...formData,
      yeastRatioId: ratioId,
      yeastPct: getYeastPercentFromId(ratioId)
    })
  }

  function onGeneralFieldChange(evt) {
    setFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    })
  }

  function onSwitchChange(evt) {
    setFormData({...formData, [evt.target.name]: evt.target.checked})
  }

  function renderMainHydration() {
    return (
      <Grid item container direction={'column'}>
        <Grid item container direction={'row'}>
          <Typography variant={'subtitle2'} gutterBottom>Final Mix Hydration:
            <Box component="span" fontWeight='fontWeightBold'> {formData.hydrationPct} %</Box>
          </Typography>
          <IconButton sx={{marginTop: -0.9}} disabled={false} color={'primary'}
                      onClick={() => openHelp(undefined, helpFinalMixHydration)}><HelpOutline fontSize={'small'}/></IconButton>
        </Grid>
        <FormulaSlider getAriaLabel={() => 'Dough Hydration'}
                       disabled={false}
                       sx={{marginLeft: 1, width: '90%'}}
                       name={'hydrationPct'}
                       value={formData.hydrationPct}
                       onChange={onNumericFieldChange}
                       valueLabelDisplay="auto"
                       color={"water"}
                       min={20}
                       max={150}
                       step={1}/>
      </Grid>
    )
  }

  function renderAutolyseSwitch() {
    return (
        <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1}
              marginBottom={1}>
          <FormGroup row>
            <FormControlLabel control={<Switch name={'autolyse'} checked={formData.autolyse}
                                               onChange={onSwitchChange}
                                               disabled={false}/>}
                              label={<Typography variant={'subtitle2'}>Autolyse</Typography>}/>
            <IconButton disabled={false} color={'primary'}
                        onClick={() => openHelp(undefined, helpAutolyse)}><HelpOutline
                    fontSize={'small'}/></IconButton>
          </FormGroup>
        </Grid>
      )
  }

  function getStarterSliderHeading() {
    if (formData.starterPct > 0) {
      return `${toFixedDecimal(formData.starterPct, 2)}% ${getLevainTypeLabel(formData.starterType)}`
    }
    return 'None'
  }

  function getYeastSliderHeading() {
    if (formData.yeastRatioId > 0) {
      return `${toFixedDecimal(getYeastPercentFromId(formData.yeastRatioId), 2)}%`
    }
    return 'None'
  }

  function renderMainLeavening() {
    return (
      <div>
        <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={2} marginBottom={0.5}>
          <Typography variant={'subtitle2'} gutterBottom>Levain:
            <Box component="span" fontWeight='fontWeightBold'> {getStarterSliderHeading()}</Box>
          </Typography>
          <FormulaSlider getAriaLabel={() => 'Levain '}
                         sx={{marginLeft: 1, width: '90%'}}
                         disabled={formData.yeastRatioId !== 0}
                         name={'starterPct'}
                         value={formData.starterPct}
                         onChange={onNumericFieldChange}
                         color={formData.starterPct > 0 ? "primary" : "attention"}
                         valueLabelDisplay={'auto'}
                         min={0}
                         max={150}
                         step={1}/>
        </Grid>
        <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1} marginBottom={2.5}>
          <Typography variant={'subtitle2'} gutterBottom>Dry Yeast:
            <Box component="span" fontWeight='fontWeightBold'> {getYeastSliderHeading()}</Box>
          </Typography>
          <FormulaSlider getAriaLabel={() => 'Dry Yeast'}
                         sx={{marginLeft: 1, width: '90%'}}
                         disabled={formData.starterPct > 0}
                         name={'yeastRatioId'}
                         value={formData.yeastRatioId}
                         onChange={onYeastRatioChange}
                         color={formData.yeastRatioId > 0 ? "primary" : "attention"}
                         valueLabelFormat={getYeastRatioSelectLabel}
                         valueLabelDisplay={'auto'}
                         min={0}
                         max={getMaxYeastRatioId()}
                         step={1}/>
        </Grid>
        <FermentationTimingControl headingLabel={'Bulk Ferment'} fermPctValueArr={doughVolumes} fermTempValueArr={fermTemps}
                                   timeDisplayValue={mainDoughTimeFormatted} timeDisplayWarning={() => undefined}
                                   fermPctValue={formData.bulkFermPct} fermPctName={'bulkFermPct'} fermPctLabel={'Bulk Ferm Goal'}
                                   fermTempValue={formData.bulkFermTempF} fermTempName={'bulkFermTempF'} fermTempLabel={'Bulk Ferm Temp'}
                                   onChange={onGeneralFieldChange} onHelpClick={() => openHelp(undefined, helpFermVolTemp)}/>
      </div>
    )
  }

  function renderLeavening(includePreferment) {
    if (includePreferment) {
      return renderPrefermentRatio()
    }
    if (!includePreferment) {
      return renderMainLeavening()
    }
    return null
  }

  function renderPrefermentRatio() {
    return (
      <div>
        <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={2} marginBottom={1} >
          <Grid item container direction={'row'}>
            <Typography variant={'subtitle2'} gutterBottom>Preferment:
              <Box component="span" fontWeight='fontWeightBold'> {formData.prefermentPffPct}% PFF</Box>
            </Typography>
          </Grid>
          <FormulaSlider getAriaLabel={() => 'Preferment Flour'}
                         disabled={false}
                         sx={{marginLeft: 1, width: '90%'}}
                         name={'prefermentPffPct'}
                         value={formData.prefermentPffPct}
                         onChange={onDecimalFieldChange}
                         color={formData.prefermentPffPct > 0 ? "primary" : "attention"}
                         valueLabelDisplay="auto"
                         min={1}
                         max={90}
                         step={0.5}/>
        </Grid>
        <FermentationTimingControl headingLabel={'Bulk Ferment'} fermPctValueArr={doughVolumes} fermTempValueArr={fermTemps}
                                   timeDisplayValue={prefermentMainDoughTimeFormatted} timeDisplayWarning={prefermentMainDoughWarningMsg}
                                   fermPctValue={formData.bulkFermPct} fermPctName={'bulkFermPct'} fermPctLabel={'Bulk Ferm Goal'}
                                   fermTempValue={formData.bulkFermTempF} fermTempName={'bulkFermTempF'} fermTempLabel={'Bulk Ferm Temp'}
                                   onChange={onGeneralFieldChange} onHelpClick={() => openHelp(undefined, helpFermVolTemp)}/>
      </div>
  )
  }

  function prefermentMainDoughTimeFormatted() {
    if (formData.prefermentPffPct === 0) {
      return 'n/a'
    }

    //TODO: factor in additional leavening on main form even when preferment is included...

    // NOTE: treating yeast-based starter like a levain and using levain timings, but this probably
    // isn't very accurate if the yeast population in a yeast build is much larger than starter yeast population...
    // ... unsure how else to scale, may need to do some trials ...
    let levainPct = levainPffToTwEstimate(formData.prefermentPffPct, prefermentHydrationPct, prefermentStarterPct)
    let qualifier = ''
    if (prefermentLevainFermPct < 100) {
      qualifier = '*'
    }
    levainPct = Math.round(levainPct * (prefermentLevainFermPct / 100))

    return `~ ${getLevainTime(formData.bulkFermTempF, formData.bulkFermPct, levainPct, formData.hydrationPct)} ${qualifier}`
  }

  function prefermentMainDoughWarningMsg() {
    if (prefermentLevainFermPct < 100) {
      return `*Time adjusted due to preferment rise less than 100%`
    }
    return undefined
  }

  function mainDoughTimeFormatted() {
    if (formData.starterPct === 0 && formData.yeastRatioId === 0) {
      return 'n/a'
    }
    if (formData.starterPct > 0) {
      let levainPct = formData.starterPct
      if (formData.starterType === LEVAIN_PCT_TYPE_PFF) {
        // hardcoding levainSeedPct as 20 as a "rough default; to get more accurate will require user to specify seed%
        levainPct = levainPffToTwEstimate(formData.starterPct, formData.hydrationPct, 20)
      }
      return `~ ${getLevainTime(formData.bulkFermTempF, formData.bulkFermPct, levainPct, formData.hydrationPct )}`
    } else {
      return `~ ${getYeastTime(formData.bulkFermTempF, formData.bulkFermPct, formData.yeastRatioId, formData.hydrationPct)}`
    }
  }

  return (
          <Grid item container direction={'column'}>
            {renderMainHydration()}
            {renderAutolyseSwitch()}
            <div>
              <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}>
                <Typography sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Flour</Typography>
              </Divider>
              <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1} marginBottom={1}>
                <IngredientGroupForm ingredientData={formData.flour} setIngredientData={updateFlourData} idField={'id'} nameField={'name'}
                           unitField={'percent'} groupName={'Flour'} minGroupSize={1} disabled={false}/>
              </Grid>
            </div>
            <div>
              <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}>
                <Typography sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Liquid</Typography>
              </Divider>
              <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1} marginBottom={1}>
                <IngredientGroupForm ingredientData={formData.liquid} setIngredientData={updateLiquidData} idField={'id'}
                            nameField={'name'} unitField={'percent'} minGroupSize={1} groupName={'Liquid'} disabled={false}/>
              </Grid>
            </div>

            <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}>
              <Typography sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Leavening</Typography>
            </Divider>
            {renderLeavening(formData.includePreferment)}
            
            <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}>
              <Typography sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Mixins</Typography>
            </Divider>
            <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1}
                  marginBottom={1}>
              <IngredientCollectionForm ingredientData={formData.mixin} setIngredientData={updateMixinData} idField={'id'}
                                        nameField={'name'} unitField={'percent'} collectionName={'Mixin'}/>
            </Grid>
            <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}>
              <Typography sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Soaker</Typography>
            </Divider>
            <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1}
                  marginBottom={1}>
              <IngredientCollectionForm ingredientData={formData.soaker} setIngredientData={updateSoakerData} idField={'id'}
                                        nameField={'name'} unitField={'percent'} collectionName={'Soaker'}/>
            </Grid>
            <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}>
              <Typography sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Toppings</Typography>
            </Divider>
            <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1}
                  marginBottom={1}>
              <IngredientGroupForm ingredientData={formData.toppings} setIngredientData={updateToppingsData} idField={'id'}
                                   nameField={'name'} unitField={'percent'} groupName={'Toppings'} minGroupSize={0} disabled={false}/>
            </Grid>

            <ContextualHelpDialog isOpen={openContextualHelpDialog} close={onCloseHelp}
                                  title={contextualHelpContent.title} content={contextualHelpContent.content}/>
          </Grid>
  )
}

export { MainDoughForm }