import {useState} from "react";
import {mainFormDataDefaults, maxUnitsToBake, prefermentDefaults} from "./DoughFormDefaults";
import {
  Accordion, AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel, FormGroup,
  FormLabel, Grid,
  Paper, Radio,
  RadioGroup, Switch,
  TextField, Tooltip,
  Typography
} from "@mui/material";
import {FormTextField} from "../shared/FormTextField";
import * as Types from "../shared/Types";
import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import {calculateDoughRecipe} from "./DoughRecipeCalculator";
import {getLocalDate} from "../utils/DateUtils";
import {useNavigate, useParams} from "react-router";
import {SavedObjectDialog} from "../shared/SavedObjectDialog";
import {DoughRecipeWrapper} from "./DoughRecipeWrapper";
import {urlRatioDough} from "../../App";
import {getNewStateKey} from "../utils/FormStateUtils";
import AttributionIcon from '@mui/icons-material/Attribution';
import ReplayIcon from '@mui/icons-material/Replay';
import IconButton from "@mui/material/IconButton";
import {
  ArrowDropDown, ArrowDropUp
} from "@mui/icons-material";
import {ContextualHelpDialog} from "../help/ContextualHelpDialog";
import {AttributionDialog} from "../shared/AttributionDialog";
import {getPrefermentPffPct, LEVAIN_PCT_TYPE_TOTAL_WT} from "../shared/Types";
import {PrefermentForm} from "./PrefermentForm";
import {MainDoughForm} from "./MainDoughForm";

function DoughForm(props) {
  const {formKey} = useParams()
  const [savedFormData, setSavedFormData] = useState(initSavedFormData())
  const [formData, setFormData] = useState(initFormData())
  const [prefermentFormData, setPrefermentFormData] = useState(initPrefermentFormData())
  const [formNameError, setFormNameError] = useState(false)
  const [oldFormName, setOldFormName] = useState(undefined)
  const [recipeVisible, setRecipeVisible] = useState(false)
  const [recipeItems, setRecipeItems] = useState(undefined)
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true)
  const [openFormExistsDialog, setOpenFormExistsDialog] = useState(false)
  const [openAttributionDialog, setOpenAttributionDialog] = useState(false)
  const [openContextualHelpDialog, setOpenContextualHelpDialog] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [contextualHelpContent, setContextualHelpContent] = useState({title: undefined, content: undefined})
  const [formStateKey, setFormStateKey] = useState(getNewStateKey())
  const navigate = useNavigate()

  function initSavedFormData() {
    if (formKey === undefined) {
      // no storage key parameter, carry on
      return undefined
    }
    const decodedKey = window.atob(formKey)
    let storedData = localStorage.getItem(decodedKey)
    if (storedData) {
      // saved formula
      return JSON.parse(storedData)
    }
    storedData = sessionStorage.getItem(decodedKey)
    if (storedData) {
      // converted formula
      const convertedForm = JSON.parse(storedData)
      setTimeout(() => {
        // delay storage removal to accommodate ReactJs dev StrictMode which renders twice
        sessionStorage.removeItem(decodedKey)
      }, 1000)
      return convertedForm
    }
    // storage key doesn't exist or no data - ignore
    return mainFormDataDefaults
  }

  function initFormData() {
    if (savedFormData === undefined) {
      return mainFormDataDefaults
    }
    // exclude preferment from the savedFormData to split into separately managed states: formData and prefermentFormData
    // this is mostly being done to omit the preferment structure in the persisted data to save space
    // these are, of course, merged when calculating the recipe
    const {prefermentData, ...remainder} = savedFormData
    if (prefermentData !== undefined ) {
      // migrate obsolete preferment field to new mainDough field
      if (Object.keys(prefermentData).includes('recipeFlourPct') && prefermentData['recipeFlourPct'] > 0) {
        remainder['prefermentPffPct'] = prefermentData['recipeFlourPct']
        delete prefermentData['recipeFlourPct']
      }
      // field obsolete in preferment data
      if (Object.keys(prefermentData).includes('bulkFermPct')) {
        delete prefermentData['bulkFermPct']
      }
      // field obsolete in preferment data
      if (Object.keys(prefermentData).includes('bulkFermTempF')) {
        delete prefermentData['bulkFermTempF']
      }
    }

    return applyNewMainFormDefaults(remainder)
  }

  function openHelp(title, content) {
    setContextualHelpContent({title: title, content: content})
    setOpenContextualHelpDialog(true)
  }

  function onCloseHelp() {
    setOpenContextualHelpDialog(false)
    setContextualHelpContent({title: undefined, content: undefined})
  }

  function applyNewMainFormDefaults(storedForm) {
    Object.keys(mainFormDataDefaults).forEach(k => {
      if (storedForm[k] === undefined) {
        storedForm[k] = mainFormDataDefaults[k]
      }
      // in case of type rename
      storedForm.formType = mainFormDataDefaults.formType
    })
    return storedForm
  }

  function initPrefermentFormData() {
    if (savedFormData === undefined || savedFormData['prefermentData'] === undefined) {
      return prefermentDefaults
    }
    return applyNewPrefermentFormDefaults(savedFormData['prefermentData'])
  }

  function applyNewPrefermentFormDefaults(storedForm) {
    Object.keys(prefermentDefaults).forEach(k => {
      if (storedForm[k] === undefined) {
        storedForm[k] = prefermentDefaults[k]
      }
    })
    return storedForm
  }

  function updateFormData(formData) {
    setFormData(formData)
    setHasChanges(true)
  }

  function updatePrefermentData(prefermentData) {
    setPrefermentFormData(prefermentData)
    setHasChanges(true)
  }

  function onNumericFieldChange(evt) {
    let intVal = parseInt(evt.target.value)
    if (isNaN(intVal)) {
      intVal = 0
    }
    if (evt.target.name === 'numBatches' && intVal >= maxUnitsToBake) {
      intVal = maxUnitsToBake
    }
    if (evt.target.name === 'numBatches' && intVal < 1) {
      intVal = 1
    }
    updateFormData({
      ...formData,
      [evt.target.name]: intVal
    })
  }

  function onStringFieldChange(evt) {
    updateFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    })
  }

  function onIncludePrefermentChange(evt) {
    const includePreferment = evt.target.checked

    updateFormData({
      ...formData,
      includePreferment: includePreferment,
      starterPct: (includePreferment ? 0 : mainFormDataDefaults.starterPct),
      starterHydrationPct: (includePreferment ? 0 : mainFormDataDefaults.starterHydrationPct),
      starterType: LEVAIN_PCT_TYPE_TOTAL_WT,
      yeastRatioId: (includePreferment ? 0 : mainFormDataDefaults.yeastRatioId),
      prefermentPffPct: (includePreferment ? getPrefermentPffPct(prefermentFormData.prefermentType) : 0)
    })
  }

  function onFormNameFieldChange(evt) {
    if (evt.target.validity.valid) {
      setFormNameError(false)
    } else {
      setFormNameError(true)
    }
    updateFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    })
  }

  function recipeCloseRequest() {
    setRecipeVisible(false)
  }

  function applyForm() {
    const mergedFormData = {...formData, prefermentData: (formData.includePreferment ? prefermentFormData : undefined)}
    setRecipeItems(calculateDoughRecipe(mergedFormData))
    setRecipeVisible(true)
    setSaveBtnDisabled(false)
  }

  function loadSavedForm() {
    if (savedFormData === undefined) {
      console.log(`loadSavedForm (revert): no savedFormData present, ignoring`)
      return
    }
    setFormData(initFormData())
    setPrefermentFormData(initPrefermentFormData())
    setFormNameError(false)
    setRecipeItems(undefined)
    setRecipeVisible(false)
    setSaveBtnDisabled(true)
    setFormStateKey(getNewStateKey())
    setHasChanges(false)
  }

  function resetForm() {
    setSavedFormData(undefined)
    setFormData(mainFormDataDefaults)
    setPrefermentFormData(prefermentDefaults)
    setFormNameError(false)
    setRecipeItems(undefined)
    setRecipeVisible(false)
    setSaveBtnDisabled(true)
    setFormStateKey(getNewStateKey())
    setHasChanges(false)
    navigate(urlRatioDough)
  }

  function handleSaveClick() {
    saveForm(formData.formName, false)
  }

  function saveForm(formName, forceSave = false) {
    let formKey = formName
    if (Object.keys(localStorage).includes(formKey) && !forceSave) {
      setOldFormName(formData.formName)
      setOpenFormExistsDialog(true)
      return
    }
    setOldFormName(undefined)
    let timeStampField = 'createdAt'
    if (formData.hasOwnProperty(timeStampField)) {
      timeStampField = 'modifiedAt'
    }
    const updatedFormData = {
      ...formData,
      formName: formName,
      [timeStampField]: getLocalDate().getTime()
    }
    setFormData(updatedFormData)
    const mergedFormData = {...updatedFormData, prefermentData: (formData.includePreferment ? prefermentFormData : undefined)}
    localStorage.setItem(formKey, JSON.stringify(mergedFormData))
    setSavedFormData(mergedFormData)
    setRecipeItems(calculateDoughRecipe(mergedFormData))
    setSaveBtnDisabled(true)
    setHasChanges(false)
  }

  function setNewFormName(newFormName) {
    updateFormData({...formData, formName: newFormName})
  }

  function applyNewFormName(newFormName) {
    saveForm(newFormName, true)
  }

  function clickAttribution() {
    setOpenAttributionDialog(true)
  }

  function applyAttribution(attributionText) {
    updateFormData({...formData, attribution: attributionText})
  }

  function incrementBatches() {
    const currVal = formData.numBatches
    if (currVal >= maxUnitsToBake) {
      return
    }
    updateFormData({...formData, numBatches: currVal + 1})
  }

  function decrementBatches() {
    const currVal = formData.numBatches
    if (currVal === 1) {
      return
    }
    updateFormData({...formData, numBatches: currVal - 1})
  }

  return (
      <div key={formStateKey}>
        <Grid container direction={'row'} paddingLeft={{xs: '5%', sm: '8%', md: '8%', lg: '20%'}} paddingRight={{xs: '5%', sm: '8%', md: '8%', lg: '20%'}} rowSpacing={1.25} columns={{xs: 4, sm: 8, md: 12}}>
          <Grid item container direction={'column'} marginTop={1.5} alignItems={'center'}>
            <Typography variant={'h5'}>Ratio Dough Formula</Typography>
          </Grid>
          <Grid item container direction={'column'} justifyContent={'center'} paddingLeft={{xs: 0, sm: 0, md: 8}} paddingRight={{xs: 0, sm: 0, md: 8}}>
            <Grid item container direction={'row'} columns={{xs: 4, sm: 8, md: 12}}>
              <Grid item xs={3.5} sm={7.5} md={11.5}>
                <FormTextField sx={{width: '100%'}} required
                      id="outlined-required"
                      size={'small'}
                      name={'formName'}
                      label="Name"
                      value={formData.formName}
                      onChange={onFormNameFieldChange}
                      error={formNameError}
                      helperText={
                        formNameError ? 'Formula name is required' : ''
                      }
                      variant="filled"/>
              </Grid>
              <Grid item xs={0.5} sm={0.5} md={0.5}>
                <Tooltip title={'Attribution'} placement={'bottom'} arrow>
                <IconButton onClick={clickAttribution}><AttributionIcon sx={{marginTop: 0.5}} color={'primary'}/></IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction={'row'} columns={{xs: 4, sm: 8, md: 12}} justifyContent={'center'} alignContent={'center'}>
            <Grid item xs={1.5} sm={2.25} md={2.75}>
                <FormTextField size={'small'}
                               sx={{width: '8ch'}}
                        id="batches-select"
                        label={'Bake Units'}
                        name={'numBatches'} variant={'filled'}
                        value={formData.numBatches}
                        onChange={onNumericFieldChange}>
                </FormTextField>
                <FormControl>
                  <IconButton onClick={incrementBatches} sx={{padding: 0, marginTop: -0.3}} color={"primary"}><ArrowDropUp fontSize={'large'}/></IconButton>
                  <IconButton onClick={decrementBatches} sx={{padding: 0, marginTop: -1.5}} color={"primary"}><ArrowDropDown fontSize={'large'}/></IconButton>
                </FormControl>
            </Grid>
            <Grid item xs={1.2} sm={2} md={2}>
              <FormTextField size={'small'}
                      id="outlined-required"
                      name={'batchSize'}
                      label={formData.batchType === 'FW' ? 'Unit Flour g' : 'Unit Total g'}
                      value={formData.batchSize}
                      onChange={onNumericFieldChange}
                      variant="filled"/>
            </Grid>
            <Grid item xs={1.2} sm={3.5} md={4.5}>
              <FormControl sx={{marginLeft: '1rem'}}>
                <FormLabel id="batchTypeLabel" sx={{fontSize: '0.75rem', marginBottom: -0.25}}>Unit Type</FormLabel>
                <RadioGroup aria-labelledby="batchTypeLabel" row
                            defaultValue={mainFormDataDefaults.batchType}
                            value={formData.batchType}
                            name="batchType" onChange={onStringFieldChange}>
                  <FormControlLabel sx={{fontSize: '0.85rem', marginTop: 0}} value={Types.BATCH_TYPE_TOTAL_WT}
                                    control={<Radio color={'primary'}/>}
                                    label={<Typography sx={{fontSize: 12}}>Total Weight</Typography>}/>
                  <FormControlLabel sx={{fontSize: '0.85rem', marginTop: 0}} value={Types.BATCH_TYPE_FLOUR_WT}
                                    control={<Radio color={'primary'}/>}
                                    label={<Typography sx={{fontSize: 12}}>Flour Weight</Typography>}/>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1}
                marginBottom={1}>
            <FormGroup row>
              <FormControlLabel control={<Switch name={'includePreferment'} checked={formData.includePreferment}
                                                 onChange={onIncludePrefermentChange}/>}
                                label={<Typography variant={'subtitle2'}>Add Preferment Formula</Typography>}/>
            </FormGroup>
          </Grid>
          {formData.includePreferment ?
            (
              <Grid item container direction={'column'}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                    <Typography>Preferment</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <PrefermentForm formData={prefermentFormData} setFormData={updatePrefermentData}/>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ) : null
          }
          <Grid item container direction={'column'}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                <Typography>Main Dough</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MainDoughForm formData={formData} setFormData={updateFormData} prefermentType={prefermentFormData.prefermentType}
                               prefermentLevainFermPct={prefermentFormData.levainFermPct} prefermentStarterPct={prefermentFormData.starterPct}
                               prefermentHydrationPct={prefermentFormData.hydrationPct} />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item container direction={'column'}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                <Typography>Notes & Instructions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField fullWidth multiline rows={10}
                           id={'outlined-textarea'}
                           variant={'standard'}
                           name={'notes'}
                           value={formData.notes}
                           onChange={onStringFieldChange}/>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item container spacing={2} direction={'row'} justifyContent={'center'} columns={{xs: 4, sm: 8, md: 12}}>
            {savedFormData !== undefined  ?
              (
                <Grid item>
                  <Tooltip title={'Revert to Saved copy'} placement={'bottom'} arrow>
                    <span>
                    <IconButton sx={{marginRight: -1}} onClick={loadSavedForm} disabled={!hasChanges || formNameError}><ReplayIcon fontSize={'medium'} color={(!hasChanges || formNameError ? 'grey': 'primary')}/></IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              ) : null
            }
            <Grid item>
              <Button variant={'contained'} onClick={applyForm} disabled={formNameError}>Apply</Button>
            </Grid>
            <Grid item>
              <Button color={"secondary"} variant={'outlined'} onClick={resetForm}>Clear</Button>
            </Grid>
            <Grid item>
              <Button color={"secondary"} variant={'outlined'} disabled={saveBtnDisabled}
                      onClick={handleSaveClick}>Save</Button>
            </Grid>
          </Grid>
          <Grid item container direction={'column'} justifyContent={'center'} columns={{xs: 4, sm: 8, md: 12}}>
            <Paper elevation={3} sx={{marginBottom: '1.5rem'}}>
              <DoughRecipeWrapper items={recipeItems} visible={recipeVisible} closeRequest={recipeCloseRequest}/>
            </Paper>
          </Grid>
        </Grid>
        <SavedObjectDialog objectName={formData.formName}
                           setNewObjectName={setNewFormName}
                           apply={applyNewFormName}
                           existingFormName={oldFormName}
                           dialogOpen={openFormExistsDialog}
                           setDialogOpen={setOpenFormExistsDialog}
                           objectType={'Formula'} />
        <ContextualHelpDialog isOpen={openContextualHelpDialog} close={onCloseHelp} title={contextualHelpContent.title} content={contextualHelpContent.content}/>
        <AttributionDialog attributionText={formData.attribution} setNewAttributionText={applyAttribution} dialogOpen={openAttributionDialog} setDialogOpen={setOpenAttributionDialog}/>
      </div>
  )
}

export {DoughForm}
