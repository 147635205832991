import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import Button from "@mui/material/Button";

function SavedObjectDialog(props) {
  const {objectName, setNewObjectName, existingFormName, apply, dialogOpen, setDialogOpen, objectType} = props

  const savedName = existingFormName
  const handleClose = () => {
    setDialogOpen(false);
    if (savedName !== objectName) {
      setNewObjectName(savedName)
    }
  };

  const handleApply = () => {
    setDialogOpen(false);
    apply(objectName)
  };

  function onFieldChange(evt) {
    setNewObjectName(evt.target.value)
  }

  return (
    <div>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>{objectType} Name Exists!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This name exists and will be overwritten unless you type a new name here.
          </DialogContentText>
          <TextField
                  autoFocus
                  margin="dense"
                  id={`new${objectType}Name`}
                  label={`New ${objectType} Name`}
                  value={objectName}
                  onChange={onFieldChange}
                  fullWidth
                  variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleApply}>Apply</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export {SavedObjectDialog}