export const decimalFormat = new RegExp('^\\d*\\.?\\d*$')
export const decimalLeadingZeroFormat = new RegExp('^0\\d+')
export const decimalExceedsMaxScale = new RegExp('^\\d*\\.\\d\\d\\d$')

function isDigit(str, ix) {
  return str.length > ix && !isNaN(str.charAt(ix))
}

function endsWithDigit(str) {
  return str.length > 0 && !isNaN(str.charAt(str.length - 1))
}

function startsWithDigit(str) {
  return str.length > 0 && !isNaN(str.charAt(0))
}

function toFixedDecimal(num, digits, base) {
  const pow = Math.pow(base ?? 10, digits ?? 2);
  return Math.round(num*pow) / pow;
}

function toFixedWholePercent(num, digits, base) {
  return toFixedDecimal(num * 100, digits, base)
}

function normalizeDecimalString(decimalStr) {
  let outputDecimalStr = decimalStr.trim()
  if (outputDecimalStr !== '' && !decimalFormat.test(outputDecimalStr)) {
    return undefined
  }
  if (outputDecimalStr.startsWith('.')) {
    outputDecimalStr = `0${outputDecimalStr}`
  } else if (decimalLeadingZeroFormat.test(outputDecimalStr)) {
    outputDecimalStr = outputDecimalStr.substring(1)
  }
  if (decimalExceedsMaxScale.test(outputDecimalStr)) {
    outputDecimalStr = `${toFixedDecimal(parseFloat(outputDecimalStr))}`
  }
  return outputDecimalStr
}

function formatZeroFillDecimal(decimal, formatNumDigits) {
  let inputNumDigits = `${decimal}`.length
  let preZero = ''
  for (let i = inputNumDigits; i < formatNumDigits; i++) {
    preZero += '0'
  }
  return `${preZero}${decimal}`
}

function trimZeroesDecimalStr(decimalStr) {
  const decimalPartsArr = decimalStr.split('.')
  let wholePart = decimalPartsArr[0]
  let trimWholeIx = 0
  while (trimWholeIx < wholePart.length) {
    if (wholePart.charAt(trimWholeIx) !== '0') {
      break
    }
    ++trimWholeIx
  }
  wholePart = trimWholeIx >= wholePart.length ? '0' : wholePart.substring(trimWholeIx)

  let fractionPart = ''
  if (decimalPartsArr.length > 1) {
    fractionPart = decimalPartsArr[1]
  }
  let trimFractionIx = fractionPart.length - 1
  while (trimFractionIx >= 0) {
    if (fractionPart.charAt(trimFractionIx) !== '0') {
      break
    }
    --trimFractionIx
  }
  fractionPart = trimFractionIx < 0 ? '' : fractionPart.substring(0, trimFractionIx + 1)
  return fractionPart === '' ? wholePart : `${wholePart}.${fractionPart}`
}

export {isDigit, startsWithDigit, endsWithDigit, toFixedDecimal, toFixedWholePercent, normalizeDecimalString, formatZeroFillDecimal, trimZeroesDecimalStr}