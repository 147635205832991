import {styled} from "@mui/system";
import {Slider} from "@mui/material";

// Slider change only from thumb button and not by clicking/touching the track
// This is especially helpful on mobile devices where components are close together!
const FormulaSlider = styled(Slider)({
  pointerEvents: 'none !important',
  '.MuiSlider-thumb': {
    pointerEvents: 'all !important'
  }
});

export {FormulaSlider}
