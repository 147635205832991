function arrayEquals(a, b) {
  return Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index]);
}

function deepCopy(arrSrc) {
  const arrDest = arrSrc.slice()
  for(let ix = 0; ix < arrDest.length; ix++) {
    arrDest[ix] = {...arrSrc[ix]}
  }
  return arrDest
}

function deepCopyExcludingField(arrSrc, fieldName) {
  const arrDest = arrSrc.slice()
  for(let ix = 0; ix < arrDest.length; ix++) {
    arrDest[ix] = {...arrSrc[ix]}
    delete arrDest[ix][fieldName]
  }
  return arrDest
}

function deepCopySyncDecimalStringField(arrSrc, fieldName) {
  const arrDest = arrSrc.slice()
  for(let ix = 0; ix < arrDest.length; ix++) {
    arrDest[ix] = {...arrSrc[ix]}
    arrDest[ix][`${fieldName}Str`] = `${arrDest[ix][fieldName]}`
  }
  return arrDest
}

function getFieldValuesAsArray(arrSrc, fieldName) {
  return arrSrc.map((o, ix) => {
    return  arrSrc[ix][fieldName]
  })
}

function setArrayFieldValues(arrSrc, fieldName, value) {
  if (arrSrc === undefined) {
    return
  }
  arrSrc.forEach(function (item) {
    item[fieldName] = value
  })
}

function getSumForField(arrSrc, fieldName) {
  return arrSrc.reduce((acc, obj) => {return acc + obj[fieldName]}, 0)
}

function findIndexForFieldValue(arrSrc, key, value) {
  return arrSrc.findIndex((obj) => obj[key] === value)
}

function findObjectForFieldValue(arrSrc, key, value) {
  return arrSrc.find((obj) => obj[key] === value)
}

export {arrayEquals, deepCopy, deepCopyExcludingField, deepCopySyncDecimalStringField, getFieldValuesAsArray, setArrayFieldValues, getSumForField, findIndexForFieldValue, findObjectForFieldValue}