import {styled} from "@mui/system";
import {NavLink} from "react-router-dom";

const StyledNavLink = styled(NavLink)(({theme}) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'inherit',
  textDecoration: 'none',
  fontSize: '20px',
  '&.active': {
    background: theme.palette.secondary.main
  }
}))

export {StyledNavLink};