import * as Types from "../shared/Types";
import {getLevainTime} from "../shared/FermentationTiming";

const newItems = {
  formName: '',
  formType: '',
  createdAt: '',
  modifiedAt: '',
  batchSize: 0,
  batchType: Types.BATCH_TYPE_FLOUR_WT,
  hydrationPct: 0,
  hydrationPctFraction: 0,
  levain: {
    starterPct: 0,
    starterPctFraction: 0,
    hydrationPct: 0,
    hydrationPctFraction: 0,
    starterWeight: 0,
    flourWeight: 0,
    flour: [],
    water: {weight: 0, percent: 0},
    levainFermPct: 0,
    levainFermTempF: '0',
    levainFermTimeStr: '',
    mother: {
      starterPct: 0,
      starterPctFraction: 0,
      hydrationPct: 0,
      hydrationPctFraction: 0,
      flourWeight: 0,
      starterWeight: 0,
      starterHalfWeight: 0,
      waterWeight: 0,
    },
    totalWeight: 0
  }
}

function calculateLevainRecipe(inputData) {
  let items = JSON.parse(JSON.stringify(newItems))
  items.formName = inputData.formName
  items.formType = inputData.formType
  items.createdAt = inputData.createdAt
  items.modifiedAt = inputData.modifiedAt
  items.batchSize = inputData.batchSize
  items.batchType = inputData.batchType
  items.levain.starterPct = inputData.levainStarterPct
  items.levain.starterPctFraction = inputData.levainStarterPct / 100
  items.levain.hydrationPct = inputData.levainHydrationPct
  items.levain.hydrationPctFraction = inputData.levainHydrationPct / 100
  items.levain.mother.starterPct = inputData.levainMotherStarterPct
  items.levain.mother.starterPctFraction = inputData.levainMotherStarterPct / 100
  items.levain.mother.hydrationPct = inputData.levainMotherHydrationPct
  items.levain.mother.hydrationPctFraction = inputData.levainMotherHydrationPct / 100
  items.levain.levainFermPct = inputData.levainFermPct
  items.levain.levainFermTempF = inputData.levainFermTempF

  let levainFlourWeight = 0
  if (inputData.batchType === Types.BATCH_TYPE_FLOUR_WT) {
    levainFlourWeight = items.batchSize
  } else {
    levainFlourWeight = Math.round(calculateLevainFlourByTotalWeight(items.batchSize, items.levain.starterPctFraction, items.levain.hydrationPctFraction))
  }
  let levainComponents = calculateLevainComponentWeights(levainFlourWeight, items)
  items = applyLevainComponentWeights(levainFlourWeight, 0, levainComponents, inputData.levainFlour, items)
  items.levain.levainFermTimeStr = getLevainTime(items.levain.levainFermTempF, items.levain.levainFermPct, items.levain.starterPct, items.levain.hydrationPct)
  return items
}

// levain flour (lf) by PFF determined by solving for lf:
// lf = (df + lf)*lp
//   where df = target dough flour weight, lp = levain pct of dough
function calculateLevainFlourByPff(levainTotalFlourWeight, levainStarterPctFraction, levainMotherStarterPctFraction, levainMotherHydrationPctFraction) {
  let levainMotherFactor = 1 + (levainStarterPctFraction / (levainMotherStarterPctFraction + 1 + levainMotherHydrationPctFraction))
  return levainTotalFlourWeight / levainMotherFactor
}

// levain flour (lf) by levain total weight (lw) determined by solving for lf:
// lw = (lf*lsp + lf + lf*lhp)
//   where lsp = levain starter pct, lhp = levain hydration pct, and lw = levain total weight
// lf = lw / (lsp + 1 + lhp)
function calculateLevainFlourByTotalWeight(levainTotalWeight, starterPctFraction, hydrationPctFraction) {
  return levainTotalWeight / (starterPctFraction + 1 + hydrationPctFraction)
}

// the levainFlourWeight argument is expected to be levain fresh flour weight, not including any mother flour
function calculateLevainComponentWeights(levainFlourWeight, items) {
  let levainStarterWeight = levainFlourWeight * items.levain.starterPctFraction
  let levainWaterWeight = levainFlourWeight * items.levain.hydrationPctFraction
  let levainTotalWeight = levainStarterWeight + levainFlourWeight +  levainWaterWeight
  let levainMotherFlourWeight = calculateLevainFlourByTotalWeight(levainStarterWeight, items.levain.mother.starterPctFraction, items.levain.mother.hydrationPctFraction)
  let levainMotherStarterWeight = levainMotherFlourWeight * items.levain.mother.starterPctFraction
  let levainMotherStarterHalf = 0.5 * levainMotherStarterWeight

  return {
    levainStarterWeight: levainStarterWeight,
    levainWaterWeight: levainWaterWeight,
    levainTotalWeight: levainTotalWeight,
    levainMotherFlourWeight: levainMotherFlourWeight,
    levainMotherStarterWeight: levainMotherStarterWeight,
    levainMotherStarterHalf: levainMotherStarterHalf
  }
}

function calculatePrimaryLevainComponentWeights(levainFlourWeight, starterPctFraction, hydrationPctFraction) {
  let levainStarterWeight = levainFlourWeight * starterPctFraction
  let levainLiquidWeight = levainFlourWeight * hydrationPctFraction
  let levainTotalWeight = levainStarterWeight + levainFlourWeight + levainLiquidWeight

  return {
    levainStarterWeight: levainStarterWeight,
    levainLiquidWeight: levainLiquidWeight,
    levainTotalWeight: levainTotalWeight
  }
}

function applyLevainComponentWeights(levainFlourWeight, doughFlourWeight, levainComponents, levainFlourArray, items) {
  items.levain.starterWeight = Math.round(levainComponents.levainStarterWeight)
  items.levain.flourWeight = Math.round(levainFlourWeight)
  items.levain.water.weight = Math.round(levainComponents.levainWaterWeight)
  items.levain.water.percent = items.levain.hydrationPct
  items.levain.water.tempF = 82
  items.levain.mother.flourWeight = Math.round(levainComponents.levainMotherFlourWeight)
  items.levain.mother.starterWeight = Math.max(1, Math.round(levainComponents.levainMotherStarterWeight))
  items.levain.mother.starterHalfWeight = Math.round(levainComponents.levainMotherStarterHalf)
  items.levain.mother.waterWeight = Math.round(levainComponents.levainMotherFlourWeight * items.levain.mother.hydrationPctFraction)
  items.levain.totalWeight = Math.round(levainComponents.levainTotalWeight)
  items.levain.adjustedDoughFlourWeight = Math.round(doughFlourWeight)
  // apply levain flour
  levainFlourArray.forEach((fd) => {
    items.levain.flour.push({id: fd.id, name: fd.name, percent: fd.percent, weight: (Math.round(levainFlourWeight * (fd.percent / 100)))})
  })
  return items
}

export {calculateLevainRecipe, calculateLevainFlourByTotalWeight, calculatePrimaryLevainComponentWeights}