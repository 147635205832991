
import {useState} from "react";
import {getLocalDate} from "../utils/DateUtils";
import {FormTextField} from "../shared/FormTextField";
import {
  Box,
  Divider,
  FormControl, FormControlLabel, FormLabel, Grid,
  Paper, Radio, RadioGroup, Tooltip,
  Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import ReplayIcon from '@mui/icons-material/Replay';
import {SavedObjectDialog} from "../shared/SavedObjectDialog";
import * as React from "react";
import {calculateLevainRecipe} from "./LevainRecipeCalculator";
import {useNavigate, useParams} from "react-router";
import {levainFlourDefaults, levainFormDefaults} from "./LevainFormDefaults";
import * as Types from "../shared/Types";
import {LevainRecipeWrapper} from "./LevainRecipeWrapper";
import {urlLevain} from "../../App";
import {getNewStateKey} from "../utils/FormStateUtils";
import IconButton from "@mui/material/IconButton";
import {FormulaSlider} from "../shared/FormulaSlider";
import {toFixedDecimal} from "../utils/NumberUtils";
import {FermentationTimingControl} from "../shared/FermentationTimingControl";
import {fermTemps, getLevainTime, getYeastTime, levainVolumes} from "../shared/FermentationTiming";
import {helpFermVolTemp} from "../help/HelpContent";
import {ContextualHelpDialog} from "../help/ContextualHelpDialog";
import {IngredientGroupForm} from "../shared/IngredientGroupForm";

function LevainForm(props) {
  const {formKey} = useParams()
  const [savedFormData, setSavedFormData] = useState(initSavedLevainFormData())
  const [formData, setFormData] = useState(initLevainFormData())
  const [flourFormData, setFlourFormData] = useState(initLevainFlourFormData())
  const [formNameError, setFormNameError] = useState(false)
  const [oldFormName, setOldFormName] = useState(undefined)
  const [openContextualHelpDialog, setOpenContextualHelpDialog] = useState(false)
  const [contextualHelpContent, setContextualHelpContent] = useState({title: undefined, content: undefined})
  const [recipeVisible, setRecipeVisible] = useState(false)
  const [recipeItems, setRecipeItems] = useState(undefined)
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true)
  const [openFormExistsDialog, setOpenFormExistsDialog] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [formStateKey, setFormStateKey] = useState(getNewStateKey())
  const navigate = useNavigate()

  function initSavedLevainFormData() {
    if (formKey === undefined) {
      return undefined
    }
    const decodedKey = atob(formKey)
    const storedForm = localStorage.getItem(decodedKey)
    return storedForm ? JSON.parse(storedForm) : levainFormDefaults
  }

  function initLevainFormData() {
    if (savedFormData === undefined) {
      return levainFormDefaults
    }
    const {levainFlour, ...remainder} = savedFormData
    return fillFormGaps(remainder)
  }

  function fillFormGaps(storedForm) {
    Object.keys(levainFormDefaults).forEach(k => {
      if (storedForm[k] === undefined) {
        storedForm[k] = levainFormDefaults[k]
      }
      storedForm.formType = levainFormDefaults.formType
    })
    return storedForm
  }

  function initLevainFlourFormData() {
    if (savedFormData === undefined) {
      return levainFlourDefaults
    }
    if (savedFormData['levainFlour'] === undefined) {
      return levainFlourDefaults
    }
    return savedFormData['levainFlour']
  }

  function updateFormData(formData) {
    setFormData(formData)
    setHasChanges(true)
  }

  function updateFlourFormData(flourFormData) {
    setFlourFormData(flourFormData)
    setHasChanges(true)
  }

  function openHelp(title, content) {
    setContextualHelpContent({title: title, content: content})
    setOpenContextualHelpDialog(true)
  }

  function onCloseHelp() {
    setOpenContextualHelpDialog(false)
    setContextualHelpContent({title: undefined, content: undefined})
  }

  function onNumericFieldChange(evt) {
    let intVal = parseInt(evt.target.value)
    if (isNaN(intVal)) {
      intVal = 0
    }
    updateFormData({
      ...formData,
      [evt.target.name]: intVal
    })
  }

  function onStringFieldChange(evt) {
    updateFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    })
  }

  function onFormNameFieldChange(evt) {
    if (evt.target.validity.valid) {
      setFormNameError(false)
    } else {
      setFormNameError(true)
    }
    updateFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    })
  }

  function onGeneralFieldChange(evt) {
    setFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    })
  }

  function recipeCloseRequest() {
    setRecipeVisible(false)
  }

  function applyForm() {
    const recipeFormData = {...formData, levainFlour: flourFormData}
    setRecipeItems(calculateLevainRecipe(recipeFormData))
    setRecipeVisible(true)
    setSaveBtnDisabled(false)
  }

  function loadSavedForm() {
    if (savedFormData === undefined) {
      console.log(`loadSavedForm (revert): no savedFormData present, ignoring`)
      return
    }
    setFormData(initLevainFormData())
    setFlourFormData(initLevainFlourFormData())
    setFormNameError(false)
    setRecipeItems(undefined)
    setRecipeVisible(false)
    setSaveBtnDisabled(true)
    setFormStateKey(getNewStateKey())
    setHasChanges(false)
  }

  function prefermentLevainTimeFormatted() {
    return `~ ${getLevainTime(formData.levainFermTempF, formData.levainFermPct, formData.levainStarterPct, formData.levainHydrationPct)}`
  }

  function resetForm() {
    setSavedFormData(undefined)
    setFormData(levainFormDefaults)
    setFlourFormData(levainFlourDefaults)
    setFormNameError(false)
    setRecipeItems(undefined)
    setRecipeVisible(false)
    setSaveBtnDisabled(true)
    setFormStateKey(getNewStateKey())
    setHasChanges(false)
    navigate(urlLevain)
  }

  function handleSaveClick() {
    saveForm(formData.formName, false)
  }

  function saveForm(formName, forceSave = false) {
    let formKey = formName
    if (Object.keys(localStorage).includes(formKey) && !forceSave) {
      setOldFormName(formData.formName)
      setOpenFormExistsDialog(true)
      return
    }
    setOldFormName(undefined)
    let timeStampField = 'createdAt'
    if (formData.hasOwnProperty(timeStampField)) {
      timeStampField = 'modifiedAt'
    }
    const updatedFormData = {
      ...formData,
      formName: formName,
      [timeStampField]: getLocalDate().getTime()
    }
    setFormData(updatedFormData)
    const recipeFormData = {...updatedFormData, levainFlour: flourFormData}
    localStorage.setItem(formKey, JSON.stringify(recipeFormData))
    setSavedFormData(recipeFormData)
    setRecipeItems(calculateLevainRecipe(recipeFormData))
    setSaveBtnDisabled(true)
    setHasChanges(false)
  }

  function setNewFormName(newFormName) {
    updateFormData({...formData, formName: newFormName})
  }

  function applyNewFormName(newFormName) {
    saveForm(newFormName, true)
  }

  return (
    <div key={formStateKey}>
      <Grid container direction={'row'} paddingLeft={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}}
            paddingRight={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}} rowSpacing={1.5} columns={{xs: 4, sm: 8, md: 12}}>
        <Grid item container direction={'column'} marginTop={1.5} alignItems={'center'}>
          <Typography variant={'h5'}>Levain Formula</Typography>
        </Grid>
        <Grid item container direction={'column'} alignItems={'stretch'}>
          <FormTextField required
                  id="outlined-required"
                  name={'formName'}
                  label="Name"
                  value={formData.formName}
                  onChange={onFormNameFieldChange}
                  error={formNameError}
                  helperText={
                    formNameError ? 'Formula name is required' : ''
                  }
                  variant="filled"/>
        </Grid>
        <Grid item container direction={'row'} columnSpacing={1.5} columns={{xs: 4, sm: 8, md: 12}} justifyContent={'flex-start'}>
          <Grid item xs={1.5} sm={2} md={3}>
              <FormTextField size={'small'}
                  id="outlined-required"
                  name={'batchSize'}
                  label={formData.batchType === 'FW' ? 'Flour g' : 'Total g'}
                  value={formData.batchSize}
                  onChange={onNumericFieldChange}
                  variant="filled"/>
            </Grid>
            <Grid item xs={2.5} sm={6} md={6}>
              <FormControl sx={{marginLeft: '1rem'}}>
                <FormLabel id="batchTypeLabel" sx={{fontSize: '0.75rem', marginBottom: 0}} disabled={true}
                           color="secondary">Unit Type</FormLabel>
                <RadioGroup aria-labelledby="batchTypeLabel" row
                            defaultValue={levainFlourDefaults.batchType}
                            value={formData.batchType}
                            name="batchType" onChange={onStringFieldChange}>
                  <FormControlLabel sx={{fontSize: '0.85rem', marginTop: 0}} value={Types.BATCH_TYPE_FLOUR_WT}
                                    control={<Radio/>}
                                    label={<Typography sx={{fontSize: 12}}>Flour Weight</Typography>}/>
                  <FormControlLabel sx={{fontSize: '0.85rem', marginTop: 0}} value={Types.BATCH_TYPE_TOTAL_WT}
                                    control={<Radio/>}
                                    label={<Typography sx={{fontSize: 12}}>Total Weight</Typography>}/>
                </RadioGroup>
              </FormControl>
            </Grid>
        </Grid>
        <Grid item container direction={'column'} alignItems={'flex-start'}>
          <Divider textAlign={'right'} sx={{width: '90%', marginTop: 2}}>
            <Typography sx={{fontSize: 12, fontWeight: 450}}gutterBottom>Flour</Typography>
          </Divider>
          <IngredientGroupForm ingredientData={flourFormData} setIngredientData={updateFlourFormData} idField={'id'} nameField={'name'}
                               unitField={'percent'} groupName={'Flour'} minGroupSize={1} disabled={false}/>
        </Grid>

        <Grid item container direction={'column'} rowSpacing={1.5} columnSpacing={1.5} columns={{xs: 4, sm: 8, md: 12}} justifyContent={'flex-start'}>
          <Divider textAlign={'right'} sx={{width: '90%', marginTop: 2}}>
            <Typography sx={{fontSize: 12, fontWeight: 450}}gutterBottom>Starter</Typography>
          </Divider>

          <Grid item container direction={'column'} alignItems={'flex-start'}>
            <Typography variant={'subtitle2'} gutterBottom>Starter Seed:
              <Box component="span" fontWeight='fontWeightBold'> {toFixedDecimal(formData.levainStarterPct, 2)}% Weight</Box>
            </Typography>
            <FormulaSlider getAriaLabel={() => 'Levain '}
                           sx={{marginLeft: 1, width: '90%'}}
                           name={'levainStarterPct'}
                           value={formData.levainStarterPct}
                           onChange={onNumericFieldChange}
                           color={"primary"}
                           valueLabelDisplay={'auto'}
                           min={1}
                           max={150}
                           step={1}/>
          </Grid>
          <Grid item container direction={'column'} alignItems={'flex-start'}>
            <Typography variant={'subtitle2'} gutterBottom>Levain Hydration:
              <Box component="span" fontWeight='fontWeightBold'> {toFixedDecimal(formData.levainHydrationPct, 2)}% </Box>
            </Typography>
            <FormulaSlider getAriaLabel={() => 'Levain Hydration'}
                           sx={{marginLeft: 1, width: '90%'}}
                           name={'levainHydrationPct'}
                           value={formData.levainHydrationPct}
                           onChange={onNumericFieldChange}
                           valueLabelDisplay="auto"
                           color={"water"}
                           min={20}
                           max={150}
                           step={1}/>
          </Grid>
          <Divider textAlign={'right'} sx={{width: '90%', marginTop: 2}}>
            <Typography sx={{fontSize: 12, fontWeight: 450}} gutterBottom>Starter Mother</Typography>
          </Divider>
          <Grid item container direction={'column'} alignItems={'flex-start'} marginBottom={0.25}>
            <Typography variant={'subtitle2'} gutterBottom>Mother Seed:
              <Box component="span" fontWeight='fontWeightBold'> {toFixedDecimal(formData.levainMotherStarterPct, 2)}% Weight</Box>
            </Typography>
            <FormulaSlider getAriaLabel={() => 'Levain '}
                           sx={{marginLeft: 1, width: '90%'}}
                           name={'levainMotherStarterPct'}
                           value={formData.levainMotherStarterPct}
                           onChange={onNumericFieldChange}
                           color={"primary"}
                           valueLabelDisplay={'auto'}
                           min={1}
                           max={150}
                           step={1}/>
          </Grid>
          <Grid item container direction={'column'} alignItems={'flex-start'} marginBottom={0.25}>
            <Typography variant={'subtitle2'} gutterBottom>Mother Hydration:
              <Box component="span" fontWeight='fontWeightBold'> {toFixedDecimal(formData.levainMotherHydrationPct, 2)}% </Box>
            </Typography>
            <FormulaSlider getAriaLabel={() => 'Levain Hydration'}
                           sx={{marginLeft: 1, width: '90%'}}
                           name={'levainMotherHydrationPct'}
                           value={formData.levainMotherHydrationPct}
                           onChange={onNumericFieldChange}
                           valueLabelDisplay="auto"
                           color={"water"}
                           min={20}
                           max={150}
                           step={1}/>
          </Grid>

          <Grid item alignItems={'flex-start'} marginBottom={2.5}>
            <FermentationTimingControl headingLabel={'Levain'} fermPctValueArr={levainVolumes} fermTempValueArr={fermTemps}
                                       timeDisplayValue={prefermentLevainTimeFormatted} timeDisplayWarning={() => undefined}
                                       fermPctValue={formData.levainFermPct} fermPctName={'levainFermPct'} fermPctLabel={'Levain Ferm Goal'}
                                       fermTempValue={formData.levainFermTempF} fermTempName={'levainFermTempF'} fermTempLabel={'Levain Ferm Temp'}
                                       onChange={onGeneralFieldChange} onHelpClick={() => openHelp(undefined, helpFermVolTemp)}/>
          </Grid>
        </Grid>
        <Grid item container marginTop={1} spacing={2} direction={'row'} justifyContent={'center'} columns={{xs: 4, sm: 8, md: 12}}>
          {savedFormData !== undefined ?
            (
              <Grid item>
                <Tooltip title={'Revert to Saved copy'} placement={'bottom'} arrow>
                  <span>
                    <IconButton sx={{marginRight: -1}} onClick={loadSavedForm} disabled={!hasChanges || formNameError}><ReplayIcon fontSize={'medium'} color={(!hasChanges || formNameError ? 'grey': 'primary')}/></IconButton>
                  </span>
              </Tooltip>
            </Grid>
            ) : null
          }
          <Grid item>
            <Button variant={'contained'} onClick={applyForm} disabled={formNameError}>Apply</Button>
          </Grid>
          <Grid item>
            <Button color={"secondary"} variant={'outlined'} onClick={resetForm}>Clear</Button>
          </Grid>
          <Grid item>
            <Button color={"secondary"} variant={'outlined'} disabled={saveBtnDisabled}
                    onClick={handleSaveClick}>Save</Button>
          </Grid>
        </Grid>
      <Grid item container direction={'column'} justifyContent={'center'} columns={{xs: 4, sm: 8, md: 12}}>
        <Paper elevation={3} sx={{marginBottom: '1.5rem'}}>
          <LevainRecipeWrapper items={recipeItems} visible={recipeVisible} closeRequest={recipeCloseRequest}/>
        </Paper>
      </Grid>
    </Grid>
    <ContextualHelpDialog isOpen={openContextualHelpDialog} close={onCloseHelp}
                          title={contextualHelpContent.title} content={contextualHelpContent.content}/>

    <SavedObjectDialog objectName={formData.formName}
                       setNewObjectName={setNewFormName}
                       apply={applyNewFormName}
                       existingFormName={oldFormName}
                       dialogOpen={openFormExistsDialog}
                       setDialogOpen={setOpenFormExistsDialog}
                       objectType={'Levain'} />

  </div>
  )
}

export {LevainForm}