import * as React from 'react';
import {Box, Fab, Grid, Tooltip} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {deepCopy, findIndexForFieldValue} from "../utils/ArrayUtils";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import * as Units from "../shared/Units";
import {FormTextField} from "../shared/FormTextField";
import {UnitSelector} from "../shared/UnitSelector";
import {DecimalFormField} from "../shared/DecimalFormField";

/**
 * This component manages a set of related ingredients with selectable quantity units.
 * Ingredient units here can be specified as decimal numbers, e.g. 1.75 cups
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function QuantityIngredientCollectionForm(props) {
  const {ingredientData, setIngredientData, disabled, idField, nameField, unitField, unitTypeField, defaultUnitType, excludeUnits, collectionName, minCollectionSize} = props


  function onIngredientNameChange(evt) {
    const ingredientId = evt.target.dataset.ingredientid
    const ix = findIndexForFieldValue(ingredientData, idField, ingredientId)
    if (ix < 0) {
      console.log(`!! onIngredientNameChange: cannot find ${collectionName} for id ${ingredientId}`)
      return
    }
    const updatedIngredientData = ingredientData.slice()
    updatedIngredientData[ix] = {...ingredientData[ix], [nameField]: evt.target.value}
    setIngredientData(updatedIngredientData)
  }

  function onIngredientUnitFieldChange(fieldName, floatValue, id) {
    const ix = findIndexForFieldValue(ingredientData, idField, id)
    if (ix < 0) {
      console.log(`!! onIngredientUnitFieldChange: cannot find ${collectionName} for id ${id}`)
      return
    }

    const updatedIngredientData = ingredientData.slice()
    updatedIngredientData[ix] = {...ingredientData[ix], [unitField]: floatValue}
    setIngredientData(updatedIngredientData)
  }

  function onUnitChange(id, value) {
    const ix = findIndexForFieldValue(ingredientData, idField, id)
    if (ix < 0) {
      console.log(`!! onUnitChange: cannot find ${collectionName} for id ${id}`)
      return
    }
    const updatedIngredientData = ingredientData.slice()
    updatedIngredientData[ix] = {...ingredientData[ix], [unitTypeField]: value}
    setIngredientData(updatedIngredientData)
  }

  function addCustomIngredient() {
    const newIx = ingredientData.length + 1
    const uniqueId = `c.${Math.floor(Math.random() * 1000) + 1}`
    const newIngredientArr = ingredientData.slice()
    const newUnitType = defaultUnitType === undefined ? Units.unitGrams : defaultUnitType
    newIngredientArr.push({[nameField]: `${collectionName} ${newIx}`, [unitField]: 0, [unitTypeField]: newUnitType, [idField]: uniqueId})
    setIngredientData(newIngredientArr)
  }

  function deleteIngredient(ingredientId) {
    let workingIngredientData = deepCopy(ingredientData)
    workingIngredientData = workingIngredientData.filter((fd) => fd[idField] !== ingredientId)
    setIngredientData(workingIngredientData)
  }

  return (
          <div>
            <Grid item container direction={'column'} rowSpacing={1}>
              {ingredientData.length === 0 && minCollectionSize === 0 ? (
                      <Grid item xs={0.65} sm={1.25} md={1.25}>
                        <Box sx={{paddingTop: '0.6rem', paddingLeft: '0.5rem'}}>
                          <Tooltip title={`Add a ${collectionName}`} placement={'right'} arrow>
                            <Fab size="small" color="secondary" aria-label="add">
                              <AddIcon onClick={addCustomIngredient}/>
                            </Fab>
                          </Tooltip>
                        </Box>
                      </Grid>
              ) : null
              }
              {ingredientData.map((fd, idx) => {
              return(
              <Grid key={fd[idField]} item container columnSpacing={1.5} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'} columns={{xs: 4, sm: 8, md: 12}}>
                <Grid item xs={1.25} sm={3.25} md={4} >
                    <FormTextField size={'small'}
                            id="outlined-required"
                            disabled={disabled}
                            label={collectionName}
                            inputProps={{'data-ingredientid': fd[idField]}}
                            name={nameField}
                            value={fd[nameField]}
                            onChange={onIngredientNameChange}
                            variant="filled"/>
                </Grid>
                <Grid item xs={0.9} sm={1.5} md={2.25}>
                  <DecimalFormField value={fd[unitField]} size={'small'} disabled={disabled}
                                    name={unitField} label={Units.getUnitLabel(fd[unitTypeField])}
                                    onChange={onIngredientUnitFieldChange}
                                    id={fd[idField]} />
                </Grid>
                <Grid item xs={0.65} sm={0.75} md={1.5}>
                  <UnitSelector id={fd[idField]} disabled={disabled} unitValue={fd[unitTypeField]} onValueChange={onUnitChange} excludeValues={excludeUnits}/>
                </Grid>
                <Grid item xs={0.45} sm={1} md={0.75}>
                  <IconButton sx={{paddingTop: '1.1rem'}} onClick={() => deleteIngredient(fd[idField])} placement="right" edge="end" disabled={disabled || (minCollectionSize > 0 && ingredientData.length === 1)} >
                    <DeleteIcon/>
                  </IconButton>
                </Grid>
                {idx === (ingredientData.length - 1) ?
                  <Grid item xs={0.65} sm={1.25} md={1}>
                    <Box sx={{paddingTop: '0.6rem', paddingLeft: '0.5rem'}}>
                      <Tooltip title={`Add a ${collectionName}`} placement={'right'} arrow>
                        <span>
                        <Fab disabled={disabled} size="small" color="secondary" aria-label="add">
                          <AddIcon onClick={addCustomIngredient}/>
                        </Fab>
                        </span>
                      </Tooltip>
                    </Box>
                  </Grid> : null}
              </Grid>)
            })
            }
            </Grid>
          </div>
  )
}

export { QuantityIngredientCollectionForm }