import {
  BATCH_TYPE_TOTAL_WT,
  ingredientDryYeast,
  ingredientFlour,
  ingredientLevain,
  ingredientUnknown,
  ingredientWater,
  LEVAIN_PCT_TYPE_TOTAL_WT,
  noPreferment
} from "../shared/Types";
import {toGrams} from "../shared/Units";
import {toFixedWholePercent} from "../utils/NumberUtils";
import {deriveRatioIdFromMultiplier, yeastDefs} from "../shared/Yeast";

const ratioFormItems = {
  formName: ``,
  formType: '',
  attribution: '',
  createdAt: '',
  modifiedAt: '',
  numBatches: 1,
  batchSize: 0,
  batchType: BATCH_TYPE_TOTAL_WT,
  hydrationPct: 0,
  autolyse: false,
  prefermentPffPct: 0,
  starterType: LEVAIN_PCT_TYPE_TOTAL_WT,
  starterPct: 0,
  starterHydrationPct: 0,
  yeastRatioId: 0,
  includePreferment: false,
  flour: [],
  liquid: [],
  mixin: [],
  soaker: [],
  toppings: [],
  notes: '',
  prefermentData: {
    prefermentType: noPreferment,
    hydrationPct: 0,
    autolyse: false,
    yeastRatioId: 0,
    starterType: LEVAIN_PCT_TYPE_TOTAL_WT,
    starterPct: 0,
    starterHydrationPct: 0,
    flour: [],
    liquid: []
  }
}

function convertToRatioForm(inputData) {
  let items = JSON.parse(JSON.stringify(ratioFormItems))
  items.formName = inputData.formName
  items.formType = inputData.formType
  items.numBatches = inputData.numBatches
  items.attribution = inputData.attribution
  items.createdAt = inputData.createdAt
  items.modifiedAt = inputData.modifiedAt
  items.notes = inputData.notes
  items.autolyse = inputData.autolyse
  items.includePreferment = inputData.includePreferment
  if (inputData.includePreferment) {
    items.prefermentData.prefermentType = inputData.prefermentData.prefermentType
    items.prefermentData.autolyse = inputData.prefermentData.autolyse
  } else {
    items.prefermentData = undefined
  }
  return calculateRecipePercentages(items, inputData)
}

function calculateRecipePercentages(items, inputData) {
  // main dough flour total
  let mainDoughFlourWeight = 0
  inputData.flour.forEach((fd, ix) => {
    mainDoughFlourWeight += toGrams(fd.amount, fd.unit, ingredientFlour)
  })
  consoleLog(`main total flour grams: ${mainDoughFlourWeight}`)
  // push main dough flour with percentages
  inputData.flour.forEach((fd, ix) => {
    const flourGrams = toGrams(fd.amount, fd.unit, ingredientFlour)
    const flourPercent = toFixedWholePercent(flourGrams / mainDoughFlourWeight)
    items.flour.push({id: fd.id, name: fd.name, percent: flourPercent, percentStr: `${flourPercent}` })
    consoleLog(`main flour: ${fd.name}, grams: ${flourGrams}, percent: ${flourPercent}`)
  })

  let mainDoughLiquidWeight = 0
  // get total liquid weight
  inputData.liquid.forEach((fd, ix) => {
    const liquidGrams = toGrams(fd.amount, fd.unit, ingredientWater)
    mainDoughLiquidWeight += liquidGrams
  })
  // calculate percentages in terms of total liquid
  inputData.liquid.forEach((fd, ix) => {
    const liquidGrams = toGrams(fd.amount, fd.unit, ingredientWater)
    const liquidPercent = toFixedWholePercent(liquidGrams / mainDoughLiquidWeight)
    items.liquid.push({id: fd.id, name: fd.name, percent: liquidPercent, percentStr: `${liquidPercent}` })
    consoleLog(`main liquid: ${fd.name}, grams: ${liquidGrams}, percent: ${liquidPercent}`)
  })

  let prefermentFlourWeight = 0
  let prefermentLiquidWeight = 0
  if (inputData.includePreferment) {
    // preferment flour total
    inputData.prefermentData.flour.forEach((fd, ix) => {
      prefermentFlourWeight += toGrams(fd.amount, fd.unit, ingredientFlour)
    })
    consoleLog(`preferment total flour grams: ${prefermentFlourWeight}`)
    // push preferment flour with percentages
    inputData.prefermentData.flour.forEach((fd, ix) => {
      const flourGrams = toGrams(fd.amount, fd.unit, ingredientFlour)
      const flourPercent = toFixedWholePercent(flourGrams / prefermentFlourWeight)
      items.prefermentData.flour.push({id: fd.id, name: fd.name, percent: flourPercent, percentStr: `${flourPercent}` })
      consoleLog(`preferment flour: ${fd.name}, grams: ${flourGrams}, percent: ${flourPercent}`)
    })

    // get total liquid weight
    inputData.prefermentData.liquid.forEach((fd, ix) => {
      const liquidGrams = toGrams(fd.amount, fd.unit, ingredientWater)
      prefermentLiquidWeight += liquidGrams
    })
    // calculate percentages in terms of total liquid
    inputData.prefermentData.liquid.forEach((fd, ix) => {
      const liquidGrams = toGrams(fd.amount, fd.unit, ingredientWater)
      const liquidPercent = toFixedWholePercent(liquidGrams / prefermentLiquidWeight)
      items.prefermentData.liquid.push({id: fd.id, name: fd.name, percent: liquidPercent, percentStr: `${liquidPercent}` })
      consoleLog(`preferment liquid: ${fd.name}, grams: ${liquidGrams}, percent: ${liquidPercent}`)
    })
  }

  const totalFlourWeight = mainDoughFlourWeight + prefermentFlourWeight

  // push main dough mixin with percentages
  let totalMixinWeight = 0
  inputData.mixin.forEach((md, ix) => {
    const mixinGrams = toGrams(md.amount, md.unit, ingredientUnknown)
    const mixinPercent = toFixedWholePercent(mixinGrams / totalFlourWeight)
    items.mixin.push({id: md.id, name: md.name, percent: mixinPercent })
    consoleLog(`mixin: ${md.name}, grams: ${mixinGrams}, percent: ${mixinPercent}`)
    totalMixinWeight += mixinGrams
  })
  // soaker is just another category of mixin
  inputData.soaker.forEach((sd, ix) => {
    const mixinGrams = toGrams(sd.amount, sd.unit, ingredientUnknown)
    const mixinPercent = toFixedWholePercent(mixinGrams / totalFlourWeight)
    items.soaker.push({id: sd.id, name: sd.name, percent: mixinPercent })
    consoleLog(`soaker: ${sd.name}, grams: ${mixinGrams}, percent: ${mixinPercent}`)
    totalMixinWeight += mixinGrams
  })
  // toppings is just another category of mixin but weight isn't included
  inputData.toppings.forEach((td, ix) => {
    items.toppings.push({id: td.id, name: td.name, percent: 0 })
    consoleLog(`topping: ${td.name}, grams: 0, percent: 0`)
  })
  consoleLog(`total mixin and soaker grams: ${totalMixinWeight}`)
  consoleLog(`total recipe flour grams: ${totalFlourWeight}`)

  // liquid totals
  const totalLiquidWeight = mainDoughLiquidWeight + prefermentLiquidWeight
  consoleLog(`main dough liquid grams: ${mainDoughLiquidWeight}, preferment liquid grams: ${prefermentLiquidWeight}, total liquid grams: ${totalLiquidWeight}`)
  items.hydrationPct = toFixedWholePercent(totalLiquidWeight / totalFlourWeight)
  consoleLog(`recipe hydration: ${items.hydrationPct}% `)

  // main levain and yeast
  const mainLevainWeight = toGrams(inputData.starterAmount, inputData.starterUnit, ingredientLevain)
  items.starterPct = toFixedWholePercent(mainLevainWeight / mainDoughFlourWeight)
  consoleLog(`main levain grams: ${mainLevainWeight}, pct: ${items.starterPct}`)

  const mainYeastWeight = toGrams(inputData.yeastAmount, inputData.yeastUnit, ingredientDryYeast)
  //  yw = um * ru * (fw/100)
  //  um = ym / (ru * (fw/100))
  //  where yw = yeast weight, um = unit multiplier, ru = ratio unit grams, fw = flour weight
  const mainYeastUnitMultiplier = (mainYeastWeight / (yeastDefs.yeastRatioUnitGrams * (mainDoughFlourWeight / 100)))
  items.yeastRatioId = deriveRatioIdFromMultiplier(mainYeastUnitMultiplier)
  items.yeastPct = toFixedWholePercent(mainYeastWeight / mainDoughFlourWeight)
  consoleLog(`main yeast grams: ${mainYeastWeight}, yeastRatioId: ${items.yeastRatioId}, yeastPct: ${items.yeastPct}`)

  // preferment totals
  let prefermentLevainWeight = 0
  let prefermentYeastWeight = 0
  if (inputData.includePreferment) {
    items.prefermentPffPct = toFixedWholePercent(prefermentFlourWeight / totalFlourWeight)
    items.prefermentData.hydrationPct = toFixedWholePercent(prefermentLiquidWeight / prefermentFlourWeight)
    prefermentLevainWeight = toGrams(inputData.prefermentData.starterAmount, inputData.prefermentData.starterUnit, ingredientLevain)
    items.prefermentData.starterPct = toFixedWholePercent(prefermentLevainWeight / prefermentFlourWeight)
    consoleLog(`preferment levain grams: ${prefermentLevainWeight}, pct: ${items.prefermentData.starterPct}`)

    prefermentYeastWeight = toGrams(inputData.prefermentData.yeastAmount, inputData.prefermentData.yeastUnit, ingredientDryYeast)
    //  yw = um * ru * (fw/100)
    //  um = ym / (ru * (fw/100))
    //  where yw = yeast weight, um = unit multiplier, ru = ratio unit grams, fw = flour weight
    const prefermentYeastUnitMultiplier = (prefermentYeastWeight / (yeastDefs.yeastRatioUnitGrams * (prefermentFlourWeight / 100)))
    items.prefermentData.yeastRatioId = deriveRatioIdFromMultiplier(prefermentYeastUnitMultiplier)
    items.prefermentData.yeastPct = toFixedWholePercent(prefermentYeastWeight / prefermentFlourWeight)
    consoleLog(`preferment yeast grams: ${prefermentYeastWeight}, yeastRatioId: ${items.prefermentData.yeastRatioId}`)
    consoleLog(`preferment hydrationPct: ${items.prefermentData.hydrationPct}`)
    consoleLog(`prefermentPffPct: ${items.prefermentPffPct}`)
  }

  // total weight
  items.batchSize = Math.round((totalFlourWeight + totalLiquidWeight + totalMixinWeight + mainLevainWeight + mainYeastWeight + prefermentLevainWeight + prefermentYeastWeight) / items.numBatches)
  consoleLog(`recipe total batch size: ${items.batchSize}`)
  return items
}

function consoleLog(message) {
  // console.log(message)
}

export {convertToRatioForm}