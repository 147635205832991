import * as React from 'react';
import './App.css';
import MainMenu from "./components/menu/MainMenu";
import {createTheme, ThemeProvider} from "@mui/material";
import {amber, blue, grey, red, yellow} from "@mui/material/colors";
import {Home} from "./components/home/Home";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {SavedItems} from "./components/saved/SavedItems";
import {DoughForm} from "./components/dough/DoughForm";
import {LevainForm} from "./components/levain/LevainForm";
import {DoughRecipePrintWrapper} from "./components/dough/DoughRecipePrintWrapper";
import {LevainRecipePrintWrapper} from "./components/levain/LevainRecipePrintWrapper";
import {PRINT_DOUGH_URI, PRINT_LEVAIN_URI} from "./components/utils/RoutingUtils";
import {QuantityDoughForm} from "./components/quantity/QuantityDoughForm";
import {formTypeLevain, formTypeQuantityDough, formTypeRatioDough} from "./components/shared/Types";
import {Help} from "./components/help/Help";
const theme = createTheme({
  palette: {
    primary: {
      main: amber[500]
    },
    secondary: {
      main: yellow[400]
    },
    attention: {
      main: red[500]
    },
    water: {
      main: blue[300]
    },
    dark: {
      main: grey[800]
    }
  }
})

// this supports un-themed print page rendering...
//   keep this in sync with the actual theme palette!
export const THEME_PRIMARY_COLOR = '#ffc107'

export const urlRatioDough = '/ratio'
export const urlQuantityDough = '/quantity'
export const urlLevain = '/levain'

export const formTypeUrlMap = [
  {
    formType: formTypeRatioDough,
    url: urlRatioDough
  },
  {
    formType: formTypeQuantityDough,
    url: urlQuantityDough
  },
  {
    formType: formTypeLevain,
    url: urlLevain
  }
]

function App() {
  const currentHref = window.location.href

  // bypass router encapsulated components to single out only the component of interest for printing
  if (currentHref.match(`.*${PRINT_DOUGH_URI}`)) {
    return (
        <div>
          <DoughRecipePrintWrapper/>
        </div>
    )
  } else if (currentHref.match(`.*${PRINT_LEVAIN_URI}`)) {
    return (
        <div>
          <LevainRecipePrintWrapper/>
        </div>
    )
  }

  return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <header className="App-content">
            <BrowserRouter>
              <MainMenu/>
              <Routes>
                <Route path={'/'} element={<Home/>} />
                <Route path={'/help'} element={<Help/>} />
                <Route path={`${urlRatioDough}/:formKey`} element={<DoughForm/>} />
                <Route path={`${urlRatioDough}`} element={<DoughForm/>} />
                <Route path={`${urlQuantityDough}/:formKey`} element={<QuantityDoughForm/>} />
                <Route path={`${urlQuantityDough}`} element={<QuantityDoughForm/>} />
                <Route path={`${urlLevain}/:formKey`} element={<LevainForm/>} />
                <Route path={`${urlLevain}`} element={<LevainForm/>} />
                <Route path={'/saved'} element={<SavedItems/>} />
                <Route path={`${PRINT_DOUGH_URI}`} element={<DoughRecipePrintWrapper/>} />
                <Route path={`${PRINT_LEVAIN_URI}`} element={<LevainRecipePrintWrapper/>} />
              </Routes>
            </BrowserRouter>
          </header>
        </div>
      </ThemeProvider>
  );
}

export default App;
